function gaussianBlur(imageData, radius) {
  const { data, width, height } = imageData;
  const output = new Uint8ClampedArray(data.length);

  // Função para calcular o kernel Gaussiano com base no raio
  function getGaussianKernel(radius) {
    const sigma = radius / 2;
    const kernelSize = 2 * radius + 1;
    const kernel = new Float32Array(kernelSize);
    const twoSigmaSquare = 2 * sigma * sigma;
    let sum = 0;

    for (let i = 0; i < kernelSize; i++) {
      const x = i - radius;
      kernel[i] = Math.exp(-(x * x) / twoSigmaSquare);
      sum += kernel[i];
    }

    // Normalizar o kernel
    for (let i = 0; i < kernelSize; i++) {
      kernel[i] /= sum;
    }

    return kernel;
  }

  // Aplicar convolução usando o kernel Gaussiano
  function applyKernel(data, width, height, kernel, radius) {
    const tempData = new Uint8ClampedArray(data.length);

    // Convolução horizontal
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        let r = 0,
          g = 0,
          b = 0,
          a = 0;
        let sum = 0;

        for (let i = -radius; i <= radius; i++) {
          const xk = x + i;
          if (xk >= 0 && xk < width) {
            const index = (y * width + xk) * 4;
            const weight = kernel[i + radius];
            r += data[index] * weight;
            g += data[index + 1] * weight;
            b += data[index + 2] * weight;
            a += data[index + 3] * weight;
            sum += weight;
          }
        }

        const index = (y * width + x) * 4;
        tempData[index] = r / sum;
        tempData[index + 1] = g / sum;
        tempData[index + 2] = b / sum;
        tempData[index + 3] = a / sum;
      }
    }

    // Convolução vertical
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        let r = 0,
          g = 0,
          b = 0,
          a = 0;
        let sum = 0;

        for (let i = -radius; i <= radius; i++) {
          const yk = y + i;
          if (yk >= 0 && yk < height) {
            const index = (yk * width + x) * 4;
            const weight = kernel[i + radius];
            r += tempData[index] * weight;
            g += tempData[index + 1] * weight;
            b += tempData[index + 2] * weight;
            a += tempData[index + 3] * weight;
            sum += weight;
          }
        }

        const index = (y * width + x) * 4;
        output[index] = r / sum;
        output[index + 1] = g / sum;
        output[index + 2] = b / sum;
        output[index + 3] = a / sum;
      }
    }
  }

  // Criar o kernel Gaussiano
  const kernel = getGaussianKernel(radius);

  // Aplicar o kernel à imagem
  applyKernel(data, width, height, kernel, radius);

  // Atualizar o ImageData com os novos valores suavizados
  const blurredImageData = new ImageData(output, width, height);
  return blurredImageData;
}

// eslint-disable-next-line no-unused-vars
function applySobelFilter(ctx, canvas) {
  const width = canvas.width;
  const height = canvas.height;

  const imageData = ctx.getImageData(0, 0, width, height);
  const data = imageData.data;

  const sobelData = [];
  const grayscaleData = [];

  // Converter para escala de cinza
  for (let i = 0; i < data.length; i += 4) {
    const grayscale =
      data[i] * 0.299 + data[i + 1] * 0.587 + data[i + 2] * 0.114;
    grayscaleData.push(grayscale, grayscale, grayscale, data[i + 3]); // Mantém o valor alfa
  }

  // Matrizes do filtro de Sobel
  const sobelX = [
    [-1, 0, 1],
    [-2, 0, 2],
    [-1, 0, 1],
  ];

  const sobelY = [
    [-1, -2, -1],
    [0, 0, 0],
    [1, 2, 1],
  ];

  // Aplicar o filtro de Sobel
  for (let y = 1; y < height - 1; y++) {
    for (let x = 1; x < width - 1; x++) {
      let pixelX =
        sobelX[0][0] * grayscaleData[((y - 1) * width + (x - 1)) * 4] +
        sobelX[0][2] * grayscaleData[((y - 1) * width + (x + 1)) * 4] +
        sobelX[1][0] * grayscaleData[(y * width + (x - 1)) * 4] +
        sobelX[1][2] * grayscaleData[(y * width + (x + 1)) * 4] +
        sobelX[2][0] * grayscaleData[((y + 1) * width + (x - 1)) * 4] +
        sobelX[2][2] * grayscaleData[((y + 1) * width + (x + 1)) * 4];

      let pixelY =
        sobelY[0][0] * grayscaleData[((y - 1) * width + (x - 1)) * 4] +
        sobelY[0][1] * grayscaleData[((y - 1) * width + x) * 4] +
        sobelY[0][2] * grayscaleData[((y - 1) * width + (x + 1)) * 4] +
        sobelY[2][0] * grayscaleData[((y + 1) * width + (x - 1)) * 4] +
        sobelY[2][1] * grayscaleData[((y + 1) * width + x) * 4] +
        sobelY[2][2] * grayscaleData[((y + 1) * width + (x + 1)) * 4];

      const magnitude = Math.sqrt(pixelX * pixelX + pixelY * pixelY) >>> 0;

      const index = (y * width + x) * 4;
      sobelData[index] = magnitude;
      sobelData[index + 1] = magnitude;
      sobelData[index + 2] = magnitude;
      sobelData[index + 3] = 255; // Alpha
    }
  }

  // Atualizar o canvas com os dados do filtro
  for (let i = 0; i < data.length; i += 4) {
    data[i] = sobelData[i]; // Red
    data[i + 1] = sobelData[i]; // Green
    data[i + 2] = sobelData[i]; // Blue
  }

  ctx.putImageData(imageData, 0, 0);
}

function createHeatmap(mesh, plane) {
  console.warn(`Criando heatMap ${plane}`);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // Definir tamanho do canvas (por exemplo, 512x512)
  canvas.width = 512;
  canvas.height = 512;

  const vertices = mesh.geometry.attributes.position.array;
  const numVertices = vertices.length / 3;

  // Determinar min e max da altura para normalizar as cores e para as coordenadas x e y
  let minX = Infinity,
    maxX = -Infinity;
  let minY = Infinity,
    maxY = -Infinity;
  let minHeight = Infinity,
    maxHeight = -Infinity;

  // Iterar para encontrar os mínimos e máximos de altura e coordenadas
  for (let i = 0; i < numVertices; i++) {
    const x = getXForPlane(vertices, i, plane);
    const y = getYForPlane(vertices, i, plane);
    const height = getHeightForPlane(vertices, i, plane);

    if (x < minX) minX = x;
    if (x > maxX) maxX = x;
    if (y < minY) minY = y;
    if (y > maxY) maxY = y;
    if (height < minHeight) minHeight = height;
    if (height > maxHeight) maxHeight = height;
  }

  // Iterar sobre os vértices e projetar
  for (let i = 0; i < numVertices; i++) {
    let x = getXForPlane(vertices, i, plane);
    let y = getYForPlane(vertices, i, plane);
    const height = getHeightForPlane(vertices, i, plane);

    // Escalar as coordenadas (x, y) para o tamanho do canvas (0 - 512)
    x = ((x - minX) / (maxX - minX)) * canvas.width;
    y = ((y - minY) / (maxY - minY)) * canvas.height;

    // Normalizar a altura para uma escala de cor (0-255)
    const normalizedHeight = (height - minHeight) / (maxHeight - minHeight);
    const colorValue = Math.floor(normalizedHeight * 255);
    const color = `rgb(${colorValue}, 0, ${255 - colorValue})`; // De azul para vermelho, por exemplo.

    // Desenhar ponto no canvas
    ctx.fillStyle = color;
    ctx.fillRect(x, y, 1, 1); // Desenha um pixel
  }

  // Obter a imagem do canvas para aplicar filtros
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  // Aplicar o filtro de Gaussian Blur antes do Sobel
  const blurredImage = gaussianBlur(imageData, 2); // Raio de desfoque 2, ajuste conforme necessário
  ctx.putImageData(blurredImage, 0, 0);

  // Aplicar filtro de Sobel para destacar bordas
  //applySobelFilter(ctx, canvas);

  // Retornar o canvas como uma imagem
  return canvas.toDataURL(); // URL da imagem que você pode colocar em um `<img>` ou salvar.

  // Funções auxiliares definidas dentro da função principal

  function getXForPlane(vertices, index, plane) {
    switch (plane) {
      case "XY":
        return vertices[index * 3];
      case "XZ":
        return vertices[index * 3];
      case "YZ":
        return vertices[index * 3 + 1];
    }
  }

  function getYForPlane(vertices, index, plane) {
    switch (plane) {
      case "XY":
        return vertices[index * 3 + 1];
      case "XZ":
        return vertices[index * 3 + 2];
      case "YZ":
        return vertices[index * 3 + 2];
    }
  }

  function getHeightForPlane(vertices, index, plane) {
    switch (plane) {
      case "XY":
        return vertices[index * 3 + 2]; // Altura é Z
      case "XZ":
        return vertices[index * 3 + 1]; // Altura é Y
      case "YZ":
        return vertices[index * 3]; // Altura é X
    }
  }
}

function createDensityMap(mesh, plane) {
  console.warn(`Criando DensityMap ${plane}`);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // Definir tamanho do canvas (por exemplo, 512x512)
  canvas.width = 512;
  canvas.height = 512;

  const vertices = mesh.geometry.attributes.position.array;
  const numVertices = vertices.length / 3;

  // Determinar min e max das coordenadas x e y
  let minX = Infinity,
    maxX = -Infinity;
  let minY = Infinity,
    maxY = -Infinity;

  // Iterar para encontrar os mínimos e máximos de coordenadas
  for (let i = 0; i < numVertices; i++) {
    const x = getXForPlane(vertices, i, plane);
    const y = getYForPlane(vertices, i, plane);

    if (x < minX) minX = x;
    if (x > maxX) maxX = x;
    if (y < minY) minY = y;
    if (y > maxY) maxY = y;
  }

  // Criar uma matriz 2D para contar os pontos em cada "pixel"
  const densityMap = Array(canvas.width)
    .fill(null)
    .map(() => Array(canvas.height).fill(0));

  // Iterar sobre os vértices e contar quantos caem em cada pixel
  for (let i = 0; i < numVertices; i++) {
    let x = getXForPlane(vertices, i, plane);
    let y = getYForPlane(vertices, i, plane);

    // Escalar as coordenadas (x, y) para o tamanho do canvas (0 - 512)
    const pixelX = Math.floor(((x - minX) / (maxX - minX)) * canvas.width);
    const pixelY = Math.floor(((y - minY) / (maxY - minY)) * canvas.height);

    // Verificar se as coordenadas estão dentro dos limites do canvas
    if (
      pixelX >= 0 &&
      pixelX < canvas.width &&
      pixelY >= 0 &&
      pixelY < canvas.height
    ) {
      // Incrementar o contador de vértices para esse pixel
      densityMap[pixelX][pixelY]++;
    }
  }

  // Encontrar o valor máximo de densidade para normalizar as cores
  let maxDensity = -Infinity;
  for (let x = 0; x < canvas.width; x++) {
    for (let y = 0; y < canvas.height; y++) {
      if (densityMap[x][y] > maxDensity) {
        maxDensity = densityMap[x][y];
      }
    }
  }

  // Iterar sobre a matriz de densidade para desenhar os pixels
  for (let x = 0; x < canvas.width; x++) {
    for (let y = 0; y < canvas.height; y++) {
      const density = densityMap[x][y];

      // Normalizar a densidade para uma escala de cor (0-255)
      const colorValue = Math.floor(255 * (density / maxDensity));
      const color = `rgb(${colorValue}, ${255 - colorValue}, ${
        255 - colorValue
      })`; // De azul para vermelho, por exemplo.

      // Desenhar o pixel no canvas
      ctx.fillStyle = color;
      ctx.fillRect(x, y, 1, 1);
    }
  }

  // Obter a imagem do canvas para aplicar filtros
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  // Aplicar o filtro de Gaussian Blur antes do Sobel
  const blurredImage = gaussianBlur(imageData, 2); // Raio de desfoque 2, ajuste conforme necessário
  ctx.putImageData(blurredImage, 0, 0);

  // Aplicar filtro de Sobel para destacar bordas
  //applySobelFilter(ctx, canvas);

  // Retornar o canvas como uma imagem
  return canvas.toDataURL(); // URL da imagem que você pode colocar em um `<img>` ou salvar.

  // Funções auxiliares definidas dentro da função principal

  function getXForPlane(vertices, index, plane) {
    switch (plane) {
      case "XY":
        return vertices[index * 3];
      case "XZ":
        return vertices[index * 3];
      case "YZ":
        return vertices[index * 3 + 1];
    }
  }

  function getYForPlane(vertices, index, plane) {
    switch (plane) {
      case "XY":
        return vertices[index * 3 + 1];
      case "XZ":
        return vertices[index * 3 + 2];
      case "YZ":
        return vertices[index * 3 + 2];
    }
  }
}

function createCurvatureHeatmap(mesh, plane) {
  console.warn(`Criando heatMap de curvatura para o plano ${plane}`);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = 512;
  canvas.height = 512;

  const vertices = mesh.geometry.attributes.position.array;
  const normals = mesh.geometry.attributes.normal.array;
  const numVertices = vertices.length / 3;

  // Criar o mapa de adjacência
  const adjacencyMap = createAdjacencyMap(mesh.geometry);

  // Determinar min e max da curvatura para normalizar as cores e coordenadas x e y
  let minX = Infinity,
    maxX = -Infinity;
  let minY = Infinity,
    maxY = -Infinity;
  let minCurvature = Infinity,
    maxCurvature = -Infinity;

  // Função para calcular a curvatura aproximada de um vértice
  function calculateCurvature(vertexIndex) {
    let curvature = 0;
    const normal = [
      normals[vertexIndex * 3],
      normals[vertexIndex * 3 + 1],
      normals[vertexIndex * 3 + 2],
    ];

    // Buscar os vértices adjacentes
    const adjacentVertices = adjacencyMap[vertexIndex];

    adjacentVertices.forEach((adjIdx) => {
      const adjNormal = [
        normals[adjIdx * 3],
        normals[adjIdx * 3 + 1],
        normals[adjIdx * 3 + 2],
      ];
      const dotProduct =
        normal[0] * adjNormal[0] +
        normal[1] * adjNormal[1] +
        normal[2] * adjNormal[2];
      const angle = Math.acos(Math.max(-1, Math.min(1, dotProduct))); // Garantir que o valor está no intervalo [-1, 1]
      curvature += angle;
    });

    return curvature / adjacentVertices.length; // Média da diferença angular
  }

  // Criar uma matriz de 360 posições para contar as curvaturas
  const curvatureAngleCount = new Array(360).fill(0);

  // Iterar para encontrar os mínimos e máximos de curvatura e coordenadas
  for (let i = 0; i < numVertices; i++) {
    const x = getXForPlane(vertices, i, plane);
    const y = getYForPlane(vertices, i, plane);
    const curvature = calculateCurvature(i);

    // Atualizar os valores mínimos e máximos para a normalização das coordenadas
    if (x < minX) minX = x;
    if (x > maxX) maxX = x;
    if (y < minY) minY = y;
    if (y > maxY) maxY = y;
    if (curvature < minCurvature) minCurvature = curvature;
    if (curvature > maxCurvature) maxCurvature = curvature;

    // Converter a curvatura em graus
    const curvatureDegrees = (curvature * 180) / Math.PI; // Converter radianos para graus

    // Garantir que o valor está entre 0 e 359 graus
    const boundedCurvatureDegrees = Math.max(
      0,
      Math.min(359, Math.floor(curvatureDegrees))
    );

    // Incrementar a contagem na matriz de acordo com o grau
    curvatureAngleCount[boundedCurvatureDegrees]++;
  }

  // Iterar sobre os vértices e projetar
  for (let i = 0; i < numVertices; i++) {
    let x = getXForPlane(vertices, i, plane);
    let y = getYForPlane(vertices, i, plane);
    const curvature = calculateCurvature(i);

    // Escalar as coordenadas (x, y) para o tamanho do canvas (0 - 512)
    x = ((x - minX) / (maxX - minX)) * canvas.width;
    y = ((y - minY) / (maxY - minY)) * canvas.height;

    // Normalizar a curvatura para uma escala de cor (0-255)
    const normalizedCurvature =
      (curvature - minCurvature) / (maxCurvature - minCurvature);
    const colorValue = Math.floor(normalizedCurvature * 255);

    const color = (normalizedCurvature > 0.05)? `rgb(${255-colorValue}, 0, ${colorValue})` : `rgb(${colorValue}, 0, ${255 - colorValue})`;

    // Desenhar ponto no canvas
    ctx.fillStyle = color;
    ctx.fillRect(x, y, 1, 1); // Desenha um pixel
  }

  // Obter a imagem do canvas para aplicar filtros
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  // Aplicar o filtro de Gaussian Blur antes do Sobel
  const blurredImage = gaussianBlur(imageData, 2); // Raio de desfoque 2, ajuste conforme necessário
  ctx.putImageData(blurredImage, 0, 0);

  // Aplicar filtro de Sobel para destacar bordas
  //applySobelFilter(ctx, canvas);

  // Retornar o canvas como uma imagem
  return canvas.toDataURL(); // URL da imagem que você pode colocar em um `<img>` ou salvar.

  // Funções auxiliares definidas dentro da função principal

  function getXForPlane(vertices, index, plane) {
    switch (plane) {
      case "XY":
        return vertices[index * 3];
      case "XZ":
        return vertices[index * 3];
      case "YZ":
        return vertices[index * 3 + 1];
    }
  }

  function getYForPlane(vertices, index, plane) {
    switch (plane) {
      case "XY":
        return vertices[index * 3 + 1];
      case "XZ":
        return vertices[index * 3 + 2];
      case "YZ":
        return vertices[index * 3 + 2];
    }
  }
}

// Função para criar o mapa de adjacência
function createAdjacencyMap(geometry) {
  const index = geometry.index.array; // Índices dos triângulos
  const adjacencyMap = {};

  // Inicializar o mapa de adjacência
  for (let i = 0; i < geometry.attributes.position.count; i++) {
    adjacencyMap[i] = [];
  }

  // Preencher o mapa de adjacência com os vértices conectados
  for (let i = 0; i < index.length; i += 3) {
    const a = index[i];
    const b = index[i + 1];
    const c = index[i + 2];

    adjacencyMap[a].push(b, c);
    adjacencyMap[b].push(a, c);
    adjacencyMap[c].push(a, b);
  }

  // Remover duplicatas de vizinhos
  for (const key in adjacencyMap) {
    adjacencyMap[key] = [...new Set(adjacencyMap[key])];
  }

  return adjacencyMap;
}

export { createHeatmap, createDensityMap, createCurvatureHeatmap };
