import * as THREE from "three";
import Delaunator from "delaunator";
import KMeans from "ml-kmeans";

export default {
  methods: {
    groupToothCentroidsWithKMeans(centroids, numClusters = 16) {
      // Converta os centroides para uma matriz de pontos
      const points = centroids.map((centroid) => [
        centroid.x,
        centroid.y,
        centroid.z,
      ]);

      // Execute o algoritmo K-means
      const result = KMeans(points, numClusters);

      const clusterCentroids = result.centroids.map((centroid) => {
        return new THREE.Vector3(
          centroid.centroid[0],
          centroid.centroid[1],
          centroid.centroid[2]
        );
      });

      return clusterCentroids;
    },

    matrixToTexture(matrix, width, height) {
      // Calcula o valor mínimo e máximo
      let min = Infinity,
        max = -Infinity;
      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          if (matrix[y][x] < min) min = matrix[y][x];
          if (matrix[y][x] > max) max = matrix[y][x];
        }
      }

      const size = width * height;
      const data = new Uint8Array(4 * size);

      // Mapeia os valores para a escala de 0-255
      for (let i = 0; i < size; i++) {
        const y = Math.floor(i / width);
        const x = i % width;
        const normalized = (matrix[y][x] - min) / (max - min);
        const value = Math.floor(normalized * 255);
        const stride = i * 4;
        data[stride] = value;
        data[stride + 1] = value;
        data[stride + 2] = value;
        data[stride + 3] = 255;
      }

      const texture = new THREE.DataTexture(data, width, height);
      texture.needsUpdate = true;

      return texture;
    },

    addMatrixMesh(matrix) {
      const width = matrix[0].length;
      const height = matrix.length;
      const texture = this.matrixToTexture(matrix, width, height);

      const geometry = new THREE.PlaneGeometry(50, 50);
      const material = new THREE.MeshBasicMaterial({ map: texture });
      const mesh = new THREE.Mesh(geometry, material);
      this.scene.add(mesh);
    },

    highlightTeethRegions(geometry) {
      const plane = this.determineAlignmentAndFrontDirection(geometry).plane;
    
      const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
      const mesh = new THREE.Mesh(geometry, material);
    
      const boundingBox = new THREE.Box3().setFromObject(mesh);
    
      let minX, maxX, minY, maxY, minZ, maxZ;
    
      if (plane === "xz") {
        minX = boundingBox.min.x;
        maxX = boundingBox.max.x;
        minY = boundingBox.min.y;
        maxY = boundingBox.max.y;
        minZ = boundingBox.min.z;
        maxZ = boundingBox.max.z;
      } else if (plane === "yz") {
        minX = boundingBox.min.y; // Para o plano yz, y é tratado como x
        maxX = boundingBox.max.y;
        minZ = boundingBox.min.x; // e x como z
        maxZ = boundingBox.max.x;
        minY = boundingBox.min.z;
        maxY = boundingBox.max.z;
      } else if (plane === "xy") {
        minX = boundingBox.min.x;
        maxX = boundingBox.max.x;
        minZ = boundingBox.min.z;
        maxZ = boundingBox.max.z;
        // eslint-disable-next-line no-unused-vars
        minY = boundingBox.min.y;
        // eslint-disable-next-line no-unused-vars
        maxY = boundingBox.max.y;
      }
    
      // Defina a dimensão da matriz para 512x512
      const width = 512;
      const height = 512;
    
      // Crie a matriz 2D para acumular as alturas
      const matrix = Array.from({ length: width }, () => new Array(height).fill(0));
    
      const positions = geometry.attributes.position.array;
      const numVertices = positions.length / 3;
      for (let i = 0; i < numVertices; i++) {
        const x = positions[i * 3];
        const y = positions[i * 3 + 1];
        const z = positions[i * 3 + 2];
    
        // Normaliza as coordenadas para o tamanho da matriz
        const coordX = Math.floor(((x - minX) / (maxX - minX)) * (width - 1));
        const coordY = Math.floor(((z - minZ) / (maxZ - minZ)) * (height - 1));
    
        let heightValue;
        if (plane === "xz") {
          heightValue = y;
        } else if (plane === "yz") {
          heightValue = x;
        } else if (plane === "xy") {
          heightValue = z;
        }
    
        // Acumula a altura na matriz
        if (coordX >= 0 && coordX < width && coordY >= 0 && coordY < height) {
          //const coord = Math.floor(((heightValue - minY) / (maxY - minY)) * (25 - 1));
          matrix[coordX][coordY] += heightValue;
        }
      }
    
      const teethRegions = [];
      // Identifica regiões de interesse
      for (let i = 0; i < width; i++) {
        for (let j = 0; j < height; j++) {
          if (matrix[i][j] < 0) { // Ajuste conforme necessário
            teethRegions.push({ x: i, y: j, height: matrix[i][j] });
          }
        }
      }
      this.addMatrixMesh(matrix);
      return teethRegions;
    }
    ,

    determineAlignmentAndFrontDirection(geometry) {
      // Cria um material e um mesh a partir da geometria
      const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
      const mesh = new THREE.Mesh(geometry, material);

      // Cria uma instância de Box3 para calcular a bounding box
      const boundingBox = new THREE.Box3().setFromObject(mesh);

      // Obtém os limites da bounding box
      const min = boundingBox.min;
      const max = boundingBox.max;

      // Calcula as extensões em cada eixo
      const extentX = max.x - min.x;
      const extentY = max.y - min.y;
      const extentZ = max.z - min.z;

      // Determina o plano e o eixo da altura
      let plane = "";
      let heightAxis = "";
      if (extentY < extentX && extentY < extentZ) {
        heightAxis = "y";
        plane = "xz";
      } else if (extentX < extentY && extentX < extentZ) {
        heightAxis = "x";
        plane = "yz";
      } else {
        heightAxis = "z";
        plane = "xy";
      }

      // Centraliza o modelo
      const center = boundingBox.getCenter(new THREE.Vector3());
      geometry.translate(-center.x, -center.y, -center.z);

      // Analisa a distribuição dos pontos para identificar a direção da frente
      const points = geometry.attributes.position.array;
      const pointDensity = [0, 0, 0, 0]; // +z, +x, -z, -x

      for (let i = 0; i < points.length; i += 3) {
        const x = points[i];
        const y = points[i + 1];
        const z = points[i + 2];
        let angle;

        if (plane === "xz") {
          angle = Math.atan2(x, z) * (180 / Math.PI);
        } else if (plane === "yz") {
          angle = Math.atan2(y, z) * (180 / Math.PI);
        } else if (plane === "xy") {
          angle = Math.atan2(x, y) * (180 / Math.PI);
        }

        if (angle >= -45 && angle < 45) {
          pointDensity[0]++; // +z
        } else if (angle >= 45 && angle < 135) {
          pointDensity[1]++; // +x
        } else if (angle >= 135 || angle < -135) {
          pointDensity[2]++; // -z
        } else {
          pointDensity[3]++; // -x
        }
      }

      const minDensity = Math.min(...pointDensity);
      const frontIndex = pointDensity.indexOf(minDensity);

      let front = "";
      switch (frontIndex) {
        case 0:
          front = "-" + plane[1];
          break;
        case 1:
          front = "-" + plane[0];
          break;
        case 2:
          front = "+" + plane[1];
          break;
        case 3:
          front = "+" + plane[0];
          break;
      }

      return { plane, height: heightAxis, front };
    },

    async simpleAnalysis() {
      const geometry = this.activeObject.geometry;

      // Inicializa o mapa de vértices
      const vertexMap = this.initializeVertexMapSimple(geometry);

      // Constrói o mapa de adjacência com base no mapa de vértices
      const adjacencyMap = this.buildAdjacencyMap(vertexMap);

      // Criação do mapa de probabilidade
      const probabilityMap = this.createProbabilityMap(
        geometry,
        vertexMap,
        adjacencyMap,
        "inferior", // ou 'superior'
        3 // Limite de proximidade
      );

      const threshold = 1; // Ajuste conforme necessário
      const { dentes, gengiva } = this.classifyTriangles(
        probabilityMap,
        threshold
      );

      console.log("Triângulos que são dentes:", dentes);
      console.log("Triângulos que são gengiva:", gengiva);

      // Encontrar os centros dos dentes
      const toothCenters = this.findToothCenters(geometry, dentes);
      console.log("Centros dos dentes:", toothCenters);

      // Agrupar os centros dos dentes usando K-means
      const groupedToothCenters = this.groupToothCentroidsWithKMeans(
        toothCenters,
        16
      );
      console.log("Centros dos dentes agrupados:", groupedToothCenters);

      // Aplica cores diferentes aos triângulos classificados como dentes e gengiva
      this.colorizeTriangles(geometry, dentes);

      // Adicionar esferas para visualizar os centros dos dentes
      this.addToothCentersToScene(groupedToothCenters.flat());
    },

    // Função para adicionar esferas na posição dos centros dos dentes
    addToothCentersToScene(toothCenters) {
      toothCenters.forEach((center) => {
        const geometry = new THREE.SphereGeometry(0.2, 32, 32);
        const material = new THREE.MeshBasicMaterial({ color: 0x0000ff });
        const sphere = new THREE.Mesh(geometry, material);
        sphere.position.copy(center);
        this.scene.add(sphere);
      });
    },
    calculateTriangleCentroid(vertices, triangleIndex) {
      const v1 = new THREE.Vector3().fromArray(vertices, triangleIndex * 9);
      const v2 = new THREE.Vector3().fromArray(vertices, triangleIndex * 9 + 3);
      const v3 = new THREE.Vector3().fromArray(vertices, triangleIndex * 9 + 6);

      const centroid = new THREE.Vector3(
        (v1.x + v2.x + v3.x) / 3,
        (v1.y + v2.y + v3.y) / 3,
        (v1.z + v2.z + v3.z) / 3
      );

      return centroid;
    },

    groupToothCentroids(centroids, proximidadeLimite = 2) {
      const groups = [];
      const visited = new Set();

      centroids.forEach((centroid, index) => {
        if (visited.has(index)) return;

        const group = [];
        const queue = [index];

        while (queue.length > 0) {
          const currentIndex = queue.shift(); // Usar shift() para garantir ordem correta de processamento
          if (visited.has(currentIndex)) continue;

          visited.add(currentIndex);
          group.push(centroids[currentIndex]);

          for (let i = 0; i < centroids.length; i++) {
            if (
              !visited.has(i) &&
              centroids[currentIndex].distanceTo(centroids[i]) <
                proximidadeLimite
            ) {
              queue.push(i);
            }
          }
        }

        groups.push(group);
      });

      return groups;
    },
    calculateGroupCentroid(group) {
      const sum = group.reduce(
        (acc, centroid) => acc.add(centroid),
        new THREE.Vector3()
      );
      return sum.divideScalar(group.length);
    },

    findToothCenters(geometry, dentes) {
      const vertices = geometry.attributes.position.array;
      const toothCenters = [];

      // Calcular o centroide de cada triângulo classificado como dente
      dentes.forEach((triangleIndex) => {
        const centroid = this.calculateTriangleCentroid(
          vertices,
          triangleIndex
        );
        toothCenters.push(centroid);
      });
      return toothCenters;
    },

    colorizeTriangles(geometry, dentes) {
      const colorGengiva = new THREE.Color(0xff0000); // Verde para gengiva
      const colorDente = new THREE.Color(0xffffff); // Vermelho para dentes

      // Inicializa o array de cores preenchendo com a cor de gengiva
      const colors = new Float32Array(geometry.attributes.position.count * 3);

      for (let i = 0; i < geometry.attributes.position.count; i++) {
        colors[i * 3] = colorGengiva.r;
        colors[i * 3 + 1] = colorGengiva.g;
        colors[i * 3 + 2] = colorGengiva.b;
      }

      // Ajusta as cores dos triângulos classificados como dentes
      dentes.forEach((index) => {
        colors[index * 9] = colorDente.r;
        colors[index * 9 + 1] = colorDente.g;
        colors[index * 9 + 2] = colorDente.b;
        colors[index * 9 + 3] = colorDente.r;
        colors[index * 9 + 4] = colorDente.g;
        colors[index * 9 + 5] = colorDente.b;
        colors[index * 9 + 6] = colorDente.r;
        colors[index * 9 + 7] = colorDente.g;
        colors[index * 9 + 8] = colorDente.b;
      });

      // Cria uma cópia da geometria original
      const coloredGeometry = geometry.clone();

      // Define o atributo de cor na geometria clonada
      coloredGeometry.setAttribute(
        "color",
        new THREE.Float32BufferAttribute(colors, 3)
      );

      // Cria um material com wireframe e cores por vértice
      const material = new THREE.MeshBasicMaterial({
        wireframe: false,
        vertexColors: true,
      });

      // Cria um novo mesh com a geometria colorida e o material
      const coloredMesh = new THREE.Mesh(coloredGeometry, material);

      // Adiciona o novo mesh à cena
      this.scene.add(coloredMesh);

      console.warn("Done");
    },

    // groupToothCentroidsWithKMeans(centroids, numClusters = 16) {
    //   // Converta os centroides para uma matriz de pontos
    //   const points = centroids.map(centroid => [centroid.x, centroid.y, centroid.z]);

    //   // Execute o K-means
    //   const kmeans = new KMeans({ k: numClusters });
    //   kmeans.fit(points);

    //   // Agrupe os centroides com base nas etiquetas
    //   const groups = Array.from({ length: numClusters }, () => []);
    //   points.forEach((point, index) => {
    //     const label = kmeans.predict([point])[0];
    //     groups[label].push(centroids[index]);
    //   });

    //   return groups;
    // },

    initializeVertexMapSimple(geometry) {
      console.table(geometry)
      const positions = geometry.attributes.position.array;
      const totalTriangles = positions.length / 9; // Cada triângulo tem 3 vértices
      const vertexMap = new Map();

      for (let i = 0; i < totalTriangles; i++) {
        const baseIndex = i * 9;
        for (let j = 0; j < 3; j++) {
          // Loop para cada vértice do triângulo
          const x = positions[baseIndex + j * 3];
          const y = positions[baseIndex + j * 3 + 1];
          const z = positions[baseIndex + j * 3 + 2];
          const key = `${x.toFixed(15)}-${y.toFixed(15)}-${z.toFixed(15)}`; // Reduzindo a precisão para evitar erros de ponto flutuante

          if (!vertexMap.has(key)) {
            vertexMap.set(key, []);
          }
          vertexMap.get(key).push(i);
        }
      }

      return vertexMap;
    },

    buildAdjacencyMap(vertexMap) {
      const adjacencyMap = new Map();

      for (let triangleIndex of vertexMap.values()) {
        for (let i = 0; i < triangleIndex.length; i++) {
          for (let j = i + 1; j < triangleIndex.length; j++) {
            const index1 = triangleIndex[i];
            const index2 = triangleIndex[j];

            if (!adjacencyMap.has(index1)) {
              adjacencyMap.set(index1, new Set());
            }
            adjacencyMap.get(index1).add(index2);

            if (!adjacencyMap.has(index2)) {
              adjacencyMap.set(index2, new Set());
            }
            adjacencyMap.get(index2).add(index1);
          }
        }
      }

      return adjacencyMap;
    },

    createProbabilityMap(
      geometry,
      vertexMap,
      adjacencyMap,
      tipoArcada,
      proximidadeLimite
    ) {
      const vertices = geometry.attributes.position.array;
      const alturaMedia = this.calculateMaxMinHeight(vertices, tipoArcada);
      const probabilidadeMap = [];

      for (let i = 0; i < vertices.length; i += 9) {
        const triangleIndex = i / 9;

        // Cálculo da concentração de triângulos dentro do limite de proximidade
        const concentracao = this.calculateConcentration(
          vertices,
          vertexMap.get(this.getKeyFromVertex(vertices, i)),
          adjacencyMap.get(triangleIndex),
          proximidadeLimite
        );

        // Ponderação pela altura
        const pesoAltura = this.calculateHeightWeight(
          new THREE.Vector3().fromArray(vertices, i),
          new THREE.Vector3().fromArray(vertices, i + 3),
          new THREE.Vector3().fromArray(vertices, i + 6),
          alturaMedia,
          tipoArcada
        );

        // Pontuação de probabilidade final para o triângulo
        const probabilidade = concentracao * (2 * pesoAltura);

        probabilidadeMap.push({
          indice: triangleIndex,
          probabilidade,
          pesoAltura,
          concentracao,
        });
      }

      return probabilidadeMap;
    },

    calculateConcentration(
      vertices,
      vertexIndices,
      neighbors,
      proximidadeLimite
    ) {
      let concentracao = 0;
      const tempVector = new THREE.Vector3(); // Vetor temporário para armazenar os vértices
      const tempNeighborVector = new THREE.Vector3(); // Vetor temporário para os vizinhos

      for (const index of neighbors) {
        // Obter o vértice do array de vértices
        const v = tempNeighborVector.fromArray(vertices, index * 3);

        // Verificar a distância euclidiana entre v e o vértice atual
        let isProximo = false;
        for (let i = 0; i < vertexIndices.length; i++) {
          const vi = tempVector.fromArray(vertices, vertexIndices[i] * 3); // Obtém o vértice correto do triângulo
          const distance = v.distanceTo(vi);
          if (distance < proximidadeLimite) {
            isProximo = true;
            break;
          }
        }

        if (isProximo) {
          concentracao++;
        }
      }

      return concentracao / neighbors.size;
    },
    calculateMaxMinHeight(vertices, tipoArcada) {
      let alturaExtrema = tipoArcada === "superior" ? -Infinity : Infinity;

      for (let i = 1; i < vertices.length; i += 3) {
        const y = vertices[i];
        if (tipoArcada === "superior" && y > alturaExtrema) {
          alturaExtrema = y;
        } else if (tipoArcada === "inferior" && y < alturaExtrema) {
          alturaExtrema = y;
        }
      }

      return Math.abs(alturaExtrema);
    },

    calculateHeightWeight(v1, v2, v3, alturaExtrema) {
      const alturaMediaTriangulo = Math.abs((v1.y + v2.y + v3.y) / 3);
      let alturaRelativa;

      alturaRelativa = (alturaExtrema - alturaMediaTriangulo) / alturaExtrema;

      return alturaRelativa;
    },
    classifyTriangles(probabilidadeMap, threshold) {
      const dentes = [];
      const gengiva = [];

      for (const item of probabilidadeMap) {
        if (Math.abs(item.probabilidade) > threshold) {
          dentes.push(item.indice);
        } else {
          gengiva.push(item.indice);
        }
      }

      return { dentes, gengiva };
    },

    getKeyFromVertex(vertices, index) {
      const precision = 15;
      const x = vertices[index];
      const y = vertices[index + 1];
      const z = vertices[index + 2];
      return `${x.toFixed(precision)}-${y.toFixed(precision)}-${z.toFixed(
        precision
      )}`;
    },
    async executeRaycasting() {
      this.statusMessage = "";
      this.removeBorderMesh();
      const contourMesh = this.findContourMesh();
      const tubeGeometries = this.findTubeGeometries(contourMesh);
      const selectedTriangles = this.selectCloseTriangles(
        this.activeObject,
        tubeGeometries,
        0.45
      );
      if (!selectedTriangles) {
        this.statusMessage = "A borda selecionada não está fechada.";
      } else {
        this.createMeshFromCloseTriangles(this.activeObject, selectedTriangles);
        const isUpperArc =
          this.activeObject.userData.modelType === "maxillary" ? true : false;
        const meshSegmenter = new MeshSegmenter(
          this.activeObject,
          selectedTriangles,
          isUpperArc,
          10
        );
        meshSegmenter.segment();
        console.table({
          candidate: meshSegmenter.candidateTriangles.length,
          border: meshSegmenter.borderTriangleIndices.length,
          segmented: meshSegmenter.segmentedTriangles.size,
          percent: (
            (100 * meshSegmenter.segmentedTriangles.size) /
            meshSegmenter.candidateTriangles.length
          ).toFixed(2),
        });
        if (
          meshSegmenter.segmentedTriangles.size /
            meshSegmenter.candidateTriangles.length >
          0.55
        ) {
          this.statusMessage = "A borda selecionada não está fechada.";
        } else {
          this.createMeshFromCloseTriangles(
            this.activeObject,
            meshSegmenter.segmentedTriangles,
            0xffff00
          );
          if (this.selectedTooth) {
            this.dentalMap[this.selectedTooth].borderPoints =
              this.$store.getters["editor3D/contourPoints"];
            this.dentalMap[this.selectedTooth].borderTriangleIndices =
              selectedTriangles;
            this.dentalMap[this.selectedTooth].segmentedTriangleIndices = [
              ...meshSegmenter.segmentedTriangles,
            ];
          }
        }
      }
      console.table(this.selectedTooth)
      this.iaActive = "gray";
    },
    extractContour(mesh) {
      const edges = new THREE.EdgesGeometry(mesh.geometry, 90);
      const line = new THREE.LineSegments(
        edges,
        new THREE.LineBasicMaterial({ color: 0x0000ff })
      );

      // Extract points from the edges geometry
      const positions = line.geometry.attributes.position.array;
      const points = [];
      for (let i = 0; i < positions.length; i += 3) {
        points.push(
          new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2])
        );
      }

      // Render points as a separate object (optional)
      const pointGeometry = new THREE.BufferGeometry().setFromPoints(points);
      const pointMaterial = new THREE.PointsMaterial({
        color: 0xffffff,
        size: 0.5,
      });
      const pointMesh = new THREE.Points(pointGeometry, pointMaterial);
      return pointMesh;
    },
    DBSCAN(data, eps, MinPts) {
      let clusters = [];
      let visited = new Set();
      let noise = new Set();
      let inCluster = new Set();

      console.table({ data, eps, MinPts });
      console.warn(data.length);

      function regionQuery(point) {
        // Retorna pontos dentro do raio eps
        return data.filter((p) => p.distanceTo(point) < eps);
      }

      function expandCluster(point, neighbors, cluster) {
        visited.add(point);
        inCluster.add(point);
        cluster.push(point);

        let i = 0;
        while (i < neighbors.length) {
          let neighbor = neighbors[i];
          if (!visited.has(neighbor)) {
            visited.add(neighbor);
            const neighborsPrime = regionQuery(neighbor);
            if (neighborsPrime.length >= MinPts) {
              neighbors = neighbors.concat(neighborsPrime);
            }
          }
          if (!inCluster.has(neighbor)) {
            cluster.push(neighbor);
            inCluster.add(neighbor);
          }
          i++;
        }
      }

      data.forEach((point) => {
        if (!visited.has(point)) {
          const neighbors = regionQuery(point);
          if (neighbors.length < MinPts) {
            noise.add(point);
            visited.add(point);
          } else {
            const newCluster = [];
            expandCluster(point, neighbors, newCluster);
            clusters.push(newCluster);
          }
        }
      });

      return clusters;
    },
    dynamicSurfaceProximityMethod() {
      // Assumindo que `originalGeometry` é a sua geometria 3D
      const edgesGeometry = new THREE.EdgesGeometry(
        this.activeObject.geometry,
        90
      );

      //const edgesMaterial = new THREE.LineBasicMaterial({ color: 0xffffff });
      //const edges = new THREE.LineSegments(edgesGeometry, edgesMaterial);

      // Adiciona edges ao cenário se precisar visualizar
      //this.scene.add(edges);

      // Assumindo que 'edgesGeometry' é uma instância de THREE.EdgesGeometry
      const positionAttribute = edgesGeometry.attributes.position;

      // Use um Map para armazenar os pontos com suas posições como chave
      let edgePointsMap = new Map();

      for (let i = 0; i < positionAttribute.count; i++) {
        let point = new THREE.Vector3();
        point.fromBufferAttribute(positionAttribute, i);

        // Converta o ponto para uma string que será usada como chave única
        const key = `${point.x.toFixed(10)}_${point.y.toFixed(
          10
        )}_${point.z.toFixed(10)}`;
        if (!edgePointsMap.has(key)) {
          edgePointsMap.set(key, point);
        }
      }

      // Agora, 'edgePointsMap' contém apenas pontos únicos. Podemos transformá-lo de volta em um array
      let edgePoints = Array.from(edgePointsMap.values());

      // Verifique se cada ponto tem 2 vizinhos
      edgePoints = edgePoints.filter((point, index, array) => {
        let neighbors = array.filter(
          (otherPoint) =>
            point.distanceTo(otherPoint) < 1 && point !== otherPoint
        );
        return neighbors.length > 2; // Apenas mantenha pontos com exatamente 2 vizinhos
      });

      // Para cada centro de triângulo, calcular a distância mais curta até os pontos de borda
      this.activeObject.geometry.computeBoundingBox();

      const centers = []; // Array para armazenar centros dos triângulos
      const distances = []; // Array para armazenar a transformada de distância de cada centro

      const vertices = this.activeObject.geometry.attributes.position;
      // Vetores reutilizáveis para os vértices dos triângulos
      const v1 = new THREE.Vector3();
      const v2 = new THREE.Vector3();
      const v3 = new THREE.Vector3();
      // Vetor reutilizável para o centro do triângulo
      const center = new THREE.Vector3();

      for (let i = 0; i < vertices.count; i += 3) {
        // Reutiliza os vetores para calcular o centro do triângulo
        v1.fromBufferAttribute(vertices, i);
        v2.fromBufferAttribute(vertices, i + 1);
        v3.fromBufferAttribute(vertices, i + 2);
        center.addVectors(v1, v2).add(v3).divideScalar(3);

        // Calcula a distância mais curta até os pontos de borda
        let minDistance = edgePoints.reduce(
          (min, edgePoint) => Math.min(min, center.distanceTo(edgePoint)),
          Infinity
        );

        centers.push(center.clone()); // Clona o centro antes de adicionar ao array
        distances.push(minDistance);
      }

      // Encontrar a distância máxima para basear a escala de cores
      const maxDistance = Math.max(...distances);

      // Após calcular centers e distances no método dynamicSurfaceProximityMethod
      let pointsWithRatios = centers.map((point, index) => {
        return {
          point: point,
          distanceRatio: distances[index] / maxDistance, // Normalizando
        };
      });

      const highPoints = [];
      pointsWithRatios.forEach((item) => {
        if (item.distanceRatio > 0.25) highPoints.push(item.point);
      });

      // const clustersDB = this.DBSCAN(highPoints, 1, 50);
      // this.displayClusters(clustersDB);
      // console.table(clustersDB)

      let { clusters, centroids } = this.kMedoids(highPoints, 16);
      console.table({ highPoints, clusters, centroids });
      this.displayClustersAndCentroids(clusters, centroids);

      // centers.forEach((center, index) => {
      //   const distance = distances[index];
      //   // Calcula a proporção da distância em relação à máxima
      //   const distanceRatio = distance / maxDistance;

      //   if (distanceRatio > 0.40) {
      //     // Converte essa proporção em uma cor na escala de "frio" para "quente" (azul para vermelho)
      //     // Usaremos uma interpolação simples: azul (menor distância) a vermelho (maior distância)
      //     const color = new THREE.Color().lerpColors(
      //       new THREE.Color(0x0000ff), // Azul
      //       new THREE.Color(0xff0000), // Vermelho
      //       distanceRatio // Proporção que define a mistura das cores
      //     );

      //     // Cria a esfera
      //     const sphereGeometry = new THREE.SphereGeometry(0.2, 4, 4); // Ajuste o tamanho conforme necessário
      //     const sphereMaterial = new THREE.MeshBasicMaterial({ color: color });
      //     const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

      //     // Posiciona a esfera no centro calculado
      //     sphere.position.copy(center);

      //     // Adiciona a esfera à cena
      //     this.activeObject.add(sphere); // Ou scene.add(sphere) se preferir adicionar direto na cena
      //   }
      // });
    },
    displayClusters(clusters, lines = false) {
      const colors = [
        0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0x00ffff, 0xff00ff, 0xffffff,
      ]; // Cores diferentes para cada cluster
      const material = new THREE.LineBasicMaterial({ color: 0xffffff }); // Branco para linhas

      // Buscar ou criar o grupo 'dbscan'
      let dbscanGroup = this.scene.getObjectByName("dbscan");
      if (!dbscanGroup) {
        dbscanGroup = new THREE.Group();
        dbscanGroup.name = "dbscan";
        this.scene.add(dbscanGroup);
      } else {
        // Limpar o grupo existente para evitar sobreposição de objetos
        dbscanGroup.clear();
      }

      clusters.forEach((cluster, index) => {
        const clusterColor = colors[index % colors.length]; // Escolhe uma cor da lista
        cluster.forEach((point) => {
          // Criar uma esfera para cada ponto no cluster
          const sphereGeometry = new THREE.SphereGeometry(0.1, 16, 16); // Tamanho e resolução da esfera menor
          const sphereMaterial = new THREE.MeshBasicMaterial({
            color: clusterColor,
          });
          const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
          sphere.position.copy(point);
          dbscanGroup.add(sphere);

          // Criar linhas, se necessário
          if (lines) {
            cluster.forEach((otherPoint) => {
              if (point !== otherPoint) {
                const points = [];
                points.push(point.clone());
                points.push(otherPoint.clone());

                const geometry = new THREE.BufferGeometry().setFromPoints(
                  points
                );
                const line = new THREE.Line(geometry, material);
                dbscanGroup.add(line);
              }
            });
          }
        });
      });
    },
    displayClustersAndCentroids(clusters, centroids, lines = false) {
      const centroidMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 }); // Vermelho para centroids
      const lineMaterial = new THREE.LineBasicMaterial({ color: 0xffffff }); // Branco para linhas

      // Buscar ou criar o grupo 'kmeans'
      let kmeansGroup = this.scene.getObjectByName("kmeans");
      if (!kmeansGroup) {
        kmeansGroup = new THREE.Group();
        kmeansGroup.name = "kmeans";
        this.scene.add(kmeansGroup);
      } else {
        // Limpar o grupo existente para evitar sobreposição de objetos
        kmeansGroup.clear();
      }

      centroids.forEach((centroid, index) => {
        // Criar uma esfera para cada centroid
        const sphereGeometry = new THREE.SphereGeometry(1, 16, 16); // Tamanho e resolução da esfera
        const centroidSphere = new THREE.Mesh(sphereGeometry, centroidMaterial);
        centroidSphere.position.copy(centroid);
        kmeansGroup.add(centroidSphere);

        // Criar linhas para cada ponto no cluster correspondente, se necessário
        if (lines) {
          clusters[index].forEach((data) => {
            const points = [];
            points.push(centroid.clone());
            points.push(data.clone());

            const geometry = new THREE.BufferGeometry().setFromPoints(points);
            const line = new THREE.Line(geometry, lineMaterial);
            kmeansGroup.add(line);
          });
        }
      });
    },
    kMeans(points, k) {
      // Inicializa os centroids aleatoriamente
      let centroids = points
        .slice(0)
        .sort(() => 0.5 - Math.random())
        .slice(0, k);
      let oldCentroids = [];
      let iterations = 0;
      let maxIterations = 10000; // Evitar loop infinito
      let clusters;

      while (
        !this.isEqual(oldCentroids, centroids) &&
        iterations < maxIterations
      ) {
        // Agrupa os pontos pelo centroid mais próximo
        clusters = points.reduce((acc, point) => {
          let minDist = Infinity;
          let index = 0;
          centroids.forEach((centroid, i) => {
            let dist = point.distanceTo(centroid);
            if (dist < minDist) {
              minDist = dist;
              index = i;
            }
          });
          if (!acc[index]) {
            acc[index] = [];
          }
          acc[index].push(point);
          return acc;
        }, []);

        // Atualiza os centroids para serem o centro médio de seus clusters
        oldCentroids = centroids;
        centroids = clusters.map((cluster) => {
          let sum = new THREE.Vector3();
          cluster.forEach((p) => sum.add(p));
          sum.divideScalar(cluster.length);
          return sum;
        });
        iterations++;
      }

      return { clusters, centroids };
    },
    kMeansPlusPlus(points, k) {
      let centroids = [];
      // Escolhe o primeiro centroid aleatoriamente dos pontos
      centroids.push(points[Math.floor(Math.random() * points.length)]);

      // Escolhe k-1 centroids
      for (let i = 1; i < k; i++) {
        // Calcula a distância mínima de cada ponto para todos os centroids escolhidos
        let distances = points.map((p) =>
          Math.min(...centroids.map((c) => p.distanceTo(c)))
        );

        // Escolhe um novo centroid com probabilidade proporcional à distância ao quadrado
        let totalDistance = distances.reduce(
          (sum, dist) => sum + dist * dist,
          0
        );
        let random = Math.random() * totalDistance;
        for (let j = 0, sum = 0; j < distances.length; j++) {
          sum += distances[j] * distances[j];
          if (sum >= random) {
            centroids.push(points[j]);
            break;
          }
        }
      }

      return centroids;
    },
    kMedoids(points, k) {
      // Inicializa os centroids com K-means++
      let centroids = this.kMeansPlusPlus(points, k);
      let oldCentroids = [];
      let iterations = 0;
      let maxIterations = 1000; // Evitar loop infinito
      let clusters;

      while (
        !this.isEqual(oldCentroids, centroids) &&
        iterations < maxIterations
      ) {
        // Agrupa os pontos pelo centroid mais próximo
        clusters = points.reduce((acc, point) => {
          let minDist = Infinity;
          let index = 0;
          centroids.forEach((centroid, i) => {
            let dist = point.distanceTo(centroid);
            if (dist < minDist) {
              minDist = dist;
              index = i;
            }
          });
          if (!acc[index]) {
            acc[index] = [];
          }
          acc[index].push(point);
          return acc;
        }, []);

        // Atualiza os centroids para serem o ponto mais próximo do centro médio de seus clusters
        oldCentroids = centroids;
        centroids = clusters.map((cluster) => {
          let centroid = cluster[0]; // Pega o primeiro ponto do cluster como inicial
          let center = new THREE.Vector3();
          cluster.forEach((p) => center.add(p));
          center.divideScalar(cluster.length);

          let minDist = Infinity;
          cluster.forEach((p) => {
            let dist = p.distanceTo(center);
            if (dist < minDist) {
              minDist = dist;
              centroid = p;
            }
          });
          return centroid;
        });
        iterations++;
      }

      return { clusters, centroids };
    },
    isEqual(oldCentroids, centroids) {
      if (oldCentroids.length !== centroids.length) return false;
      for (let i = 0; i < centroids.length; i++) {
        if (!oldCentroids[i].equals(centroids[i])) {
          return false;
        }
      }
      return true;
    },
    findDenseRegions(radius) {
      const geometry = this.activeObject.geometry;
      const vertices = geometry.attributes.position.array;
      const step = radius;
      const densityMap = new Map();

      geometry.computeBoundingBox();
      const bbox = geometry.boundingBox;

      const ignoreTopBottomPercentage = 0.1;
      const heightAdjustment =
        (bbox.max.y - bbox.min.y) * ignoreTopBottomPercentage;
      const adjustedMinY = bbox.min.y + heightAdjustment;
      const adjustedMaxY = bbox.max.y - heightAdjustment;

      const vertexA = new THREE.Vector3();
      const vertexB = new THREE.Vector3();
      const vertexC = new THREE.Vector3();
      const center = new THREE.Vector3();

      for (let x = bbox.min.x; x <= bbox.max.x; x += step) {
        for (let y = adjustedMinY; y <= adjustedMaxY; y += step) {
          for (let z = bbox.min.z; z <= bbox.max.z; z += step) {
            center.set(x, y, z);
            let count = 0; // Contagem de vértices dentro do raio
            let areaSum = 0; // Soma da área dos triângulos dentro do raio

            for (let i = 0; i < vertices.length; i += 9) {
              vertexA.set(vertices[i], vertices[i + 1], vertices[i + 2]);
              vertexB.set(vertices[i + 3], vertices[i + 4], vertices[i + 5]);
              vertexC.set(vertices[i + 6], vertices[i + 7], vertices[i + 8]);

              // Verifica se algum dos vértices do triângulo está dentro do raio especificado
              const distanceA = center.distanceTo(vertexA);
              const distanceB = center.distanceTo(vertexB);
              const distanceC = center.distanceTo(vertexC);
              if (
                distanceA <= radius ||
                distanceB <= radius ||
                distanceC <= radius
              ) {
                count +=
                  (distanceA <= radius) +
                  (distanceB <= radius) +
                  (distanceC <= radius);
                const area = this.triangleArea(vertexA, vertexB, vertexC);
                areaSum += area; // Soma a área deste triângulo se algum vértice está dentro do raio
              }
            }

            if (count > 0 || areaSum > 0) {
              // Armazena tanto a contagem de vértices quanto a soma das áreas
              densityMap.set(JSON.stringify({ x, y, z }), { count, areaSum });
            }
          }
        }
      }

      console.warn(`Total de pontos rastreados ${densityMap.size}`);

      // Extrai os valores acumulados para contagem de vértices e soma das áreas
      const totals = Array.from(densityMap.values()).reduce(
        (acc, value) => {
          acc.totalVerticesCount += value.count;
          acc.totalAreaSum += value.areaSum;
          return acc;
        },
        { totalVerticesCount: 0, totalAreaSum: 0 }
      );

      // Calcula a densidade média de vértices e a área média
      const averageVertexDensity = totals.totalVerticesCount / densityMap.size;
      const averageArea = totals.totalAreaSum / densityMap.size;

      console.log(`Densidade média de vértices: ${averageVertexDensity}`);
      console.log(`Área média dos triângulos: ${averageArea}`);

      // Define o múltiplo da densidade média de vértices e da área média para usar como limiares
      const vertexDensityThresholdMultiplier = 1.45;
      const areaThresholdMultiplier = 1.75; // Ajustável conforme a necessidade

      // Calcula os limiares dinâmicos
      const dynamicVertexThreshold =
        averageVertexDensity * vertexDensityThresholdMultiplier;
      const dynamicAreaThreshold = averageArea * areaThresholdMultiplier;

      // Filtra, ordena os centros com base em critérios combinados e seleciona os top N (ex: 32) primeiros
      const topCenters = Array.from(densityMap)
        // eslint-disable-next-line no-unused-vars
        .filter(
          ([, value]) =>
            value.count > dynamicVertexThreshold &&
            value.areaSum > dynamicAreaThreshold
        ) // Aplica ambos os limiares
        .sort((a, b) => {
          // Critério de ordenação combinado pode ser ajustado conforme necessário
          const densityDiff = b[1].count - a[1].count;
          if (densityDiff !== 0) return densityDiff; // Prioriza densidade de vértices se diferir
          return b[1].areaSum - a[1].areaSum; // Caso contrário, usa a soma das áreas como desempate
        });

      // Plota esferas nos centros de maior densidade
      topCenters.forEach((center) => {
        const position = JSON.parse(center[0]);
        const sphereGeometry = new THREE.SphereGeometry(radius, 8, 8); // Raio reduzido para visualização
        const sphereMaterial = new THREE.MeshBasicMaterial({
          color: 0xffff00,
          transparent: true,
          opacity: 0.25,
        });
        const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
        sphere.position.set(position.x, position.y, position.z);
        this.activeObject.add(sphere); // Adiciona a esfera ao mesh (ou scene.add(sphere) se preferir adicionar à cena)
      });

      console.log("Centros de maior densidade:", topCenters);
    },
    triangleArea(vertexA, vertexB, vertexC) {
      // Calcula a área do triângulo usando a fórmula de Heron adaptada para vetores
      const sideAB = vertexB.clone().sub(vertexA).length();
      const sideBC = vertexC.clone().sub(vertexB).length();
      const sideCA = vertexA.clone().sub(vertexC).length();
      const s = (sideAB + sideBC + sideCA) / 2;
      return Math.sqrt(s * (s - sideAB) * (s - sideBC) * (s - sideCA));
    },
    centerGeometry(geometry, mesh) {
      geometry.computeBoundingBox();
      const offset = geometry.boundingBox
        .getCenter(new THREE.Vector3())
        .negate();

      // Calcula a posição original do mesh
      const originalPosition = mesh.position.clone();

      // Translada a geometria para o centro de massa estar na origem
      geometry.translate(offset.x, offset.y, offset.z);

      // Atualiza a posição do mesh para compensar a translação da geometria
      mesh.position.sub(offset);

      // Move o mesh de volta para a posição original
      mesh.position.add(originalPosition);
    },
    extractGeometry(activeObject, indices) {
      const positions = activeObject.geometry.attributes.position.array;
      const selectedVertices = [];

      indices.forEach((index) => {
        const baseIdx = index * 9;
        for (let i = 0; i < 9; i++) {
          selectedVertices.push(positions[baseIdx + i]);
        }
      });

      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(selectedVertices, 3)
      );
      geometry.computeVertexNormals();
      this.calculateVertexNormals(geometry);
      return geometry;
    },
    calculateCenterOfMass(borderPoints) {
      const sum = borderPoints.reduce(
        (acc, point) => {
          acc.x += point.x;
          acc.y += point.y;
          acc.z += point.z;
          return acc;
        },
        { x: 0, y: 0, z: 0 }
      );

      const count = borderPoints.length;
      return new THREE.Vector3(sum.x / count, sum.y / count, sum.z / count);
    },
    createCircularContour(center, radius, segments) {
      const points = [];
      for (let i = 0; i < segments; i++) {
        const angle = (i / segments) * Math.PI * 2;
        points.push(
          new THREE.Vector3(
            center.x + radius * Math.cos(angle),
            center.y, // Trava o eixo Y
            center.z + radius * Math.sin(angle)
          )
        );
      }
      return points;
    },
    mergeGeometries(geometries) {
      // Arrays para armazenar os dados combinados
      let combinedVertices = [];
      let combinedIndices = [];
      let combinedNormals = [];

      // Offset necessário para ajustar os índices para a nova lista de vértices
      let vertexOffset = 0;

      // Função auxiliar para concatenar arrays de forma segura
      function concatenateArray(targetArray, sourceArray) {
        const chunkSize = 10000; // Tamanho dos pedaços para evitar estourar a pilha
        for (let i = 0; i < sourceArray.length; i += chunkSize) {
          targetArray.push(...sourceArray.slice(i, i + chunkSize));
        }
      }

      geometries.forEach((geometry) => {
        // Assumindo que a geometria já tenha um atributo de posição
        const positions = geometry.getAttribute("position").array;
        const normals = geometry.getAttribute("normal").array;

        // Adiciona os vértices ao array combinado
        concatenateArray(combinedVertices, positions);

        // Adiciona os normais ao array combinado
        concatenateArray(combinedNormals, normals);

        // Se a geometria tiver um índice, ajusta e adiciona ao array combinado
        if (geometry.index) {
          const indices = geometry.index.array;
          combinedIndices.push(...indices.map((index) => index + vertexOffset));
        } else {
          // Se não houver índice, gera um novo baseado na posição dos vértices
          let numVerts = positions.length / 3;
          for (let i = 0; i < numVerts; i++) {
            combinedIndices.push(i + vertexOffset);
          }
        }

        // Atualiza o offset para a próxima geometria
        vertexOffset += positions.length / 3;
      });

      // Cria uma nova BufferGeometry
      const combinedGeometry = new THREE.BufferGeometry();

      // Adiciona os vértices combinados
      combinedGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(combinedVertices, 3)
      );

      // Adiciona os normais combinados
      combinedGeometry.setAttribute(
        "normal",
        new THREE.Float32BufferAttribute(combinedNormals, 3)
      );

      // Adiciona os índices combinados, se necessário
      if (combinedIndices.length > 0) {
        combinedGeometry.setIndex(combinedIndices);
      }

      combinedGeometry.computeBoundingBox();
      combinedGeometry.computeBoundingSphere();

      return combinedGeometry;
    },
    mergeGeometries_(geometries) {
      // Arrays para armazenar os dados combinados
      let combinedVertices = [];
      let combinedIndices = [];
      let combinedNormals = [];

      // Offset necessário para ajustar os índices para a nova lista de vértices
      let vertexOffset = 0;

      geometries.forEach((geometry) => {
        // Assumindo que a geometria já tenha um atributo de posição
        const positions = geometry.getAttribute("position").array;

        // Adiciona os vértices ao array combinado
        combinedVertices.push(...positions);

        const normals = geometry.getAttribute("normal").array;

        // Adiciona os vértices ao array combinado
        combinedNormals.push(...normals);

        // Se a geometria tiver um índice, ajusta e adiciona ao array combinado
        if (geometry.index) {
          const indices = geometry.index.array;
          combinedIndices.push(...indices.map((index) => index + vertexOffset));
        } else {
          // Se não houver índice, gera um novo baseado na posição dos vértices
          let numVerts = positions.length / 3;
          for (let i = 0; i < numVerts; i++) {
            combinedIndices.push(i + vertexOffset);
          }
        }

        // Atualiza o offset para a próxima geometria
        vertexOffset += positions.length / 3;
      });

      // Cria uma nova BufferGeometry
      const combinedGeometry = new THREE.BufferGeometry();

      // Adiciona os vértices combinados
      combinedGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(combinedVertices, 3)
      );

      // Adiciona os normais combinadas
      combinedGeometry.setAttribute(
        "normal",
        new THREE.Float32BufferAttribute(combinedNormals, 3)
      );

      // Adiciona os índices combinados, se necessário
      if (combinedIndices.length > 0) {
        combinedGeometry.setIndex(combinedIndices);
      }

      combinedGeometry.computeBoundingBox();
      combinedGeometry.computeBoundingSphere();

      return combinedGeometry;
    },

    extractAndCloseGeometry(toothData) {
      // Assume que this.activeObject é acessível e contém a malha dentária completa
      const positions = this.activeObject.geometry.attributes.position.array;
      const selectedVertices = [];

      // Extrai os vértices dos dentes usando os índices dos triângulos segmentados
      toothData.segmentedTriangleIndices.forEach((index) => {
        const baseIdx = index * 9;
        for (let i = 0; i < 9; i++) {
          selectedVertices.push(positions[baseIdx + i]);
        }
      });

      // Extrai os vértices dos dentes usando os índices dos triângulos segmentados
      toothData.borderTriangleIndices.forEach((index) => {
        const baseIdx = index * 9;
        for (let i = 0; i < 9; i++) {
          selectedVertices.push(positions[baseIdx + i]);
        }
      });

      // Cria a geometria do dente
      const toothGeometry = new THREE.BufferGeometry();
      toothGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(selectedVertices, 3)
      );

      const toothEdgesGeometry = new THREE.EdgesGeometry(toothGeometry, 90);
      const edgeVertices = toothEdgesGeometry.attributes.position.array;
      let edgeVerticesArray = [];
      for (let i = 0; i < edgeVertices.length; i += 3) {
        edgeVerticesArray.push(
          new THREE.Vector3(
            edgeVertices[i],
            edgeVertices[i + 1],
            edgeVertices[i + 2]
          )
        );
      }

      //const uniqueVertices = this.uniqueVerticesFunction(edgeVerticesArray);

      // Calcula o centro de massa do dente usando os pontos da borda
      const toothCenter = this.calculateCenterOfMass(toothData.borderPoints);
      //const finalSeamGeometry = this.create3DSeam(uniqueVertices, toothData.borderPoints);

      const isUpperArc =
        this.activeObject.userData.modelType === "maxillary" ? true : false;

      toothCenter.y += isUpperArc ? 4 : -4;
      toothCenter.z -= 2;

      // Gera o contorno da raiz
      const rootContour = this.createCircularContour(
        toothCenter,
        0.5,
        toothData.borderPoints.length
      );

      // Costura a borda do dente com o contorno da raiz
      // eslint-disable-next-line no-unused-vars
      const seamGeometry = this.create3DSeam(
        toothData.borderPoints,
        rootContour
      );

      // Adiciona a esfera de fechamento no centro de massa do dente
      const closingSphereGeometry = new THREE.SphereGeometry(0.5, 16, 16);
      closingSphereGeometry.translate(
        toothCenter.x,
        toothCenter.y,
        toothCenter.z
      );

      const combinedGeometry = this.mergeGeometries([toothGeometry]);

      // Agora calcule as normais dos vértices
      combinedGeometry.computeVertexNormals();
      this.calculateVertexNormals(combinedGeometry);

      // Verifica se não há NaN nas normais
      const normals = combinedGeometry.getAttribute("normal");
      for (let i = 0; i < normals.count; i++) {
        if (
          isNaN(normals.getX(i)) ||
          isNaN(normals.getY(i)) ||
          isNaN(normals.getZ(i))
        ) {
          normals.setXYZ(i, 0, 0, 1);
        }
      }

      // Depois de verificar, atualize o atributo normal
      normals.needsUpdate = true;
      return combinedGeometry;
    },
    newExtractAndCloseGeometry(toothData, toothNumber, activeObject) {
      if (toothData.segmentedTriangleIndices.length === 0) return;
      // Assume que this.activeObject é acessível e contém a malha dentária completa
      const positions = activeObject.geometry.attributes.position.array;
      const normals = activeObject.geometry.attributes.normal.array;
      const selectedVertices = [];
      const selectedNormals = [];

      // Extrai os vértices dos dentes usando os índices dos triângulos segmentados
      toothData.segmentedTriangleIndices.forEach((index) => {
        const baseIdx = index * 9;
        for (let i = 0; i < 9; i++) {
          selectedVertices.push(positions[baseIdx + i]);
        }
        for (let i = 0; i < 3; i++) {
          selectedNormals.push(normals[index * 3 + i]);
        }
      });

      // Cria a geometria do dente
      const toothGeometry = new THREE.BufferGeometry();
      toothGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(selectedVertices, 3)
      );

      toothGeometry.computeVertexNormals();
      this.calculateVertexNormals(toothGeometry);

      console.log('Start 3D reconstruiction')
      const combinedGeometry = this.tooth3DReconstruction(
        toothGeometry,
        toothData,
        toothNumber
      );
      console.log('End 3D reconstruiction')
      // combinedGeometry.computeVertexNormals();
      // this.calculateVertexNormals(combinedGeometry);

      return combinedGeometry;
    },
    createGumGeometry(activeObject, dentalMap) {
      const positions = activeObject.geometry.attributes.position.array;
      const originalTriangleCount = positions.length / 9;
    
      // Criar um Set para armazenar todos os índices de triângulos dos dentes
      const toothTriangles = new Set();
      Object.values(dentalMap).forEach(({ segmentedTriangleIndices, borderTriangleIndices }) => {
        segmentedTriangleIndices.forEach(index => toothTriangles.add(index));
        borderTriangleIndices.forEach(index => toothTriangles.add(index));
      });
    
      // Criar um array para armazenar os vértices da gengiva
      const gumVertices = new Float32Array(positions.length);
      let gumIndex = 0;
    
      // Preencher o array de vértices da gengiva, excluindo os vértices dos dentes
      for (let i = 0; i < originalTriangleCount; i++) {
        if (!toothTriangles.has(i)) {
          for (let j = 0; j < 9; j++) {
            gumVertices[gumIndex++] = positions[i * 9 + j];
          }
        }
      }
    
      const originalVertices = [];
      for (let i = 0; i < activeObject.geometry.attributes.position.count; i++) {
        const vertex = new THREE.Vector3();
        vertex.fromBufferAttribute(activeObject.geometry.attributes.position, i);
        originalVertices.push(vertex);
      }
    
      // Criar uma nova geometria com os vértices da gengiva
      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute("position", new THREE.BufferAttribute(gumVertices.subarray(0, gumIndex), 3));
    
      geometry.computeVertexNormals();
      this.calculateVertexNormals(geometry);
    
      return { geometry: geometry, originalVertices: originalVertices };
    },
    
    createGumGeometry__(activeObject, dentalMap) {
      // Primeiro, criar um array grande o suficiente para conter todos os vértices, mas sem os dentes
      const positions = activeObject.geometry.attributes.position.array;
      const gumVertices = new Float32Array(positions.length);
      let gumIndex = 0;

      console.table(dentalMap);
      // Preencher o array de vértices da gengiva, excluindo os vértices dos dentes
      for (let i = 0, l = positions.length / 9; i < l; i++) {
        if (!this.isToothTriangle(i, dentalMap)) {
          for (let j = 0; j < 9; j++) {
            gumVertices[gumIndex++] = positions[i * 9 + j];
          }
        }
      }

      const originalVertices = [];

      for (
        let i = 0;
        i < activeObject.geometry.attributes.position.count;
        i++
      ) {
        const vertex = new THREE.Vector3();
        vertex.fromBufferAttribute(
          activeObject.geometry.attributes.position,
          i
        );
        originalVertices.push(vertex);
      }

      // Criar uma nova geometria com os vértices da gengiva
      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(gumVertices.subarray(0, gumIndex), 3)
      );

      // Aplicar a suavização laplaciana
      //this.applyLaplacianSmoothing(geometry);

      geometry.computeVertexNormals();
      this.calculateVertexNormals(geometry);

      return { geometry: geometry, originalVertices: originalVertices };
    },

    isToothTriangle(triangleIndex, dentalMap) {
      // Verifica se o índice do triângulo pertence a algum dente
      for (const toothNumber of Object.keys(dentalMap)) {
        if (
          dentalMap[toothNumber].segmentedTriangleIndices.includes(
            triangleIndex
          )
        ) {
          return true;
        }
        if (
          dentalMap[toothNumber].borderTriangleIndices.includes(triangleIndex)
        ) {
          return true;
        }
      }
      return false;
    },

    createGumGeometry_(activeObject, dentalMap) {
      const gumPoints = this.extractGumPoints(activeObject, dentalMap);
      const gumGeometry = this.triangulateGum(gumPoints);
      //this.applyLaplacianSmoothing(gumGeometry);
      return { geometry: gumGeometry, originalVertices: gumPoints };
    },

    applyLaplacianSmoothing(geometry, lambda = 0.1, iterations = 10) {
      const vertices = geometry.attributes.position.array;
      const neighborIndices = this.computeNeighborIndices(geometry);

      for (let iter = 0; iter < iterations; iter++) {
        const smoothedVertices = new Float32Array(vertices.length);

        for (let i = 0; i < vertices.length / 3; i++) {
          const neighbors = neighborIndices[i];
          let sumX = 0,
            sumY = 0,
            sumZ = 0;

          neighbors.forEach((neighbor) => {
            sumX += vertices[neighbor * 3];
            sumY += vertices[neighbor * 3 + 1];
            sumZ += vertices[neighbor * 3 + 2];
          });

          smoothedVertices[i * 3] =
            vertices[i * 3] +
            lambda * (sumX / neighbors.length - vertices[i * 3]);
          smoothedVertices[i * 3 + 1] =
            vertices[i * 3 + 1] +
            lambda * (sumY / neighbors.length - vertices[i * 3 + 1]);
          smoothedVertices[i * 3 + 2] =
            vertices[i * 3 + 2] +
            lambda * (sumZ / neighbors.length - vertices[i * 3 + 2]);
        }

        // Atualiza os vértices originais com os vértices suavizados
        for (let i = 0; i < vertices.length; i++) {
          vertices[i] = smoothedVertices[i];
        }
      }

      geometry.attributes.position.needsUpdate = true;
      geometry.computeVertexNormals();
    },

    computeNeighborIndices(geometry) {
      const vertices = geometry.attributes.position.array;
      const neighborMap = new Map();

      for (let i = 0; i < vertices.length / 3; i++) {
        neighborMap.set(i, new Set());
      }

      // Percorre os triângulos para construir o mapa de vizinhos
      for (let i = 0; i < vertices.length; i += 9) {
        const v1 = i / 3;
        const v2 = v1 + 1;
        const v3 = v1 + 2;

        neighborMap.get(v1).add(v2).add(v3);
        neighborMap.get(v2).add(v1).add(v3);
        neighborMap.get(v3).add(v1).add(v2);
      }

      const neighborIndices = [];
      neighborMap.forEach((neighbors, vertexIndex) => {
        neighborIndices[vertexIndex] = Array.from(neighbors);
      });

      return neighborIndices;
    },
    findMeshByModelId(modelId) {
    let foundMesh = null;

    // Traverse pela scene para encontrar o mesh com o modelId especificado
    this.scene.traverse((object) => {
        // Verifica se o objeto é um Mesh e se o modelId corresponde ao valor fornecido
        if (object.isMesh && object.userData.modelId == modelId) {
            foundMesh = object; // Armazena o mesh encontrado
        }
    });
    return foundMesh;
},
    createDentalMeshGroup(dentalMap, modelId = 0) {
      const activeObject = (modelId === 0) ? this.activeObject : this.findMeshByModelId(modelId);

      // Criar Mesh Group
      const dentalMeshGroup = new THREE.Group();

      // Adicionar Dentes ao Grupo
      Object.keys(dentalMap).forEach((toothNumber) => {
        const toothData = dentalMap[toothNumber];
        //const toothGeometry = this.extractGeometry(activeObject, toothData.segmentedTriangleIndices);
        console.group(`Start segmentation`)
          console.log(`%cTooth ${toothNumber}`,"font-size:32px;color:green;")
        const toothGeometry = this.newExtractAndCloseGeometry(
          toothData,
          toothNumber,
          activeObject
        );
        if (toothGeometry) {
          toothGeometry.computeBoundingBox();
          const toothMesh = new THREE.Mesh(
            toothGeometry,
            new THREE.MeshPhongMaterial({
              color: 0xfffffe,
              specular: 0xffffff,
              emissive: 0x000000,
              shininess: 0,
              side: THREE.DoubleSide,
              reflectivity: 0,
            })
          );

          toothMesh.userData = {
            objectType: "tooth",
            toothNumber: toothNumber,
            category: "tooth"
          };

          // Reposiciona cada dente para ter o centro de massa na origem
          this.centerGeometry(toothGeometry, toothMesh);

          dentalMeshGroup.add(toothMesh);
          console.groupEnd();
        }
      });

      // Criar a Geometria da Gengiva
      console.group(`Start Gum Segmentation`)
      const gumObject = this.createGumGeometry(activeObject, dentalMap);
      const gumGeometry = gumObject.geometry;
      console.log('End Gum Segmentation');
      console.groupEnd();

      const gumMesh = new THREE.Mesh(
        gumGeometry,
        new THREE.MeshPhongMaterial({
          color: 0xffd0db, // 0xffc0cb
          specular: 0x333366,
          emissive: 0x000000,
          shininess: 0,
          side: THREE.DoubleSide,
          transparent: true,
          opacity: 1,
          reflectivity: 0,
        })
      );
      gumMesh.userData = {
        objectType: "gums",
        category: "gums",
        originalVertices: gumObject.originalVertices,
      };
      dentalMeshGroup.add(gumMesh);

      dentalMeshGroup.userData = activeObject.userData;
      activeObject.visible = false;

      this.setMeshCategory(dentalMeshGroup,'segmented')
      this.scene.add(dentalMeshGroup);
      this.$store.dispatch("editor3D/updateScene", this.scene);
      this.sceneUpdate = new Date();
      this.$store.dispatch("editor3D/updateDate", this.sceneUpdate);
    },

    findLargestDistanceWithFixedHeight(points, arcType) {
      if (!["upper", "lower"].includes(arcType)) {
        console.error('O tipo de arcada deve ser "upper" ou "lower".');
        return;
      }

      let referenceHeight;
      if (arcType === "upper") {
        referenceHeight = Math.min(...points.map((p) => p.y));
      } else {
        referenceHeight = Math.max(...points.map((p) => p.y));
      }

      // A função de distância considera apenas as coordenadas x e z
      function distance(point1, point2) {
        return Math.sqrt(
          (point1.x - point2.x) ** 2 + (point1.z - point2.z) ** 2
        );
      }

      let largestDistance = 0;
      let point1Result = null;
      let point2Result = null;
      for (let i = 0; i < points.length; i++) {
        for (let j = i + 1; j < points.length; j++) {
          const currentDistance = distance(points[i], points[j]);
          if (currentDistance > largestDistance) {
            largestDistance = currentDistance;
            // Clona os pontos e ajusta suas alturas para referenceHeight antes de salvar
            point1Result = { ...points[i], y: referenceHeight };
            point2Result = { ...points[j], y: referenceHeight };
          }
        }
      }

      return { largestDistance, point1: point1Result, point2: point2Result };
    },

    extractGumPoints(activeObject, dentalMap) {
      const positions = activeObject.geometry.attributes.position.array;
      const gumVertices = new Float32Array(positions.length);
      let gumIndex = 0;

      // Preencher o array de vértices da gengiva, excluindo os vértices dos dentes
      for (let i = 0, l = positions.length / 9; i < l; i++) {
        if (!this.isToothTriangle(i, dentalMap)) {
          for (let j = 0; j < 9; j++) {
            gumVertices[gumIndex++] = positions[i * 9 + j];
          }
        }
      }

      const gumPoints = [];

      for (
        let i = 0;
        i < activeObject.geometry.attributes.position.count;
        i++
      ) {
        const vertex = new THREE.Vector3();
        vertex.fromBufferAttribute(
          activeObject.geometry.attributes.position,
          i
        );
        gumPoints.push([vertex.x, vertex.y, vertex.z]);
      }

      return gumPoints;
    },

    isToothVertex(vertexIndex, dentalMap) {
      for (const toothNumber of Object.keys(dentalMap)) {
        if (
          dentalMap[toothNumber].segmentedVertexIndices.includes(vertexIndex)
        ) {
          return true;
        }
        if (dentalMap[toothNumber].borderVertexIndices.includes(vertexIndex)) {
          return true;
        }
      }
      return false;
    },
    triangulateGum(gumPoints) {
      const delaunay = Delaunator.from(gumPoints);
      const triangles = delaunay.triangles;

      console.table({ gumPoints, delaunay, triangles });

      const geometry = new THREE.BufferGeometry();
      const vertices = new Float32Array(triangles.length * 3);

      for (let i = 0; i < triangles.length; i++) {
        const point = gumPoints[triangles[i]];
        vertices[i * 3] = point[0];
        vertices[i * 3 + 1] = point[1];
        vertices[i * 3 + 2] = point[2];
      }

      geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
      geometry.computeVertexNormals();

      return geometry;
    },
  },
};

// eslint-disable-next-line no-unused-vars
class MeshSegmenter {
  constructor(mesh, borderTriangleIndices, isUpperArc, searchRadius) {
    this.startTime = performance.now();
    this.mesh = mesh;
    this.borderTriangleIndices = borderTriangleIndices;
    this.isUpperArc = isUpperArc;
    this.searchRadius = searchRadius;
    this.segmentedTriangles = new Set();
    this.visited = new Set();
    this.firstTriangle = null;

    this.vertexMap = new Map();
    this.adjacencyMap = new Map();

    this.centroid = this.calculateCentroidFromBorderTriangles();
    console.log(
      `Centroid calculation time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );
    this.candidateTriangles = this.filterTrianglesWithinRadius();
    console.log(
      `Filtering triangles time: ${(performance.now() - this.startTime).toFixed(
        2
      )} ms`
    );

    this.initializeVertexMap();
    console.log(
      `Initialize vertex map time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );

    this.buildAdjacencyMap();
    console.log(
      `Building adjacency map time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );
  }

  calculateTriangleCenter(triangleIndex) {
    const positionAttribute = this.mesh.geometry.attributes.position;
    const v1 = new THREE.Vector3().fromBufferAttribute(
      positionAttribute,
      triangleIndex * 3 + 0
    );
    const v2 = new THREE.Vector3().fromBufferAttribute(
      positionAttribute,
      triangleIndex * 3 + 1
    );
    const v3 = new THREE.Vector3().fromBufferAttribute(
      positionAttribute,
      triangleIndex * 3 + 2
    );
    return new THREE.Vector3().addVectors(v1, v2).add(v3).divideScalar(3);
  }

  calculateCentroidFromBorderTriangles() {
    const centroid = new THREE.Vector3();
    let count = 0;

    this.borderTriangleIndices.forEach((index) => {
      const center = this.calculateTriangleCenter(index);
      centroid.add(center);
      count++;
    });

    if (count > 0) centroid.divideScalar(count);
    return centroid;
  }

  filterTrianglesWithinRadius() {
    const candidateTriangles = [];
    const positions = this.mesh.geometry.attributes.position.array;
    const totalTriangles = positions.length / 9; // Cada triângulo tem 3 vértices, cada vértice tem 3 coordenadas (x, y, z)

    for (let i = 0; i < totalTriangles; i++) {
      // Calcula o índice base no buffer para o triângulo atual
      const baseIndex = i * 9;
      // Extrai os vértices do triângulo
      const vertices = [
        new THREE.Vector3(
          positions[baseIndex],
          positions[baseIndex + 1],
          positions[baseIndex + 2]
        ),
        new THREE.Vector3(
          positions[baseIndex + 3],
          positions[baseIndex + 4],
          positions[baseIndex + 5]
        ),
        new THREE.Vector3(
          positions[baseIndex + 6],
          positions[baseIndex + 7],
          positions[baseIndex + 8]
        ),
      ];
      // Calcula o centro usando os vértices
      const center = new THREE.Vector3()
        .add(vertices[0])
        .add(vertices[1])
        .add(vertices[2])
        .divideScalar(3);

      if (center.distanceTo(this.centroid) <= this.searchRadius) {
        candidateTriangles.push({ index: i, center, vertices });
      }
    }

    return candidateTriangles;
  }

  initializeVertexMap() {
    const positions = this.mesh.geometry.attributes.position.array;
    const totalTriangles = positions.length / 9; // Cada triângulo tem 3 vértices

    for (let i = 0; i < totalTriangles; i++) {
      const baseIndex = i * 9;
      for (let j = 0; j < 3; j++) {
        // Loop para cada vértice do triângulo
        const x = positions[baseIndex + j * 3];
        const y = positions[baseIndex + j * 3 + 1];
        const z = positions[baseIndex + j * 3 + 2];
        const key = `${x.toFixed(15)}-${y.toFixed(15)}-${z.toFixed(15)}`; // Reduzindo a precisão para evitar erros de ponto flutuante

        if (!this.vertexMap.has(key)) {
          this.vertexMap.set(key, []);
        }
        this.vertexMap.get(key).push(i);
      }
    }
  }

  buildAdjacencyMap() {
    for (let triangleIndex of this.vertexMap.values()) {
      for (let i = 0; i < triangleIndex.length; i++) {
        for (let j = i + 1; j < triangleIndex.length; j++) {
          const index1 = triangleIndex[i];
          const index2 = triangleIndex[j];

          if (!this.adjacencyMap.has(index1)) {
            this.adjacencyMap.set(index1, new Set());
          }
          this.adjacencyMap.get(index1).add(index2);

          if (!this.adjacencyMap.has(index2)) {
            this.adjacencyMap.set(index2, new Set());
          }
          this.adjacencyMap.get(index2).add(index1);
        }
      }
    }
  }

  buildAdjacencyMap_() {
    const adjacencyMap = new Map();

    // Para cada triângulo candidato, encontre seus vizinhos (isso requer uma lógica específica baseada na sua malha)
    this.candidateTriangles.forEach((candidate) => {
      const neighbors = this.findNeighbors(candidate.index);
      adjacencyMap.set(candidate.index, neighbors);
    });

    return adjacencyMap;
  }

  findNeighbors(triangleIndex) {
    const neighbors = [];
    // Encontra o triângulo candidato correspondente ao índice fornecido
    const candidate = this.candidateTriangles.find(
      (c) => c.index === triangleIndex
    );
    if (!candidate) {
      return neighbors; // Retorna vazio se o triângulo não for encontrado nos candidatos
    }

    // Itera sobre os triângulos candidatos para encontrar vizinhos
    this.candidateTriangles.forEach((otherCandidate) => {
      // Ignora o próprio triângulo e triângulos de borda
      if (
        otherCandidate.index === triangleIndex ||
        this.borderTriangleIndices.includes(otherCandidate.index)
      ) {
        return;
      }

      // Verifica se os triângulos compartilham um vértice
      const sharedVertex = candidate.vertices.some((vertex) =>
        otherCandidate.vertices.some(
          (otherVertex) =>
            vertex.x === otherVertex.x &&
            vertex.y === otherVertex.y &&
            vertex.z === otherVertex.z
        )
      );

      // Se compartilham um vértice, considera-se vizinhos
      if (sharedVertex) {
        neighbors.push(otherCandidate.index);
      }
    });

    return neighbors;
  }

  findTriangleIndexByPosition(x, y, z) {
    const point = new THREE.Vector3(x, y, z);
    let closestTriangleIndex = -1;
    let closestDistance = Infinity;

    this.candidateTriangles.forEach((candidate) => {
      const distance = point.distanceTo(candidate.center);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestTriangleIndex = candidate.index;
      }
    });

    return closestTriangleIndex;
  }

  findEustaquio() {
    const direction = this.isUpperArc
      ? new THREE.Vector3(0, -10, 0)
      : new THREE.Vector3(0, 10, 0);
    const raycaster = new THREE.Raycaster(this.centroid, direction);
    const intersects = raycaster.intersectObject(this.mesh);

    if (intersects.length > 0) {
      const eustaquioPoint = intersects[0].point;
      return this.findTriangleIndexByPosition(
        eustaquioPoint.x,
        eustaquioPoint.y,
        eustaquioPoint.z
      );
    }
    return null;
  }

  segment() {
    let eustaquio = this.findEustaquio();
    this.firstTriangle = eustaquio;
    console.log(
      `Finding Eustaquio time: ${(performance.now() - this.startTime).toFixed(
        2
      )} ms`
    );

    if (eustaquio !== null) {
      this.expandFromEustaquio(eustaquio);
      console.log(
        `Segmentation time: ${(performance.now() - this.startTime).toFixed(
          2
        )} ms`
      );
    }
  }

  expandFromEustaquio(eustaquioIndex) {
    let queue = [eustaquioIndex];
    let candidatesLength = this.candidateTriangles.length;
    let segmentedTrianglesSize = this.segmentedTriangles.size;

    while (queue.length > 0) {
      const current = queue.shift();

      if (this.visited.has(current)) continue;
      this.visited.add(current);

      if (!this.borderTriangleIndices.includes(current)) {
        this.segmentedTriangles.add(current);

        segmentedTrianglesSize++;
        if (segmentedTrianglesSize / candidatesLength > 0.55) break;

        const neighbors = this.adjacencyMap.get(current) || [];
        neighbors.forEach((neighbor) => {
          if (
            !this.visited.has(neighbor) &&
            !this.borderTriangleIndices.includes(neighbor)
          ) {
            queue.push(neighbor);
          }
        });
      }
    }
  }
}
