import * as THREE from "three";
import { saveAs } from "file-saver";
import { FontLoader } from "three/addons/loaders/FontLoader.js";
import { TextGeometry } from "three/addons/geometries/TextGeometry.js";
// eslint-disable-next-line no-unused-vars
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";
import KMeans from "ml-kmeans";

import gsap from "gsap";

export default {
  methods: {
    alignCameraToAxis(axis) {
      const distance = 700; // Defina a distância adequada da câmera ao modelo
      const newDirection = new THREE.Vector3();

      // Define a nova posição baseado no eixo escolhido
      switch (axis) {
        case "X":
          newDirection.set(distance, 0, 0);
          break;
        case "-X":
          newDirection.set(-distance, 0, 0);
          break;
        case "Y":
          newDirection.set(0, distance, 0);
          break;
        case "-Y":
          newDirection.set(0, -distance, 0);
          break;
        case "Z":
          newDirection.set(0, 0, distance);
          break;
        case "-Z":
          newDirection.set(0, 0, -distance);
          break;
      }

      // Anima a câmera para a nova posição
      gsap.to(this.camera.position, {
        x: newDirection.x,
        y: newDirection.y,
        z: newDirection.z,
        duration: 2, // Tempo em segundos para a transição
        ease: "circ.out",
        onUpdate: () => {
          // Faz a câmera olhar para o centro do modelo durante a animação
          this.camera.lookAt(new THREE.Vector3(0, 0, 0));
          // Se estiver usando OrbitControls, atualize-os para a nova posição da câmera
          if (this.orbitControls) {
            this.orbitControls.update();
          }
        },
      });
    },
    createLabelBackground(midPoint, radius, collisionMesh) {
      // Configurações da geometria do círculo
      const circleGeometry = new THREE.CircleGeometry(radius, 32);
      const circleMaterial = new THREE.MeshBasicMaterial({
        color: 0xffa500,
        transparent: true,
        opacity: 0.75,
      });

      // Cria a malha do círculo
      const circleMesh = new THREE.Mesh(circleGeometry, circleMaterial);

      // Posiciona o círculo ligeiramente abaixo do texto para evitar z-fighting
      circleMesh.position.set(midPoint.x, midPoint.y - 0.1, midPoint.z + 0.4);
      circleMesh.lookAt(this.camera.position);

      // Adiciona o círculo à malha de colisão
      collisionMesh.add(circleMesh);
    },
    createDistanceLabel(
      distance,
      midPoint,
      direction,
      toothNumber,
      collisionMesh
    ) {
      const loader = new FontLoader();

      loader.load("/fonts/Roboto_Regular.json", (font) => {
        const textGeometry = new TextGeometry(distance.toString(), {
          font: font,
          size: 1,
          height: 0.01,
        });

        const textMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
        const textMesh = new THREE.Mesh(textGeometry, textMaterial);

        // Calcula o bounding box e centraliza o texto baseado nele
        textGeometry.computeBoundingBox();
        const textOffset = textGeometry.boundingBox
          .getCenter(new THREE.Vector3())
          .negate();

        // Usa a função para calcular a posição do texto, incluindo o deslocamento para centralização
        const textPosition = this.calculateTextPosition(midPoint, toothNumber);
        textMesh.position.set(
          textPosition.x + textOffset.x,
          textPosition.y + textOffset.y,
          textPosition.z + textOffset.z
        );

        this.createLabelBackground(textPosition, 2, collisionMesh, textOffset);

        textMesh.lookAt(this.camera.position);
        collisionMesh.add(textMesh);

        // Adiciona a linha do texto até o meio da medição
        const lineMaterial = new THREE.LineBasicMaterial({
          color: 0xffffff,
          transparent: true,
          opacity: 0.75,
        });
        const lineGeometry = new THREE.BufferGeometry().setFromPoints([
          textPosition,
          midPoint,
        ]);
        const line = new THREE.Line(lineGeometry, lineMaterial);
        collisionMesh.add(line);
      });
    },
    calculateTextPosition(midPoint, toothNumber) {
      const toothIndexToAngle = {
        28: 0,
        27: 11.25,
        26: 22.5,
        25: 33.75,
        24: 45,
        23: 56.25,
        22: 67.5,
        21: 78.75,
        11: 90,
        12: 101.25,
        13: 112.5,
        14: 123.75,
        15: 135,
        16: 146.25,
        17: 157.5,
        18: 168.75,
        48: 180,
        47: 191.25,
        46: 202.5,
        45: 213.75,
        44: 225,
        43: 236.25,
        42: 247.5,
        41: 258.75,
        31: 270,
        32: 281.25,
        33: 292.5,
        34: 303.75,
        35: 315,
        36: 326.25,
        37: 337.5,
        38: 348.75,
      };

      // Define o raio da órbita para o texto
      const orbitRadius = 10; // Ajuste conforme necessário para o seu modelo

      // Encontra o ângulo baseado no número do dente
      const angleInDegrees = toothIndexToAngle[parseInt(toothNumber)];
      const angleInRadians = THREE.MathUtils.degToRad(angleInDegrees);

      // Calcula a posição do texto usando trigonometria e o ângulo calculado
      const textPosition = new THREE.Vector3(
        midPoint.x + orbitRadius * Math.cos(angleInRadians),
        0, // Mantém Y em 0 para ficar no plano XZ
        midPoint.z + orbitRadius * Math.sin(angleInRadians)
      );

      return textPosition;
    },
    clipSubmesh(activeObject, tubeGeometries, radius) {
      const vertices = activeObject.geometry.attributes.position.array;
      const submeshVertices = [];
      const originalIndices = [];

      // Calcular o centróide da tubeLine
      const tubeCentroid = new THREE.Vector3();
      let count = 0;
      tubeGeometries.forEach((tube) => {
        const positions = tube.geometry.attributes.position.array;
        count += tube.geometry.attributes.position.count;
        for (let i = 0; i < positions.length; i += 3) {
          tubeCentroid.add(
            new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2])
          );
        }
      });
      tubeCentroid.divideScalar(count);

      // Iterar sobre cada conjunto de três vértices para formar um triângulo no activeObject
      for (let i = 0; i < vertices.length; i += 9) {
        // Calcular o centro do triângulo
        const center = new THREE.Vector3()
          .fromArray(vertices, i)
          .add(new THREE.Vector3().fromArray(vertices, i + 3))
          .add(new THREE.Vector3().fromArray(vertices, i + 6))
          .divideScalar(3);

        // Verificar se o centro do triângulo está dentro do raio definido em torno do centróide da tubeLine
        if (center.distanceTo(tubeCentroid) < radius) {
          // Adicionar vértices do triângulo ao submesh
          for (let j = 0; j < 9; j++) {
            submeshVertices.push(vertices[i + j]);
          }
          // Adicionar índices originais do triângulo
          originalIndices.push(i / 9);
        }
      }

      //console.clear();
      // const vertexNeighbors = this.buildVertexNeighbors(submeshVertices);
      // const vertexStates = this.initializeVertexStates(vertexNeighbors);
      // this.processVertices(vertexNeighbors, vertexStates);

      // const vertexNeighbors = this.mapTriangleNeighborsFromVertices(submeshVertices);
      // const vertexStates1 = this.initializeVertexStates(vertexNeighbors);
      // // eslint-disable-next-line no-unused-vars
      // const {changesCount, vertexStates} = this.processVertices(vertexNeighbors, vertexStates1);

      // const filteredNeighbors = this.filterProcessedTriangles(vertexNeighbors, vertexStates);
      // const newVertexStates = this.initializeVertexStates(filteredNeighbors)

      // console.table(this.processVertices(filteredNeighbors, newVertexStates));

      return {
        submeshVertices: submeshVertices,
        originalIndices: originalIndices,
      };
    },
    mapTriangleNeighborsFromVertices(submeshVertices) {
      const triangleNeighbors = new Map();
      const edgeToTriangle = new Map(); // Mapeia arestas para os índices dos triângulos

      // Itera sobre cada conjunto de 9 elementos (três vértices de um triângulo)
      for (let i = 0; i < submeshVertices.length; i += 9) {
        const triangleIndex = i / 9;
        triangleNeighbors.set(triangleIndex, new Set());

        // Constrói cada triângulo a partir dos vértices
        const vertices = [
          [
            submeshVertices[i],
            submeshVertices[i + 1],
            submeshVertices[i + 2],
          ].join(","),
          [
            submeshVertices[i + 3],
            submeshVertices[i + 4],
            submeshVertices[i + 5],
          ].join(","),
          [
            submeshVertices[i + 6],
            submeshVertices[i + 7],
            submeshVertices[i + 8],
          ].join(","),
        ];

        // Define as arestas do triângulo
        const edges = [
          [vertices[0], vertices[1]].sort().join("--"),
          [vertices[1], vertices[2]].sort().join("--"),
          [vertices[2], vertices[0]].sort().join("--"),
        ];

        // Associa cada aresta ao triângulo atual
        edges.forEach((edge) => {
          if (edgeToTriangle.has(edge)) {
            const neighborIndex = edgeToTriangle.get(edge);
            triangleNeighbors.get(triangleIndex).add(neighborIndex);
            triangleNeighbors.get(neighborIndex).add(triangleIndex); // Estabelece a relação bidirecional
          } else {
            edgeToTriangle.set(edge, triangleIndex);
          }
        });
      }

      return triangleNeighbors;
    },
    filterProcessedTriangles(vertexNeighbors, vertexStates) {
      const filteredNeighbors = new Map();

      // Itera sobre os estados dos vértices para encontrar aqueles com estado 2
      vertexStates.forEach((state, index) => {
        if (state === 2) {
          const vertexKey = Array.from(vertexNeighbors.keys())[index];
          const neighbors = vertexNeighbors.get(vertexKey);
          // Filtra apenas os vizinhos que também estão no estado 2
          const filteredNeighborSet = new Set();
          neighbors.forEach((neighborKey) => {
            const neighborIndex = Array.from(vertexNeighbors.keys()).indexOf(
              neighborKey
            );
            if (vertexStates[neighborIndex] === 2) {
              filteredNeighborSet.add(neighborKey);
            }
          });
          if (filteredNeighborSet.size > 0) {
            filteredNeighbors.set(vertexKey, filteredNeighborSet);
          }
        }
      });

      return filteredNeighbors;
    },

    processVertices(vertexNeighbors, vertexStates) {
      let currentVertexIndex = 0;
      vertexStates[currentVertexIndex] = 1;
      let changes = true;
      let changesCount = 0;

      while (changes) {
        changes = false; // Reinicia o indicador de mudanças para cada ciclo completo sobre o array

        if (vertexStates[currentVertexIndex] === 1) {
          const vertexKey = Array.from(vertexNeighbors.keys())[
            currentVertexIndex
          ];
          const neighbors = vertexNeighbors.get(vertexKey);

          let hasNeighborWithZero = false;
          let countTwoNeighbors = 0;

          for (let neighborKey of neighbors) {
            const neighborIndex = Array.from(vertexNeighbors.keys()).indexOf(
              neighborKey
            );

            if (vertexStates[neighborIndex] === 0) {
              hasNeighborWithZero = true;
              vertexStates[currentVertexIndex] = 2;
              vertexStates[neighborIndex] = 1; // Atribuir o valor 1 ao vizinho
              currentVertexIndex = neighborIndex; // Muda o ponteiro para o vizinho com 0
              changes = true;
              break; // Sai do loop de vizinhos
            }

            if (vertexStates[neighborIndex] === 2) {
              countTwoNeighbors++;
            }
          }

          if (!hasNeighborWithZero && countTwoNeighbors > 1) {
            vertexStates[currentVertexIndex] = 2;
          }
        }

        // Implementar: procurar por outro VertexState = 0
        if (!changes) {
          for (let i = 0; i < vertexStates.length; i++) {
            if (vertexStates[i] === 0) {
              currentVertexIndex = i;
              vertexStates[i] = 1;
              changes = true;
              changesCount++;
              break;
            }
          }
        }
      }

      return { changesCount, vertexStates };
    },

    analyzeVertexStates(vertexStates) {
      if (vertexStates.every((state) => state === 2)) {
        console.log("Sistema fechado. Borda está completa.");
      } else if (vertexStates.some((state) => state === 1)) {
        // Coletar índices de vértices que têm estado 1
        const verticesWithStateOne = vertexStates
          .map((state, index) => (state === 1 ? index : -1))
          .filter((index) => index !== -1);

        console.log(
          "Existem vértices não conectados. Necessita de correção manual ou nova rodada."
        );
        console.log("Índices dos vértices com estado 1:", verticesWithStateOne);
      } else {
        console.log(
          "Todos os vértices estão adequadamente conectados, mas não formam um sistema fechado."
        );
      }
    },
    filterVertices(submeshVertices, vertexNeighbors, vertexStates) {
      const newSubmeshVertices = [];
      const newVertexStates = [];
      const indexMap = new Map();
      let newIndex = 0;

      // Converter as chaves do mapa para uma lista para fácil acesso por índice
      const keys = Array.from(vertexNeighbors.keys());

      keys.forEach((key, index) => {
        if (vertexStates[index] !== 1) {
          const vertexStart = index * 3; // Calcula o início do vértice no array submeshVertices
          newSubmeshVertices.push(
            submeshVertices[vertexStart],
            submeshVertices[vertexStart + 1],
            submeshVertices[vertexStart + 2]
          );
          indexMap.set(index, newIndex / 3);
          newVertexStates.push(0);
          newIndex += 3;
        }
      });

      const newVertexNeighbors = new Map();
      keys.forEach((key, index) => {
        if (indexMap.has(index)) {
          const newNeighbors = new Set();
          vertexNeighbors.get(key).forEach((neighborKey) => {
            const neighborIndex = keys.indexOf(neighborKey);
            if (indexMap.has(neighborIndex)) {
              newNeighbors.add(indexMap.get(neighborIndex));
            }
          });
          if (newNeighbors.size > 0) {
            newVertexNeighbors.set(indexMap.get(index), newNeighbors);
          }
        }
      });

      return { newSubmeshVertices, newVertexNeighbors, newVertexStates };
    },

    buildVertexNeighbors(submeshVertices) {
      // Mapa para armazenar a relação vértice-vizinhos usando um Map
      // A chave será uma string das coordenadas do vértice, e o valor será um Set de índices dos vizinhos
      const vertexNeighbors = new Map();

      // Função auxiliar para adicionar vizinhos
      const addNeighbor = (key, neighborIndex) => {
        if (vertexNeighbors.has(key)) {
          vertexNeighbors.get(key).add(neighborIndex);
        } else {
          vertexNeighbors.set(key, new Set([neighborIndex]));
        }
      };

      // Iterar sobre cada triângulo (cada grupo de 9 valores representa um triângulo)
      for (let i = 0; i < submeshVertices.length; i += 9) {
        const vertexIndices = [i, i + 3, i + 6]; // Índices dos vértices A, B, C

        // Para cada vértice no triângulo, adicionar os outros dois como vizinhos
        vertexIndices.forEach((index, _, arr) => {
          const vertexKey = `${submeshVertices[index]},${
            submeshVertices[index + 1]
          },${submeshVertices[index + 2]}`;
          arr.forEach((otherIndex) => {
            if (otherIndex !== index) {
              const neighborKey = `${submeshVertices[otherIndex]},${
                submeshVertices[otherIndex + 1]
              },${submeshVertices[otherIndex + 2]}`;
              addNeighbor(vertexKey, neighborKey);
            }
          });
        });
      }

      return vertexNeighbors;
    },
    initializeVertexStates(vertexNeighbors) {
      // Criar um array para armazenar os estados, com o mesmo tamanho do número de chaves em vertexNeighbors
      const vertexStates = new Array(vertexNeighbors.size).fill(0);

      return vertexStates;
    },

    selectCloseTriangles(activeObject, tubeGeometries, threshold) {
      const selectedTriangles = [];

      const { submeshVertices, originalIndices } = this.clipSubmesh(
        activeObject,
        tubeGeometries,
        10
      );

      // Estrutura de dados para armazenar as posições dos vértices da tubeline para facilitar a busca
      const tubeVertices = [];
      tubeGeometries.forEach((tube) => {
        const positions = tube.geometry.attributes.position.array;
        for (let i = 0; i < positions.length; i += 3) {
          tubeVertices.push(
            new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2])
          );
        }
      });

      // Iterar sobre cada vértice do submesh
      for (let i = 0; i < submeshVertices.length; i += 9) {
        // Definir vértices do triângulo
        const a = new THREE.Vector3().fromArray(submeshVertices, i);
        const b = new THREE.Vector3().fromArray(submeshVertices, i + 3);
        const c = new THREE.Vector3().fromArray(submeshVertices, i + 6);

        // Calcular o centro do triângulo
        const center = new THREE.Vector3()
          .addVectors(a, b)
          .add(c)
          .divideScalar(3);

        // Verificar se algum vértice da tubeline está dentro do threshold de distância
        for (let vertex of tubeVertices) {
          const distance = vertex.distanceTo(center);
          if (distance < threshold) {
            // Adicionar o índice do triângulo usando os índices originais
            selectedTriangles.push(originalIndices[Math.floor(i / 9)]);
            break; // Não precisa verificar os outros vértices da tubeline
          }
        }
      }

      return selectedTriangles;
    },
    createMeshFromCloseTriangles(
      activeObject,
      closeTriangles,
      color = 0x0000ff
    ) {
      const positions = activeObject.geometry.attributes.position.array;
      const selectedVertices = [];

      // Extrair vértices selecionados
      closeTriangles.forEach((triangleIdx) => {
        const baseIdx = triangleIdx * 9; // 3 vértices * 3 coordenadas
        for (let i = 0; i < 9; i++) {
          selectedVertices.push(positions[baseIdx + i]);
        }
      });

      // Criar a geometria e o mesh para os triângulos próximos
      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(selectedVertices, 3)
      );
      geometry.computeVertexNormals();

      const material = new THREE.MeshBasicMaterial({
        color: color,
        side: THREE.DoubleSide,
        wireframe: true,
      });
      const newMesh = new THREE.Mesh(geometry, material);

      // Procurar por um grupo existente com userData.isMeshBorder igual a true
      let meshGroup = this.scene.children.find(
        (child) => child.isGroup && child.userData.isMeshBorder === true
      );

      // Se não encontrou, cria um novo grupo e define a propriedade userData.isStyleBorder
      if (!meshGroup) {
        meshGroup = new THREE.Group();
        meshGroup.userData.isMeshBorder = true;
        meshGroup.userData.name = "borderData";
        this.scene.add(meshGroup);
        this.$store.dispatch("editor3D/updateScene", this.scene);
        this.sceneUpdate = new Date();
        this.$store.dispatch("editor3D/updateDate", this.sceneUpdate);
      }

      // Adiciona o novo mesh e o box helper ao grupo
      meshGroup.add(newMesh);
    },
    createMeshFromCloseTrianglesExtended(
      activeObject,
      closeTriangles,
      color = 0x0000ff,
      realistic = false
    ) {
      const positions = activeObject.geometry.attributes.position.array;
      const selectedVertices = [];

      // Extrair vértices selecionados
      closeTriangles.forEach((triangleIdx) => {
        const baseIdx = triangleIdx * 9; // 3 vértices * 3 coordenadas
        for (let i = 0; i < 9; i++) {
          selectedVertices.push(positions[baseIdx + i]);
        }
      });

      // Criar a geometria e o mesh para os triângulos próximos
      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(selectedVertices, 3)
      );
      geometry.computeVertexNormals();
      this.calculateVertexNormals(geometry);

      const edgeGeometry = new THREE.EdgesGeometry(geometry, 90);
      const line = new THREE.LineSegments(
        edgeGeometry,
        new THREE.LineBasicMaterial({ color: 0x000000 })
      );
      this.scene.add(line);

      const material = realistic
        ? color === 0xff0000
          ? new THREE.MeshPhongMaterial({
              color: 0xffc0cb,
              specular: 0x333366,
              shininess: 100,
              side: THREE.DoubleSide,
              transparent: true,
              opacity: 1,
            })
          : new THREE.MeshPhongMaterial({
              color: 0xf7f7ff,
              specular: 0xffffff,
              shininess: 100,
              side: THREE.DoubleSide,
            })
        : new THREE.MeshBasicMaterial({
            color: color,
            side: THREE.DoubleSide,
            wireframe: true,
          });
      const newMesh = new THREE.Mesh(geometry, material);

      newMesh.geometry.computeBoundingBox(); // Assegura que a caixa delimitadora está atualizada
      const bbox = newMesh.geometry.boundingBox;
      const width = bbox.max.x - bbox.min.x;
      const height = bbox.max.y - bbox.min.y;
      const depth = bbox.max.z - bbox.min.z;
      const volume = width * height * depth;

      // Criar o bounding box helper e adicioná-lo ao mesh
      const boxHelper = new THREE.BoxHelper(newMesh, 0xff0000); // Vermelho para destaque

      // Procurar por um grupo existente com userData.isMeshBorder igual a true
      let meshGroup = this.scene.children.find(
        (child) => child.isGroup && child.userData.isMeshBorder === true
      );

      // Se não encontrou, cria um novo grupo e define a propriedade userData.isStyleBorder
      if (!meshGroup) {
        meshGroup = new THREE.Group();
        meshGroup.userData.isMeshBorder = true;
        meshGroup.userData.name = "borderData";
        this.scene.add(meshGroup);
        this.$store.dispatch("editor3D/updateScene", this.scene);
        this.sceneUpdate = new Date();
        this.$store.dispatch("editor3D/updateDate", this.sceneUpdate);
      }

      meshGroup.add(newMesh);
      if (!realistic) meshGroup.add(boxHelper);
      return {
        meshId: newMesh.id,
        volume: volume,
        mesh: newMesh,
      };
    },
    handleToothClicked(toothNumber) {
      //if (this.currentMode !== "") return;
      const toothKey = String(toothNumber);
      this.analyzeAdjacentTeeth(toothKey);

      if (!this.dentalMap[toothKey]) {
        this.dentalMap[toothKey] = {
          borderPoints: [],
          borderTriangleIndices: [],
          segmentedTriangleIndices: [],
          medial: null,
          distal: null,
        };
        this.selectedTooth = toothKey;
      } else {
        const dentalData = this.dentalMap[toothKey];
        if (this.activeObject.type !== "Group") {
          this.selectedTooth = toothKey;
          this.updateInterfaceWithDentalData(dentalData);
        } else {
          this.selectedTooth =
            this.selectedTooth !== toothKey ? toothKey : null;
          this.adjustTeethOpacity();
        }
      }

      if (
        !this.currentAction ||
        this.currentAction == "definingMedialDistalPoints"
      ) {
        this.$store.dispatch("editor3D/enterMedialDistalEditMode", toothNumber);
        this.selectedTooth = toothKey;
      }
    },
    updateInterfaceWithDentalData(dentalData) {
      this.$store.dispatch(
        "editor3D/setContourPoints",
        dentalData.borderPoints
      );
      this.updateContour();
      if (dentalData.borderTriangleIndices)
        this.createMeshFromCloseTriangles(
          this.activeObject,
          dentalData.borderTriangleIndices
        );
      if (dentalData.segmentedTriangleIndices)
        this.createMeshFromCloseTriangles(
          this.activeObject,
          dentalData.segmentedTriangleIndices,
          0xffff00
        );

      // Agora, vamos lidar com os pontos medial e distal
      const toothId = this.selectedTooth;
      if (dentalData.medial || dentalData.distal) {
        const pointsMaterial = new THREE.PointsMaterial({
          size: 15,
          color: 0x00ff00,
        });
        const pointsGeometry = new THREE.BufferGeometry();
        const pointsArray = [];

        if (dentalData.medial) {
          pointsArray.push(
            dentalData.medial.x,
            dentalData.medial.y,
            dentalData.medial.z
          );
        }

        if (dentalData.distal) {
          pointsArray.push(
            dentalData.distal.x,
            dentalData.distal.y,
            dentalData.distal.z
          );
        }

        pointsGeometry.setAttribute(
          "position",
          new THREE.Float32BufferAttribute(pointsArray, 3)
        );
        const points = new THREE.Points(pointsGeometry, pointsMaterial);

        points.userData = {
          toothId: toothId,
          type: "medialDistalPoint",
        };

        // Adicione os pontos à cena ou ao meshGroup
        this.getMeshGroup().add(points);

        // Se ambos os pontos existirem, desenhe uma linha entre eles
        if (dentalData.medial && dentalData.distal) {
          // Material da linha existente entre medial e distal
          const lineMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
          const lineGeometry = new THREE.BufferGeometry().setFromPoints([
            new THREE.Vector3(
              dentalData.medial.x,
              dentalData.medial.y,
              dentalData.medial.z
            ),
            new THREE.Vector3(
              dentalData.distal.x,
              dentalData.distal.y,
              dentalData.distal.z
            ),
          ]);
          const line = new THREE.Line(lineGeometry, lineMaterial);
          line.userData = {
            toothId: toothId,
            type: "medialDistalLine",
          };

          // Adicione a linha à cena ou ao meshGroup
          this.getMeshGroup().add(line);

          // // Calcula o ponto médio entre medial e distal
          // const midpoint = new THREE.Vector3(
          //     (dentalData.medial.x + dentalData.distal.x) / 2,
          //     (dentalData.medial.y + dentalData.distal.y) / 2,
          //     (dentalData.medial.z + dentalData.distal.z) / 2
          // );

          // // Vetor direção para linha perpendicular ao plano XZ (paralelo ao eixo Y)
          // const direction = new THREE.Vector3(0, 1, 0);  // Vetor unitário no eixo Y

          // // Define o tamanho da linha perpendicular
          // const perpendicularLength = 10;  // Ajuste conforme necessário
          // const perpendicularEnd1 = new THREE.Vector3(
          //     midpoint.x,
          //     midpoint.y + perpendicularLength * direction.y,
          //     midpoint.z
          // );
          // const perpendicularEnd2 = new THREE.Vector3(
          //     midpoint.x,
          //     midpoint.y - perpendicularLength * direction.y,
          //     midpoint.z
          // );

          // // Criação da linha perpendicular
          // const perpLineMaterial = new THREE.LineBasicMaterial({ color: 0x0000ff });
          // const perpLineGeometry = new THREE.BufferGeometry().setFromPoints([
          //     perpendicularEnd1,
          //     perpendicularEnd2
          // ]);
          // const perpLine = new THREE.Line(perpLineGeometry, perpLineMaterial);

          // perpLine.userData = {
          //     toothId: toothId,
          //     type: 'medialDistalPivot',
          // };

          // // Adicionar a linha perpendicular à cena ou ao meshGroup
          // this.getMeshGroup().add(perpLine);
        }
      }

      // Se nenhum dos pontos existir, remova os existentes
      if (!dentalData.medial && !dentalData.distal) {
        this.removeMedialDistalVisuals(toothId);
      }
    },
    removeMedialDistalVisuals(toothId) {
      const meshGroup = this.getMeshGroup();

      // Filtra todos os elementos que têm o userData com o toothId e type correspondente
      const elementsToRemove = meshGroup.children.filter(
        (child) =>
          child.userData.toothId === toothId &&
          (child.userData.type === "medialDistalPoint" ||
            child.userData.type === "medialDistalLine" ||
            child.userData.type === "medialDistalPivot")
      );

      // Remove os elementos do grupo
      elementsToRemove.forEach((element) => {
        meshGroup.remove(element);
        // Além disso, se for um Geometry ou BufferGeometry, é uma boa prática fazer o seguinte:
        if (element.geometry) {
          element.geometry.dispose();
        }
        // Se for um Material, faça o seguinte:
        if (element.material) {
          element.material.dispose();
        }
      });
    },
    getMeshGroup() {
      let meshGroup = this.scene.children.find(
        (child) => child.isGroup && child.userData.isMeshBorder === true
      );

      // Se não encontrou, cria um novo grupo e define a propriedade userData.isMeshBorder
      if (!meshGroup) {
        meshGroup = new THREE.Group();
        meshGroup.userData.isMeshBorder = true;
        meshGroup.userData.name = "borderData";
        this.scene.add(meshGroup);
        this.$store.dispatch("editor3D/updateScene", this.scene);
        this.sceneUpdate = new Date();
        this.$store.dispatch("editor3D/updateDate", this.sceneUpdate);
      }

      return meshGroup;
    },
    findContourMesh() {
      return this.scene.children.find((child) => child.userData.isContour);
    },
    findTubeGeometries(mesh) {
      return mesh.children.filter(
        (child) => child.geometry instanceof THREE.TubeGeometry
      );
    },
    updateMode(newMode) {
      this.currentMode = newMode; // Atualizando currentMode com o novo modo emitido pelo filho
    },
    handleColorChange(color) {
      this.backgroundColor = color;
      this.renderer.setClearColor(this.backgroundColor);
    },
    saveDentalMap() {
      // Converte o dentalMap em uma string JSON
      const jsonString = JSON.stringify(this.dentalMap, null, 2); // Indentação para melhor leitura no arquivo
      const blob = new Blob([jsonString], { type: "application/json" });
      saveAs(blob, `dentalMap-${Date.now()}.json`);
    },

    smoothVertices(mesh, vertexMap, adjacencyMap, alpha = 0.2, iterations = 1) {
      const positions = mesh.geometry.attributes.position.array;
      const newPositions = new Float32Array(positions.length);

      for (let iter = 0; iter < iterations; iter++) {
        newPositions.set(positions);

        for (let [, triangleIndices] of vertexMap) {
          let avgX = 0,
            avgY = 0,
            avgZ = 0,
            totalWeight = 0;

          triangleIndices.forEach((index) => {
            if (adjacencyMap.has(index)) {
              adjacencyMap.get(index).forEach((neighborIndex) => {
                for (let i = 0; i < 3; i++) {
                  const neighborVertexIndex = neighborIndex * 3 + i;
                  const dx =
                    positions[neighborVertexIndex * 3] - positions[index * 3];
                  const dy =
                    positions[neighborVertexIndex * 3 + 1] -
                    positions[index * 3 + 1];
                  const dz =
                    positions[neighborVertexIndex * 3 + 2] -
                    positions[index * 3 + 2];
                  const dist = Math.sqrt(dx * dx + dy * dy + dz * dz);
                  const weight = 1.0 / (0.1 + dist);
                  avgX += positions[neighborVertexIndex * 3] * weight;
                  avgY += positions[neighborVertexIndex * 3 + 1] * weight;
                  avgZ += positions[neighborVertexIndex * 3 + 2] * weight;
                  totalWeight += weight;
                }
              });
            }
          });

          if (totalWeight > 0) {
            const newPosX = avgX / totalWeight;
            const newPosY = avgY / totalWeight;
            const newPosZ = avgZ / totalWeight;

            triangleIndices.forEach((triangleIndex) => {
              for (let i = 0; i < 3; i++) {
                const vertexIndex = triangleIndex * 3 + i;
                newPositions[vertexIndex * 3] =
                  positions[vertexIndex * 3] * (1 - alpha) + newPosX * alpha;
                newPositions[vertexIndex * 3 + 1] =
                  positions[vertexIndex * 3 + 1] * (1 - alpha) +
                  newPosY * alpha;
                newPositions[vertexIndex * 3 + 2] =
                  positions[vertexIndex * 3 + 2] * (1 - alpha) +
                  newPosZ * alpha;
              }
            });
          }
        }

        positions.set(newPositions);
      }

      mesh.geometry.attributes.position.needsUpdate = true;
      mesh.geometry.computeVertexNormals();
    },

    addSpheresWithGroup(
      borderPointsCandidates,
      sphereRadius = 0.25,
      color = 0x0000ff,
      name = ""
    ) {
      const sphereGeometry = new THREE.SphereGeometry(sphereRadius, 8, 8);
      const sphereMaterial = new THREE.MeshBasicMaterial({ color: color });

      // Cria um grupo para segurar todas as esferas
      const group = new THREE.Group();
      group.name = "Spheres";
      group.userData.name = name;

      // Itera sobre todos os pontos candidatos
      borderPointsCandidates.forEach((vertex) => {
        const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
        sphere.position.set(vertex.x, vertex.y, vertex.z);
        group.add(sphere); // Adiciona cada esfera ao grupo
      });

      // Adiciona o grupo à cena
      this.scene.add(group);
    },
    createInterestArea(points, distance) {
      // 1. Calcular o valor médio das alturas (y)
      let totalY = 0;
      points.forEach((point) => {
        totalY += point.y;
      });
      let averageY = totalY / points.length;

      // 2. Definir as dimensões do retângulo no plano x, z
      let minX = Infinity,
        maxX = -Infinity,
        minZ = Infinity,
        maxZ = -Infinity;
      points.forEach((point) => {
        if (point.x < minX) minX = point.x;
        if (point.x > maxX) maxX = point.x;
        if (point.z < minZ) minZ = point.z;
        if (point.z > maxZ) maxZ = point.z;
      });

      let width = maxX - minX;
      let depth = maxZ - minZ;
      let height = 1; // Altura ajustável

      // 3. Criar a geometria do retângulo
      let geometry = new THREE.BoxGeometry(width, height, depth);
      let material = new THREE.MeshBasicMaterial({
        color: 0x000000,
        wireframe: false,
        transparent: true,
        opacity: 0.5,
      });
      let box = new THREE.Mesh(geometry, material);

      // 4. Posicionar o retângulo na média das alturas
      box.position.set((minX + maxX) / 2, averageY, (minZ + maxZ) / 2);

      // 3. Criar um novo array de pontos que atendem aos critérios de distância
      let filteredPoints = points.filter((point) => {
        let dx = Math.abs(point.x - box.position.x);
        let dz = Math.abs(point.z - box.position.z);
        let distanceFromBox = Math.sqrt(dx * dx + dz * dz);

        let dy = Math.abs(point.y - averageY);

        return dy <= distance && distanceFromBox > 10;
      });

      return filteredPoints;
    },
    silhouetteMethod(points) {
      const maxK = 16; // Número máximo de clusters para testar
      let bestK = 2;
      let bestScore = -Infinity;

      const pointsKMeans = points.map((centroid) => [
        centroid.x,
        centroid.y,
        centroid.z,
      ]);

      for (let k = 8; k <= maxK; k++) {
        const clusters = KMeans(pointsKMeans, k);
        const score = this.calculateSilhouetteScore(clusters, pointsKMeans);

        if (score > bestScore) {
          bestScore = score;
          bestK = k;
        }
      }

      return bestK;
    },

    calculateSilhouetteScore(kmeansResult, data) {
      let clusters = this.groupPointsByClusters(data, kmeansResult.clusters);
      let totalScore = 0;
      let totalPoints = 0;

      clusters.forEach((cluster) => {
        cluster.forEach((point) => {
          let a = this.calculateAverageDistance(point, cluster);
          let b = this.calculateMinimumAverageDistance(
            point,
            clusters,
            cluster
          );
          let s = (b - a) / Math.max(a, b);
          totalScore += s;
          totalPoints++;
        });
      });

      return totalScore / totalPoints;
    },

    groupPointsByClusters(points, clusterIndices) {
      let clusters = {};
      clusterIndices.forEach((clusterIndex, pointIndex) => {
        if (!clusters[clusterIndex]) {
          clusters[clusterIndex] = [];
        }
        clusters[clusterIndex].push(points[pointIndex]);
      });
      return Object.values(clusters);
    },

    calculateAverageDistance(point, points) {
      let totalDistance = 0;
      points.forEach((p) => {
        totalDistance += this.euclideanDistance(point, p);
      });
      return totalDistance / points.length;
    },

    calculateMinimumAverageDistance(point, clusters, excludeCluster) {
      let minDistance = Infinity;

      clusters.forEach((cluster) => {
        if (cluster !== excludeCluster) {
          let averageDistance = this.calculateAverageDistance(point, cluster);
          if (averageDistance < minDistance) {
            minDistance = averageDistance;
          }
        }
      });

      return minDistance;
    },

    euclideanDistance(pointA, pointB) {
      return Math.sqrt(
        (pointA[0] - pointB[0]) ** 2 +
          (pointA[1] - pointB[1]) ** 2 +
          (pointA[2] - pointB[2]) ** 2
      );
    },

    createClosedContour(
      points,
      startPoint,
      maxDistance,
      initialAngleTolerance
    ) {
      let contour = [startPoint];
      let remainingPoints = points.filter((point) => !point.equals(startPoint));
      let currentPoint = startPoint;
      let previousPoint = null;
      let direction = null;
      let maxAngle = Math.PI * 2; // Ângulo inicial de 360 graus

      while (remainingPoints.length > 0) {
        let neighbors = this.findValidNeighbors(
          currentPoint,
          remainingPoints,
          previousPoint,
          direction,
          maxDistance,
          maxAngle
        );

        if (neighbors.length > 0) {
          previousPoint = currentPoint;
          currentPoint = neighbors[0]; // Escolhe o próximo ponto
          contour.push(currentPoint);
          remainingPoints = remainingPoints.filter(
            (point) => !point.equals(currentPoint)
          );

          // Atualiza a direção e o ângulo máximo permitido
          direction = new THREE.Vector3()
            .subVectors(currentPoint, previousPoint)
            .normalize();
          maxAngle = initialAngleTolerance; // Redefine o ângulo permitido para o seguimento
        } else {
          // Backtracking
          if (contour.length > 1) {
            currentPoint = contour.pop();
            previousPoint = contour[contour.length - 1];
          } else {
            // Não é possível formar um contorno fechado
            console.error("Não foi possível formar um contorno fechado.");
            return contour;
          }
        }
      }

      // Fechar o contorno
      contour.push(startPoint);
      this.visualizeContour(contour);

      return contour;
    },

    findValidNeighbors(
      currentPoint,
      points,
      previousPoint,
      direction,
      maxDistance,
      maxAngleTolerance
    ) {
      return points
        .filter((point) => {
          let distance = currentPoint.distanceTo(point);
          if (distance > maxDistance) return false;

          if (previousPoint) {
            let vectorToPoint = new THREE.Vector3()
              .subVectors(point, currentPoint)
              .normalize();
            let angle = Math.acos(direction.dot(vectorToPoint));

            if (angle > maxAngleTolerance) return false;
          }

          return true;
        })
        .sort(
          (a, b) => currentPoint.distanceTo(a) - currentPoint.distanceTo(b)
        );
    },

    visualizeContour(contour) {
      let material = new THREE.LineBasicMaterial({ color: 0xffff00 });
      let geometry = new THREE.BufferGeometry().setFromPoints(contour);
      let line = new THREE.Line(geometry, material);
      line.userData.name = "Contour Line";
      this.scene.add(line);
    },
    createGradientMap(mesh) {
      const normals = mesh.geometry.attributes.normal.array;
      const vertices = mesh.geometry.attributes.position.array;
      const vertexCount = vertices.length / 3;
      const normalVectors = [];

      // Armazena todas as normais em vetores
      for (let i = 0; i < normals.length; i += 3) {
        normalVectors.push(
          new THREE.Vector3(normals[i], normals[i + 1], normals[i + 2])
        );
      }

      // Função auxiliar para calcular a curvatura em cada vértice
      const calculateVertexCurvature = (adjacencyMap, normalVectors) => {
        const curvatures = new Array(vertexCount).fill(0);

        for (let i = 0; i < vertexCount; i++) {
          const currentNormal = normalVectors[i];
          const neighbors = adjacencyMap[i];

          let totalCurvature = 0;
          neighbors.forEach((neighborIndex) => {
            const neighborNormal = normalVectors[neighborIndex];

            // A curvatura é baseada no ângulo entre a normal do vértice atual e seus vizinhos
            const angleDifference = currentNormal.angleTo(neighborNormal);
            totalCurvature += angleDifference;
          });

          // A curvatura do vértice é a média das diferenças angulares com os vizinhos
          curvatures[i] = totalCurvature / neighbors.length;
        }

        return curvatures;
      };

      const getColorByCurvature = (curvature, minCurvature, maxCurvature, avgCurvature) => {
        const normalizedCurvature =
          (curvature - minCurvature) / (maxCurvature - minCurvature);
        const normalizedAvgCurvature =
          (avgCurvature - minCurvature) / (maxCurvature - minCurvature); 

        // Garantir que o valor de curvatura normalizado esteja entre 0 e 1
        const clampedCurvature = Math.max(0, Math.min(1, normalizedCurvature));

        // Ajustar o gradiente para cores mais vibrantes
        if (clampedCurvature < normalizedAvgCurvature) {
          // Interpolar de azul (baixo) para cinza (meio)
          return new THREE.Color(1, 1, 1).lerp(
            new THREE.Color(1 ,1, 1),
            clampedCurvature * 2
          );
        }
        // Interpolar de cinza (meio) para amarelo (alto)
        return new THREE.Color(1,0.5,0).lerp(
          new THREE.Color(1, 0, 0),
          (clampedCurvature - 0.5) * 2
        );
      };

      // Cria o mapa de adjacência
      const adjacencyMap = this.createAdjacencyMap(mesh.geometry);

      // Calcula a curvatura de cada vértice
      const curvatures = calculateVertexCurvature(adjacencyMap, normalVectors);

      // Encontrar a curvatura mínima, máxima e calcular a média de forma iterativa
let minCurvature = Infinity;
let maxCurvature = -Infinity;
let sumCurvature = 0;

for (let i = 0; i < curvatures.length; i++) {
  const curvature = curvatures[i];

  // Verifica se é a curvatura mínima
  if (curvature < minCurvature) {
    minCurvature = curvature;
  }

  // Verifica se é a curvatura máxima
  if (curvature > maxCurvature) {
    maxCurvature = curvature;
  }

  // Acumula a soma das curvaturas
  sumCurvature += curvature;
}

// Calcular a curvatura média
const avgCurvature = sumCurvature / curvatures.length;

      // Iniciar o array de cores
      const colors = new Float32Array(vertexCount * 3);

      // Aplicar as cores com base nas curvaturas calculadas
      for (let i = 0; i < vertexCount; i++) {
        const color = getColorByCurvature(
          curvatures[i],
          minCurvature,
          maxCurvature,
          avgCurvature
        );
        colors[i * 3] = color.r;
        colors[i * 3 + 1] = color.g;
        colors[i * 3 + 2] = color.b;
      }

      // Aplicar as cores no mesh
      mesh.geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));
      mesh.material.vertexColors = true; // Ativar as cores por vértice

      return mesh;
    },

    // Função auxiliar para criar um mapa de adjacência baseado nas faces do mesh
    createAdjacencyMap(geometry) {
      const index = geometry.index.array; // Índices dos triângulos
      const adjacencyMap = {};

      // Inicializar o mapa de adjacência
      for (let i = 0; i < geometry.attributes.position.count; i++) {
        adjacencyMap[i] = [];
      }

      // Preencher o mapa de adjacência com os vértices conectados
      for (let i = 0; i < index.length; i += 3) {
        const a = index[i];
        const b = index[i + 1];
        const c = index[i + 2];

        adjacencyMap[a].push(b, c);
        adjacencyMap[b].push(a, c);
        adjacencyMap[c].push(a, b);
      }

      // Remover duplicatas de vizinhos
      for (const key in adjacencyMap) {
        adjacencyMap[key] = [...new Set(adjacencyMap[key])];
      }

      return adjacencyMap;
    },
    toothSearch(lowCurvature, highCurvature) {
      this.iaActive = "yellow";
      const segmenter = new ToothSegmenter(
        lowCurvature.clone(),
        highCurvature.clone()
      );
      segmenter.segment();
      segmenter.findLostNeighbors();

      // eslint-disable-next-line no-unused-vars
      function mergeCloseClusters(segmenter, distanceThreshold) {
        let merged = new Map();
        let centers = [];

        // Coletar centros de massa
        segmenter.segmentedTriangleIndices.forEach((info, index) => {
          centers.push({
            index: index,
            center: info.centerOfMass,
            merged: false,
            angleY: info.angles.y,
          });
        });

        // Agrupar centros próximos
        centers.forEach((centerA, idxA) => {
          if (!centerA.merged) {
            let group = [centerA.index];
            centers.forEach((centerB, idxB) => {
              if (idxA !== idxB && !centerB.merged) {
                let distance = centerA.center.distanceTo(centerB.center);
                if (
                  distance < distanceThreshold &&
                  Math.abs(centerA.angleY - centerB.angleY) < 5
                ) {
                  group.push(centerB.index);
                  centerB.merged = true;
                }
              }
            });

            // Se houver agrupamento, mescle os grupos
            if (group.length > 1) {
              let newTriangles = [];
              group.forEach((idx) => {
                newTriangles = newTriangles.concat(
                  segmenter.segmentedTriangleIndices.get(idx).triangles
                );
              });
              const newCenterOfMass = group
                .reduce(
                  (acc, idx) =>
                    acc.add(
                      segmenter.segmentedTriangleIndices.get(idx).centerOfMass
                    ),
                  new THREE.Vector3()
                )
                .divideScalar(group.length);

              // Adiciona o grupo mesclado ao mapa 'merged'
              merged.set(group[0], {
                triangles: newTriangles,
                centerOfMass: newCenterOfMass,
              });

              // Remove os índices mesclados do segmenter, exceto o primeiro
              group.slice(1).forEach((idx) => {
                segmenter.segmentedTriangleIndices.delete(idx);
              });
            }
          }
        });

        // Atualizar segmenter com clusters mesclados
        merged.forEach((value, key) => {
          segmenter.segmentedTriangleIndices.set(key, value);
        });
      }

      // eslint-disable-next-line no-unused-vars
      function addCenterOfMassSphere(meshCenterOfMass, scene) {
        // Cria uma geometria de esfera com raio apropriado
        const sphereGeometry = new THREE.SphereGeometry(0.5, 32, 32); // Ajuste o raio conforme necessário

        // Cria um material azul para a esfera
        const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff }); // Cor azul

        // Cria a malha da esfera utilizando a geometria e o material
        const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);

        // Define a posição da esfera usando o centro de massa do mesh
        sphereMesh.position.set(
          meshCenterOfMass.x,
          meshCenterOfMass.y,
          meshCenterOfMass.z
        );

        // Adiciona a esfera ao cenário
        scene.add(sphereMesh);

        // Retorna a esfera para referências futuras se necessário
        return sphereMesh;
      }

      function analyzeDentalArch(teeth) {
        let maxX = -Infinity,
          minX = Infinity;
        let maxY = -Infinity,
          minY = Infinity;
        let maxZ = -Infinity,
          minZ = Infinity;

        teeth.forEach((tooth) => {
          maxX = Math.max(maxX, tooth.centerOfMass.x);
          minX = Math.min(minX, tooth.centerOfMass.x);
          maxY = Math.max(maxY, tooth.centerOfMass.y);
          minY = Math.min(minY, tooth.centerOfMass.y);
          maxZ = Math.max(maxZ, tooth.centerOfMass.z);
          minZ = Math.min(minZ, tooth.centerOfMass.z);
        });

        const width = maxX - minX;
        const depth = maxZ - minZ;
        const height = maxY - minY;

        // Determinar os eixos com base na amplitude
        const dimensions = [
          { axis: "X", size: width, label: "Largura" },
          { axis: "Y", size: height, label: "Altura" },
          { axis: "Z", size: depth, label: "Profundidade" },
        ];

        // Ordenar as dimensões por tamanho para classificar os eixos
        dimensions.sort((a, b) => b.size - a.size);

        const eixos = {
          Largura: dimensions[0].axis,
          Profundidade: dimensions[1].axis,
          Altura: dimensions[2].axis,
        };

        return eixos;
      }

      function findArchCenter(teeth, eixos) {
        let minX = Infinity,
          maxX = -Infinity;
        let minDepth = Infinity,
          maxDepth = -Infinity;

        // Corrigindo a maneira como acessamos os valores de cada eixo
        teeth.forEach((tooth) => {
          const x = tooth.centerOfMass[eixos.Largura.toLowerCase()];
          const depth = tooth.centerOfMass[eixos.Profundidade.toLowerCase()];
          if (x < minX) minX = x;
          if (x > maxX) maxX = x;
          if (depth < minDepth) minDepth = depth;
          if (depth > maxDepth) maxDepth = depth;
        });

        const centerWidth = (maxX + minX) / 2;
        const centerDepth = (maxDepth + minDepth) / 2;

        return {
          centerWidth: centerWidth,
          centerDepth: centerDepth,
          functionalDepthCenter: minDepth, // A profundidade mais frontal é minDepth
        };
      }

      function classifyTeeth(segmentedTeeth) {
        segmentedTeeth.forEach((tooth) => {
          const angleY = tooth.angles.y;

          // Classificação baseada nos ângulos Y ajustados
          if (angleY >= -55 && angleY <= 55) {
            tooth.type = "Incisivo";
          } else if (
            (angleY > 55 && angleY <= 75) ||
            (angleY < -55 && angleY >= -75)
          ) {
            tooth.type = "Canino";
          } else if (
            (angleY > 75 && angleY <= 110) ||
            (angleY < -75 && angleY >= -110)
          ) {
            tooth.type = "Pré-Molar";
          } else {
            tooth.type = "Molar";
          }
        });
      }

      function numberTeeth(segmentedTeethMap, archCenter, isUpperArcade) {
        const teeth = Array.from(segmentedTeethMap.entries());
        // Classificar os dentes baseado na posição relativa ao centerWidth
        teeth.sort((a, b) => a[1].centerOfMass.x - b[1].centerOfMass.x);

        // Encontrar o índice do dente mais próximo ao centro
        const centerIndex = teeth.findIndex(
          (tooth) => tooth[1].centerOfMass.x >= archCenter.centerWidth
        );

        // Objeto para armazenar os dentes numerados
        const numberedTeeth = {};

        // Atribuir números baseados na notação internacional
        teeth.forEach((tooth, index) => {
          let toothNumber;
          const toothData = tooth[1];
          if (index < centerIndex) {
            // Quadrante esquerdo
            toothNumber = centerIndex - index; // Decrementa da direita para a esquerda
          } else {
            // Quadrante direito
            toothNumber = index - centerIndex + 1; // Incrementa da esquerda para a direita
          }

          // Ajuste baseado na arcada e no quadrante
          if (isUpperArcade) {
            toothNumber += index < centerIndex ? 10 : 20; // Adiciona prefixo 1 ou 2 para arcada superior
          } else {
            toothNumber += index < centerIndex ? 40 : 30; // Adiciona prefixo 4 ou 3 para arcada inferior
          }

          tooth[1].number = toothNumber.toString();
          // Adicionando o dente ao objeto com o formato especificado
          numberedTeeth[toothNumber.toString()] = {
            borderPoints: [],
            borderTriangleIndices: [],
            segmentedTriangleIndices: toothData.triangles,
            medial: { x: 0, y: 0, z: 0 }, // Exemplo de coordenada, ajuste conforme necessário
            distal: { x: 0, y: 0, z: 0 }, // Exemplo de coordenada, ajuste conforme necessário
          };
        });
        return numberedTeeth;
      }

      // eslint-disable-next-line no-unused-vars
      function addCenterOfMassSpheres(segmenter) {
        // Mapeamento de tipos de dente para cores
        const toothColors = {
          Incisivo: 0xffffff, // Branco
          Canino: 0xffff00, // Amarelo
          "Pré-Molar": 0xffa500, // Laranja
          Molar: 0xff0000, // Vermelho
          Indeterminado: 0x0000ff, // Azul para tipos não determinados ou outros
        };

        const sphereRadius = 1; // Ajuste o tamanho conforme necessário
        const group = new THREE.Group();

        // Iterar sobre cada ilha segmentada e adicionar esferas
        segmenter.segmentedTriangleIndices.forEach((islandInfo) => {
          const centerOfMass = islandInfo.centerOfMass;
          const toothType = islandInfo.type; // Assume-se que classifyTeeth já adicionou 'type' em islandInfo
          const color = toothColors[toothType] || toothColors["Indeterminado"]; // Pega a cor baseada no tipo ou default azul

          // Cria um material com a cor selecionada para a esfera
          const sphereMaterial = new THREE.MeshBasicMaterial({ color: color });
          const sphereGeometry = new THREE.SphereGeometry(sphereRadius, 32, 32);
          const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
          sphereMesh.position.set(
            centerOfMass.x,
            centerOfMass.y,
            centerOfMass.z
          );
          group.add(sphereMesh);
        });

        return group;
      }

      // eslint-disable-next-line no-unused-vars
      function visualizeSegmentedIslands(segmenter) {
        const numOfIslands = segmenter.segmentedTriangleIndices.size;
        const colors = Array.from(
          { length: numOfIslands },
          () => new THREE.Color(Math.random(), Math.random(), Math.random())
        );

        const positions = [];
        const vertexColors = [];
        let islandIndex = 0;

        // Iterar sobre as ilhas segmentadas
        segmenter.segmentedTriangleIndices.forEach((islandInfo) => {
          const color = colors[islandIndex++];
          const triangleIndices = islandInfo.triangles;

          triangleIndices.forEach((index) => {
            const triangle = segmenter.candidateTrianglesSaved.find(
              (t) => t.index === index
            );
            if (triangle) {
              triangle.vertices.forEach((vertex) => {
                positions.push(vertex.x, vertex.y, vertex.z);
                vertexColors.push(color.r, color.g, color.b);
              });
            }
          });
        });

        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute(
          "position",
          new THREE.Float32BufferAttribute(positions, 3)
        );
        geometry.setAttribute(
          "color",
          new THREE.Float32BufferAttribute(vertexColors, 3)
        );

        geometry.computeVertexNormals();

        const material = new THREE.MeshBasicMaterial({
          vertexColors: true,
          side: THREE.DoubleSide,
          transparent: true,
          opacity: 1,
        });

        const mesh = new THREE.Mesh(geometry, material);
        mesh.userData = segmenter.mesh.userData;
        return mesh;
      }

      // Uso da função para visualizar as ilhas
      classifyTeeth(segmenter.segmentedTriangleIndices);
      mergeCloseClusters(segmenter, 10);

      //const toothMesh = visualizeSegmentedIslands(segmenter);
      //const sphereMesh = addCenterOfMassSpheres(segmenter);
      // addCenterOfMassSphere(segmenter.meshCenterOfMass, this.scene);
      //this.scene.add(toothMesh);
      //this.scene.add(sphereMesh);

      const axis = analyzeDentalArch(segmenter.segmentedTriangleIndices);
      const archCenter = findArchCenter(
        segmenter.segmentedTriangleIndices,
        axis
      );
      const modelType = this.activeObject.userData.modelType
        ? this.activeObject.userData.modelType
        : "maxillary";
      const numberedTeeth = numberTeeth(
        segmenter.segmentedTriangleIndices,
        archCenter,
        modelType === "maxillary"
      );
      return {
        numberedTeeth: numberedTeeth,
        triangles: segmenter.candidateTrianglesSaved,
        mergedMesh: segmenter.mergedMesh,
      };
    },

    updateDentalMapAfterLoading(dentalMap, modelId) {
      if (dentalMap) {
        // Atualizar apenas a arcada específica no dentalMap global
        this.dentalMap = { ...this.dentalMap, ...dentalMap };
        this.createDentalMeshGroup(dentalMap, modelId);
        this.$refs.progressDialog.cancelProcess();
        return true;
      }
      return false;
    },

    getMeanCurvature(mesh) {
      const segmenter = new GumSegmenter(mesh.clone(), this.scene, 2, true);

      return segmenter.centroidGumCurvature[0] * (180 / Math.PI);
    },

    makeGradientMesh() {
      const gradientMesh = new HarmonicGradientMesh(this.activeObject);
      gradientMesh.applyGradientMap();

      const material = new THREE.MeshPhongMaterial({ vertexColors: true });
      const coloredMesh = new THREE.Mesh(this.activeObject.geometry, material);

      // Adicione `coloredMesh` à sua cena
      this.scene.add(coloredMesh);
    },

    async gumSearch() {
      this.iaActive = "blue";

      const modelId = this.activeObject.userData.modelId
        ? this.activeObject.userData.modelId
        : 0;
      console.warn(`Model ID ${modelId}`);
      let dentalMap = await this.dbLoadDentalMap(modelId);
      if (this.updateDentalMapAfterLoading(dentalMap, modelId)) return;

      this.removeBorderMesh();
      const numOfClusters = 2;

      const segmenter = new GumSegmenter(
        this.activeObject.clone(),
        this.scene,
        numOfClusters
      );

      if (segmenter.borderTriangleIndices && 1 === 2) {
        const data = [];
        const indices = [];

        for (const triangle of segmenter.candidateTriangles) {
          if (segmenter.borderTriangleIndices.has(triangle.index)) {
            data.push([triangle.area, triangle.curvature]); // Adiciona o centro do triângulo
            indices.push(triangle.index);
          }
        }

        const result = KMeans(data, numOfClusters);

        const maxClusterIndex = result.centroids.reduce(
          (maxIndex, cluster, index, arr) =>
            cluster.size > arr[maxIndex].size ? index : maxIndex,
          0
        );

        console.table({ maxClusterIndex, result });

        const clusters = Array.from({ length: numOfClusters }, () => []);

        result.clusters.forEach((clusterIndex, dataIndex) => {
          const triangleIndex = indices[dataIndex];
          clusters[clusterIndex].push(
            segmenter.candidateTriangles[triangleIndex]
          );
        });

        // Gerar um array de cores aleatórias para os clusters
        const colors = Array.from(
          { length: numOfClusters },
          () => new THREE.Color(Math.random(), Math.random(), Math.random())
        );

        // Adicionar cores aos vértices com base nos clusters
        const geometry = new THREE.BufferGeometry();
        const positions = [];
        const vertexColors = [];

        clusters.forEach((clusterIndices, clusterIndex) => {
          const color = colors[clusterIndex];

          if (clusterIndex === maxClusterIndex)
            clusterIndices.forEach((triangleIndex) => {
              if (triangleIndex) {
                for (const vertex of triangleIndex.vertices) {
                  positions.push(vertex.x, vertex.y, vertex.z);
                  vertexColors.push(color.r, color.g, color.b);
                }
              }
            });
        });

        geometry.setAttribute(
          "position",
          new THREE.Float32BufferAttribute(positions, 3)
        );
        geometry.setAttribute(
          "color",
          new THREE.Float32BufferAttribute(vertexColors, 3)
        );

        geometry.computeVertexNormals();

        const material = new THREE.MeshBasicMaterial({
          vertexColors: true,
          side: THREE.DoubleSide,
          transparent: true,
          opacity: 1,
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.userData = this.activeObject.userData;
        this.scene.add(mesh);
        return;
      }

      const { lowCurvatureMesh: low, highCurvatureMesh: high } =
        segmenter.processBorderTriangles();
      if (low && high) {
        // Chame a função toothSearch passando o último filho
        const toothData = this.toothSearch(low, high);
        const teethMap = toothData.numberedTeeth;

        const newDentalMap = segmenter.updateDentalMapIndices(
          segmenter.mesh,
          toothData.mergedMesh,
          teethMap,
          this.dentalMap
        );

        // Mescla o novo mapa com o existente, preservando os dados anteriores
        this.dentalMap = {
          ...this.dentalMap,
          ...newDentalMap,
        };
        this.activeObject = segmenter.mesh;
        if (modelId) this.dbSaveDentalMap(modelId, newDentalMap);
        this.createDentalMeshGroup(newDentalMap);
        this.$refs.progressDialog.cancelProcess();
      }
    },

    updateMarker() {
      this.iaActive = "blue";
      this.removeBorderMesh();
      this.statusMessage = `Learning n${this.miniGun.neighbor} - v${this.miniGun.variation}`;
      const miniGun = new MeshSegmenterMiniGun(
        this.miniGun.object,
        this.miniGun.point,
        this.miniGun,
        this.scene
      );
      miniGun.segment();
      const borderIndices = [...miniGun.largestLoop];
      this.$store.dispatch("editor3D/setContourPoints", borderIndices);
      this.updateContour();

      if (miniGun.borderTriangleIndices) {
        var borderVolume = this.createMeshFromCloseTrianglesExtended(
          this.activeObject,
          miniGun.borderTriangleIndices,
          0x0000ff,
          false
        );
      }
      // const interestPoints = this.createInterestArea(
      //   miniGun.borderPointsCandidates,
      //   10
      // );
      this.addSpheresWithGroup(
        miniGun.borderPointsCandidates,
        0.25,
        0x0000ff,
        "Border Candidates"
      );

      // let startPoint = interestPoints[110]; // Exemplo de ponto de início
      // this.addSpheresWithGroup([interestPoints[110]], 0.5, 0xffff00, "Start Point");
      // let maxDistance = 5; // Distância máxima permitida entre pontos
      // let initialAngle = Math.PI * 2; // Ângulo inicial de 360 graus
      // let followUpAngle = Math.PI / 2; // Ângulo de 30 graus para os pontos subsequentes

      // let contour = this.createClosedContour(
      //   interestPoints,
      //   startPoint,
      //   maxDistance,
      //   initialAngle,
      //   followUpAngle
      // );

      // // Agora você pode usar 'contour' conforme necessário
      // console.log(contour);

      // const silhouetteMethod = this.silhouetteMethod(interestPoints)
      // console.warn(`Optimal K ${silhouetteMethod}`)

      // const centroid = this.groupToothCentroidsWithKMeans(miniGun.borderPointsCandidates, 14);
      // this.addSpheresWithGroup(centroid, 1, 0xff0000);

      if (miniGun.segmentedTriangles) {
        var toothVolume = this.createMeshFromCloseTrianglesExtended(
          this.activeObject,
          miniGun.segmentedTriangles,
          0xffff00,
          false
        );
      }
      if (borderVolume && toothVolume) {
        console.table(borderVolume.volume / toothVolume.volume);
      }
      // Procurar por um grupo existente com userData.isMeshBorder igual a true
      let meshGroup = this.scene.children.find(
        (child) => child.isGroup && child.userData.isMeshBorder === true
      );
      // Se não encontrou, cria um novo grupo e define a propriedade userData.isMeshBorder
      if (!meshGroup) {
        meshGroup = new THREE.Group();
        meshGroup.userData.isMeshBorder = true;
        meshGroup.userData.name = "borderData";
        this.scene.add(meshGroup);
      }
      //miniGun.drawSelectedTriangles(miniGun.largestLoop, meshGroup)
      this.iaActive = "gray";

      // Example usage
      //   let triangleIndices = borderIndices; // Assume this function provides the triangle indices for the border
      //   // eslint-disable-next-line no-unused-vars
      //   const { contourCurve, spheres } = getSmoothContour(
      //     this.miniGun.point,
      //     this.miniGun.object,
      //     triangleIndices
      //   );

      //   spheres.forEach((sphere) => {
      //     let geometry = new THREE.SphereGeometry(0.5, 16, 16); // Adjust size as needed
      // let material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
      // let mesh = new THREE.Mesh(geometry, material);
      // mesh.position.copy(sphere.position);
      // meshGroup.add(mesh)
      //   })

      //   console.table(contourCurve)

      // if (this.currentMarker) {
      //     this.scene.remove(this.currentMarker);
      // }
      // const pointData = this.dataMatrix[this.currentIndex.row][this.currentIndex.col];

      // if (pointData && pointData.point) {
      //     const geometry = new THREE.SphereGeometry(0.5, 32, 32);
      //     const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
      //     this.currentMarker = new THREE.Mesh(geometry, material);
      //     this.currentMarker.position.copy(pointData.point);
      //     this.scene.add(this.currentMarker);
      //     //console.table({row : this.currentIndex.row, col : this.currentIndex.col,point :  pointData })

      // }
    },

    async processMesh(
      positions,
      normals,
      toothIds,
      centerPoints,
      neighbor,
      variation,
      curvature
    ) {
      const node_url = "http://189.4.83.169:3000/process-mesh/";
      //const node_url = "http://localhost:3000/process-mesh/";
      console.warn("ProcessMesh Node Server Called - " + node_url);
      this.statusMessage = "Segmentation Server Called ... wait";

      const startTime = performance.now(); // Captura o tempo de início

      const response = await fetch(node_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          positions,
          normals,
          toothIds,
          centerPoints,
          neighbor,
          variation,
          curvature,
        }),
      });

      const result = await response.json();

      const endTime = performance.now(); // Captura o tempo de término
      const elapsedTime = endTime - startTime; // Calcula o tempo decorrido

      console.log(
        `Tempo de execução da função processMesh: ${elapsedTime.toFixed(2)} ms`
      );

      this.statusMessage = "Segmentation Complete";
      return result;
    },
    scanDentalMapforMiniGun() {
      if (!this.activeObject) {
        console.warn("No active object selected.");
        return;
      }

      this.startMiniGunWorker();

      // Object.keys(this.dentalMap).forEach((selectedTooth) => {
      //   const toothData = this.dentalMap[selectedTooth];
      //   if (toothData.medial && toothData.distal) {
      //     this.startMiniGunWorker(selectedTooth);
      //   }
      // });
    },

    generateToothArrays() {
      const toothIds = [];
      const centerPoints = [];

      for (const toothId in this.dentalMap) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.dentalMap.hasOwnProperty(toothId) &&
          this.dentalMap[toothId].medial
        ) {
          const medialPoint = this.dentalMap[toothId].medial;
          const distalPoint = this.dentalMap[toothId].distal;

          const centerPoint = {
            x: (medialPoint.x + distalPoint.x) / 2,
            y: (medialPoint.y + distalPoint.y) / 2,
            z: (medialPoint.z + distalPoint.z) / 2,
          };

          toothIds.push(toothId);
          centerPoints.push(centerPoint);
        }
      }

      return { toothIds, centerPoints };
    },

    startMiniGunWorker() {
      // Obtenha os dados de posição da malha ativa (supondo que activeObject é uma malha)
      const positions = this.activeObject.geometry.attributes.position.array;
      const normals = this.activeObject.geometry.attributes.normal.array;

      // Converta os dados para arrays normais
      const positionsArray = Array.from(positions);
      const normalsArray = Array.from(normals);

      // Gere os arrays de toothIds e centerPoints
      const { toothIds, centerPoints } = this.generateToothArrays();

      // Chame a função processMesh com os arrays gerados
      this.processMesh(
        positionsArray,
        normalsArray,
        toothIds,
        centerPoints,
        this.miniGun.neighbor,
        this.miniGun.variation,
        this.miniGun.curvature
      )
        .then((result) => {
          console.log("Result:", result);

          // Iterar sobre cada toothId no resultado
          Object.keys(result).forEach((toothId) => {
            const { borderPoints, borderTriangleIndices, segmentedTriangles } =
              result[toothId];

            this.dentalMap[toothId].borderPoints = borderPoints || [];
            this.dentalMap[toothId].borderTriangleIndices =
              borderTriangleIndices;
            this.dentalMap[toothId].segmentedTriangleIndices =
              segmentedTriangles.length >= 8000 ? [] : segmentedTriangles;

            if (this.dentalMap[toothId].borderTriangleIndices) {
              this.createMeshFromCloseTrianglesExtended(
                this.activeObject,
                this.dentalMap[toothId].borderTriangleIndices,
                0x0000ff,
                false
              );
            }
            if (this.dentalMap[toothId].segmentedTriangleIndices) {
              this.createMeshFromCloseTrianglesExtended(
                this.activeObject,
                this.dentalMap[toothId].segmentedTriangleIndices,
                0xffff00,
                false
              );
            }
          });

          this.statusMessage = "";
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      // Inicialize o Web Worker
      const worker = new Worker(
        new URL("@/workers/miniGun.js", import.meta.url),
        { type: "module" }
      );

      // Envie os dados clonados para o worker
      // worker.postMessage({
      //   positions: positionsArrayBuffer,
      //   normals: normalsArrayBuffer,
      //   toothId: selectedTooth,
      //   centerPoint: centerPoint,
      //   neighbor: this.miniGun.neighbor,
      //   variation: this.miniGun.variation,
      // });

      // Ouvir por mensagens do worker
      worker.onmessage = (event) => {
        // Atualize a interface com os pontos de borda recebidos
        const { type, data } = event.data;
        if (type == "result") {
          // eslint-disable-next-line no-unused-vars
          const { borderPoints,
            borderTriangleIndices,
            segmentedTriangles,
            toothId,
          } = data;
          this.dentalMap[toothId].borderPoints = [];
          this.dentalMap[toothId].borderTriangleIndices = borderTriangleIndices;
          this.dentalMap[toothId].segmentedTriangleIndices =
            segmentedTriangles.length >= 8000 ? [] : segmentedTriangles;

          if (this.dentalMap[toothId].borderTriangleIndices)
            this.createMeshFromCloseTrianglesExtended(
              this.activeObject,
              this.dentalMap[toothId].borderTriangleIndices,
              0x0000ff,
              false
            );
          if (this.dentalMap[toothId].segmentedTriangleIndices)
            this.createMeshFromCloseTrianglesExtended(
              this.activeObject,
              this.dentalMap[toothId].segmentedTriangleIndices,
              0xffff00,
              false
            );

          this.statusMessage = "";
          // Encerra o worker
          worker.terminate();
        } else if (type == "message") {
          const { text } = data;
          this.statusMessage = text;
        }
      };
    },
  },
};

// eslint-disable-next-line no-unused-vars
class ToothSegmenter {
  constructor(lowCurvature, highCurvature) {
    this.startTime = performance.now();
    this.mesh = lowCurvature;
    this.meshBorder = highCurvature;
    this.candidateTriangles = [];
    this.candidateTrianglesSaved = [];
    this.segmentedTriangleIndices = new Map(); // Mapa para armazenar as ilhas segmentadas
    this.visited = new Set(); // Conjunto para manter o controle dos triângulos visitados
    this.adjacencyMapHighCurvature = new Map();

    this.initialize();
  }

  initialize() {
    this.candidateTriangles = this.getTriangles(this.mesh);
    console.log(
      `Filtering triangles time: ${(performance.now() - this.startTime).toFixed(
        2
      )} ms`
    );

    this.candidateTrianglesSaved = [...this.candidateTriangles];

    this.buildAdjacencyMap();
    console.log(
      `Build Adjascency Map time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );
    this.buildAdjacencyMapForHighCurvature();
    console.log(
      `Build Adjascency High Curvature Map time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );
  }

  getTriangles(mesh) {
    function calculateTriangleArea(A, B, C) {
      const AB = {
        x: B.x - A.x,
        y: B.y - A.y,
        z: B.z - A.z,
      };
      const AC = {
        x: C.x - A.x,
        y: C.y - A.y,
        z: C.z - A.z,
      };
      const crossProduct = {
        x: AB.y * AC.z - AB.z * AC.y,
        y: AB.z * AC.x - AB.x * AC.z,
        z: AB.x * AC.y - AB.y * AC.x,
      };
      const area =
        0.5 *
        Math.sqrt(
          crossProduct.x * crossProduct.x +
            crossProduct.y * crossProduct.y +
            crossProduct.z * crossProduct.z
        );
      return area;
    }

    function calculateTriangleCentroid(A, B, C) {
      return new THREE.Vector3(
        (A.x + B.x + C.x) / 3,
        (A.y + B.y + C.y) / 3,
        (A.z + B.z + C.z) / 3
      );
    }

    const positions = mesh.geometry.attributes.position.array;
    const totalTriangles = positions.length / 9;
    const candidateTriangles = [];
    let totalMass = 0;
    let weightedCenter = new THREE.Vector3(0, 0, 0);

    for (let i = 0; i < totalTriangles; i++) {
      const baseIndex = i * 9;
      const vertices = [
        new THREE.Vector3(
          positions[baseIndex],
          positions[baseIndex + 1],
          positions[baseIndex + 2]
        ),
        new THREE.Vector3(
          positions[baseIndex + 3],
          positions[baseIndex + 4],
          positions[baseIndex + 5]
        ),
        new THREE.Vector3(
          positions[baseIndex + 6],
          positions[baseIndex + 7],
          positions[baseIndex + 8]
        ),
      ];
      const area = calculateTriangleArea(vertices[0], vertices[1], vertices[2]);
      const centroid = calculateTriangleCentroid(
        vertices[0],
        vertices[1],
        vertices[2]
      );

      // Calculating the weighted centroid for the mesh
      weightedCenter.add(centroid.multiplyScalar(area));
      totalMass += area;

      candidateTriangles.push({
        index: i,
        vertices: vertices,
        area: area,
        center: centroid,
      });
    }

    if (totalMass > 0) {
      weightedCenter.divideScalar(totalMass);
    }

    this.meshCenterOfMass = weightedCenter; // Store or process the mesh center of mass as needed
    return candidateTriangles;
  }

  buildAdjacencyMap() {
    // Cria um mapa de adjacência para os triângulos
    this.adjacencyMap = new Map();
    this.candidateTriangles.forEach((triangle) => {
      triangle.vertices.forEach((vertex) => {
        let key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
          15
        )}-${vertex.z.toFixed(15)}`;
        if (!this.adjacencyMap.has(key)) {
          this.adjacencyMap.set(key, []);
        }
        this.adjacencyMap.get(key).push(triangle.index);
      });
    });
  }

  buildAdjacencyMapForHighCurvature() {
    // Implementar lógica para construir o mapa de adjacência para o mesh de alta curvatura
    const positions = this.meshBorder.geometry.attributes.position.array;
    const totalTriangles = positions.length / 9;
    this.adjacencyMapHighCurvature = new Map();

    for (let i = 0; i < totalTriangles; i++) {
      const baseIndex = i * 9;
      const vertices = [
        new THREE.Vector3(
          positions[baseIndex],
          positions[baseIndex + 1],
          positions[baseIndex + 2]
        ),
        new THREE.Vector3(
          positions[baseIndex + 3],
          positions[baseIndex + 4],
          positions[baseIndex + 5]
        ),
        new THREE.Vector3(
          positions[baseIndex + 6],
          positions[baseIndex + 7],
          positions[baseIndex + 8]
        ),
      ];
      vertices.forEach((vertex) => {
        let key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
          15
        )}-${vertex.z.toFixed(15)}`;
        if (!this.adjacencyMapHighCurvature.has(key)) {
          this.adjacencyMapHighCurvature.set(key, []);
        }
        this.adjacencyMapHighCurvature.get(key).push(i);
      });
    }
  }

  findLostNeighbors__() {
    this.createTriangleMapping();
    const lostNeighbors = [];

    // Itera sobre todos os triângulos de alta curvatura
    this.triangleMapping.forEach((correspondingTriangles) => {
      correspondingTriangles.highCurvature.forEach((highCurvatureTriangle) => {
        const highCurvatureIndex = highCurvatureTriangle.index;
        const highCurvatureCenter = highCurvatureTriangle.center; // Supondo que o triângulo tenha um centro pré-calculado

        let closestSegmentIndex = null;
        let minDistance = Infinity;

        // Encontra o centro de massa mais próximo dentre os segmentos
        this.segmentedTriangleIndices.forEach((segment, segmentIndex) => {
          const segmentCenter = segment.centerOfMass; // Supondo que o segmento tenha um centro de massa

          const distance = Math.sqrt(
            Math.pow(segmentCenter.x - highCurvatureCenter.x, 2) +
              Math.pow(segmentCenter.y - highCurvatureCenter.y, 2) +
              Math.pow(segmentCenter.z - highCurvatureCenter.z, 2)
          );

          if (distance < minDistance) {
            minDistance = distance;
            closestSegmentIndex = segmentIndex;
          }
        });

        // Adiciona o triângulo de alta curvatura ao segmento mais próximo
        if (closestSegmentIndex !== null) {
          this.segmentedTriangleIndices[closestSegmentIndex].triangles.push(
            highCurvatureIndex
          );
          lostNeighbors.push({
            segmentIndex: closestSegmentIndex,
            lostNeighborIndex: highCurvatureIndex,
          });

          // Remove o triângulo da lista de alta curvatura
          correspondingTriangles.highCurvature =
            correspondingTriangles.highCurvature.filter(
              (triangle) => triangle.index !== highCurvatureIndex
            );
        }
      });
    });

    return lostNeighbors;
  }

  findLostNeighbors() {
    this.createTriangleMapping();
    const lostNeighbors = [];
    const pushAll = false;

    this.segmentedTriangleIndices.forEach((segment, segmentIndex) => {
      segment.triangles.forEach((triangleIndex) => {
        const triangle = this.candidateTrianglesSaved.find(
          (t) => t.index === triangleIndex
        );

        if (triangle && triangle.vertices) {
          triangle.vertices.forEach((vertex) => {
            let key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
              15
            )}-${vertex.z.toFixed(15)}`;

            if (this.triangleMapping.has(key)) {
              const correspondingTriangles = this.triangleMapping.get(key);

              // Itera sobre os triângulos de alta curvatura
              correspondingTriangles.highCurvature =
                correspondingTriangles.highCurvature.filter(
                  (highCurvatureTriangle) => {
                    const highCurvatureIndex = highCurvatureTriangle.index;

                    if (
                      !segment.triangles.includes(highCurvatureIndex) ||
                      pushAll
                    ) {
                      lostNeighbors.push({
                        segmentIndex: segmentIndex,
                        lostNeighborIndex: highCurvatureIndex,
                      });

                      // Adiciona o triângulo ao conjunto segmentado
                      segment.triangles.push(highCurvatureIndex);

                      // Remove o triângulo da lista de alta curvatura
                      return false; // Filtra esse triângulo para removê-lo da lista
                    }

                    return true; // Mantém o triângulo na lista se não foi adicionado ao segmento
                  }
                );

              // Itera sobre os triângulos de baixa curvatura
              correspondingTriangles.lowCurvature.forEach(
                (lowCurvatureTriangle) => {
                  const lowCurvatureIndex = lowCurvatureTriangle.index;

                  if (!segment.triangles.includes(lowCurvatureIndex)) {
                    lostNeighbors.push({
                      segmentIndex: segmentIndex,
                      lostNeighborIndex: lowCurvatureIndex,
                    });

                    // Adiciona o triângulo ao conjunto segmentado
                    segment.triangles.push(lowCurvatureIndex);
                  }
                }
              );
            }
          });
        }
      });
    });

    return lostNeighbors;
  }

  findLostNeighbors_() {
    this.createTriangleMapping();
    const lostNeighbors = [];

    this.segmentedTriangleIndices.forEach((segment, segmentIndex) => {
      segment.triangles.forEach((triangleIndex) => {
        const triangle = this.candidateTrianglesSaved.find(
          (t) => t.index === triangleIndex
        );

        if (triangle && triangle.vertices) {
          triangle.vertices.forEach((vertex) => {
            let key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
              15
            )}-${vertex.z.toFixed(15)}`;

            if (this.triangleMapping.has(key)) {
              const correspondingTriangles = this.triangleMapping.get(key);

              correspondingTriangles.highCurvature.forEach(
                (highCurvatureTriangle) => {
                  const highCurvatureIndex = highCurvatureTriangle.index;
                  if (!segment.triangles.includes(highCurvatureIndex)) {
                    lostNeighbors.push({
                      segmentIndex: segmentIndex,
                      lostNeighborIndex: highCurvatureIndex,
                    });

                    // Adiciona o triângulo do mesh border ao conjunto segmentado
                    segment.triangles.push(highCurvatureIndex);
                  }
                }
              );

              correspondingTriangles.lowCurvature.forEach(
                (lowCurvatureTriangle) => {
                  const lowCurvatureIndex = lowCurvatureTriangle.index;
                  if (!segment.triangles.includes(lowCurvatureIndex)) {
                    lostNeighbors.push({
                      segmentIndex: segmentIndex,
                      lostNeighborIndex: lowCurvatureIndex,
                    });

                    // Adiciona o triângulo do mesh de baixa curvatura ao conjunto segmentado
                    segment.triangles.push(lowCurvatureIndex);
                  }
                }
              );
            }
          });
        }
      });
    });

    return lostNeighbors;
  }

  createTriangleMapping() {
    const triangleMap = new Map();

    // Merging the geometries of low and high curvature meshes
    const mergedGeometry = BufferGeometryUtils.mergeGeometries(
      [this.mesh.geometry, this.meshBorder.geometry],
      false
    );

    this.mergedMesh = new THREE.Mesh(mergedGeometry, this.mesh.material);

    // Mapeia os triângulos do mesh mesclado
    const positions = this.mergedMesh.geometry.attributes.position.array;
    const totalTriangles = positions.length / 9;

    // A estrutura para manter track de onde os triângulos vieram (low ou high)
    const isLowCurvatureTriangle = (index) =>
      index < this.mesh.geometry.attributes.position.array.length / 9;

    for (let i = 0; i < totalTriangles; i++) {
      const baseIndex = i * 9;
      const vertices = [
        new THREE.Vector3(
          positions[baseIndex],
          positions[baseIndex + 1],
          positions[baseIndex + 2]
        ),
        new THREE.Vector3(
          positions[baseIndex + 3],
          positions[baseIndex + 4],
          positions[baseIndex + 5]
        ),
        new THREE.Vector3(
          positions[baseIndex + 6],
          positions[baseIndex + 7],
          positions[baseIndex + 8]
        ),
      ];

      // Calcula o centro do triângulo como a média dos vértices
      const center = new THREE.Vector3(
        (vertices[0].x + vertices[1].x + vertices[2].x) / 3,
        (vertices[0].y + vertices[1].y + vertices[2].y) / 3,
        (vertices[0].z + vertices[1].z + vertices[2].z) / 3
      );

      vertices.forEach((vertex) => {
        let key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
          15
        )}-${vertex.z.toFixed(15)}`;
        if (!triangleMap.has(key)) {
          triangleMap.set(key, {
            lowCurvature: [],
            highCurvature: [],
            vertices: [],
          });
        }
        const triangleData = { index: i, vertices, center };

        if (isLowCurvatureTriangle(i)) {
          triangleMap.get(key).lowCurvature.push(triangleData);
        } else {
          triangleMap.get(key).highCurvature.push(triangleData);
        }
      });
    }

    this.triangleMapping = triangleMap;
  }

  createTriangleMapping_() {
    const triangleMap = new Map();

    // Merging the geometries of low and high curvature meshes
    const mergedGeometry = BufferGeometryUtils.mergeGeometries(
      [this.mesh.geometry, this.meshBorder.geometry],
      false
    );

    this.mergedMesh = new THREE.Mesh(mergedGeometry, this.mesh.material);

    // Mapeia os triângulos do mesh mesclado
    const positions = this.mergedMesh.geometry.attributes.position.array;
    const totalTriangles = positions.length / 9;

    // A estrutura para manter track de onde os triângulos vieram (low ou high)
    const isLowCurvatureTriangle = (index) =>
      index < this.mesh.geometry.attributes.position.array.length / 9;

    for (let i = 0; i < totalTriangles; i++) {
      const baseIndex = i * 9;
      const vertices = [
        new THREE.Vector3(
          positions[baseIndex],
          positions[baseIndex + 1],
          positions[baseIndex + 2]
        ),
        new THREE.Vector3(
          positions[baseIndex + 3],
          positions[baseIndex + 4],
          positions[baseIndex + 5]
        ),
        new THREE.Vector3(
          positions[baseIndex + 6],
          positions[baseIndex + 7],
          positions[baseIndex + 8]
        ),
      ];
      vertices.forEach((vertex) => {
        let key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
          15
        )}-${vertex.z.toFixed(15)}`;
        if (!triangleMap.has(key)) {
          triangleMap.set(key, {
            lowCurvature: [],
            highCurvature: [],
            vertices: [],
          });
        }
        if (isLowCurvatureTriangle(i)) {
          triangleMap.get(key).lowCurvature.push({ index: i, vertices });
        } else {
          triangleMap.get(key).highCurvature.push({ index: i, vertices });
        }
      });
    }

    this.triangleMapping = triangleMap;
  }

  segment() {
    while (this.candidateTriangles.length > 0) {
      const randomIndex = Math.floor(
        Math.random() * this.candidateTriangles.length
      );
      const triangle = this.candidateTriangles[randomIndex];

      if (!this.visited.has(triangle.index)) {
        const island = this.expandFromFirst(triangle.index);

        // Cálculo do centro de massa da ilha
        let centerOfMass = new THREE.Vector3(0, 0, 0);
        let totalArea = 0;
        island.forEach((triangleIndex) => {
          const tri = this.candidateTriangles.find(
            (t) => t.index === triangleIndex
          );
          const area = tri.area;
          const centroid = new THREE.Vector3()
            .add(tri.vertices[0])
            .add(tri.vertices[1])
            .add(tri.vertices[2])
            .divideScalar(3);

          // Ponderar o centroide pelo área do triângulo
          centerOfMass.add(centroid.multiplyScalar(area));
          totalArea += area;
        });
        if (totalArea > 0) {
          centerOfMass.divideScalar(totalArea);
        }

        // Calculando os ângulos em relação ao centro de massa do mesh
        let vectorToMeshCenter = centerOfMass
          .clone()
          .sub(this.meshCenterOfMass);
        let angles = {
          x:
            (Math.atan2(vectorToMeshCenter.y, vectorToMeshCenter.z) * 180) /
            Math.PI, // Rotação em torno do eixo X
          y:
            (Math.atan2(vectorToMeshCenter.x, vectorToMeshCenter.z) * 180) /
            Math.PI, // Rotação em torno do eixo Y
          z:
            (Math.atan2(vectorToMeshCenter.y, vectorToMeshCenter.x) * 180) /
            Math.PI, // Rotação em torno do eixo Z
        };

        // Adicionar a ilha ao mapa se ela tiver mais de 1000 triângulos
        if (island.length > 1000 && island.length < 7000) {
          this.segmentedTriangleIndices.set(triangle.index, {
            triangles: island,
            centerOfMass: centerOfMass,
            angles: angles,
          });
        }
      }

      // Remover o triângulo visitado da lista de candidatos
      this.candidateTriangles.splice(randomIndex, 1);
    }
    console.log(
      `Segment triangles time: ${(performance.now() - this.startTime).toFixed(
        2
      )} ms`
    );
  }

  expandFromFirst(startIndex) {
    let queue = [startIndex];
    let island = [];

    while (queue.length > 0) {
      const currentIndex = queue.shift();
      if (this.visited.has(currentIndex)) continue;

      this.visited.add(currentIndex);
      island.push(currentIndex);

      const neighbors = this.getNeighbors(currentIndex);
      if (neighbors.length > 1)
        neighbors.forEach((neighbor) => {
          if (!this.visited.has(neighbor)) {
            queue.push(neighbor);
          }
        });
    }
    return island;
  }

  getNeighbors(index) {
    const triangle = this.candidateTriangles.find((t) => t.index === index);
    const neighbors = [];

    triangle.vertices.forEach((vertex) => {
      let key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
        15
      )}-${vertex.z.toFixed(15)}`;
      this.adjacencyMap.get(key).forEach((adjIndex) => {
        if (adjIndex !== index) {
          neighbors.push(adjIndex);
        }
      });
    });

    return neighbors;
  }
}

// eslint-disable-next-line no-unused-vars
class HarmonicGradientMesh {
  constructor(mesh) {
    this.mesh = mesh;
    this.vertexMap = new Map();
    this.adjacencyMap = new Map();
    this.curvatureMap = new Map();
    this.gradientMap = new Map();

    this.initialize();
  }

  initialize() {
    this.buildVertexMap();
    this.buildAdjacencyMap();
    this.calculateCurvature(); // Se ainda precisar da curvatura
    this.calculateNormalVariation(); // Adicionar a variação das normais
    this.calculateHarmonicField();
    this.applyGradientMap();
  }

  buildVertexMap() {
    const positions = this.mesh.geometry.attributes.position.array;
    const totalVertices = positions.length / 3;

    for (let i = 0; i < totalVertices; i++) {
      const vertex = new THREE.Vector3(
        positions[i * 3],
        positions[i * 3 + 1],
        positions[i * 3 + 2]
      );
      const key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
        15
      )}-${vertex.z.toFixed(15)}`;

      if (!this.vertexMap.has(key)) {
        this.vertexMap.set(key, vertex);
      }
    }
  }

  buildAdjacencyMap() {
    const positions = this.mesh.geometry.attributes.position.array;
    const totalTriangles = positions.length / 9;

    for (let i = 0; i < totalTriangles; i++) {
      const vertices = [
        new THREE.Vector3(
          positions[i * 9],
          positions[i * 9 + 1],
          positions[i * 9 + 2]
        ),
        new THREE.Vector3(
          positions[i * 9 + 3],
          positions[i * 9 + 4],
          positions[i * 9 + 5]
        ),
        new THREE.Vector3(
          positions[i * 9 + 6],
          positions[i * 9 + 7],
          positions[i * 9 + 8]
        ),
      ];

      vertices.forEach((vertex) => {
        const key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
          15
        )}-${vertex.z.toFixed(15)}`;

        if (!this.adjacencyMap.has(key)) {
          this.adjacencyMap.set(key, []);
        }
        this.adjacencyMap.get(key).push(i);
      });
    }
  }

  calculateCurvature() {
    const positions = this.mesh.geometry.attributes.position.array;
    const normals = this.mesh.geometry.attributes.normal.array;
    const totalVertices = positions.length / 3;

    for (let i = 0; i < totalVertices; i++) {
      const vertex = new THREE.Vector3(
        positions[i * 3],
        positions[i * 3 + 1],
        positions[i * 3 + 2]
      );
      const normal = new THREE.Vector3(
        normals[i * 3],
        normals[i * 3 + 1],
        normals[i * 3 + 2]
      );

      const key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
        15
      )}-${vertex.z.toFixed(15)}`;

      const neighbors = this.getNeighbors(vertex);
      let curvature = 0;

      neighbors.forEach((neighbor) => {
        const neighborNormal = new THREE.Vector3(
          normals[neighbor * 3],
          normals[neighbor * 3 + 1],
          normals[neighbor * 3 + 2]
        );
        curvature += normal.angleTo(neighborNormal);
      });

      curvature /= neighbors.length;
      this.curvatureMap.set(key, curvature);
    }
  }

  calculateHarmonicField_() {
    // Initialize gradientMap with curvature values
    this.curvatureMap.forEach((curvature, key) => {
      this.gradientMap.set(key, curvature);
    });

    // Function to get neighbors of order 2
    const getNeighborsOfOrder2 = (vertex) => {
      const firstOrderNeighbors = this.getNeighbors(vertex);
      const secondOrderNeighbors = new Set();

      firstOrderNeighbors.forEach((neighbor) => {
        const neighborsOfNeighbor = this.getNeighbors(neighbor);
        neighborsOfNeighbor.forEach((n) => {
          secondOrderNeighbors.add(n);
        });
      });

      // Remove the original vertex itself from the second-order neighbors
      secondOrderNeighbors.delete(vertex);

      return Array.from(secondOrderNeighbors);
    };

    // Iteratively solve the harmonic field using the Laplacian matrix
    for (let iteration = 0; iteration < 5; iteration++) {
      const newGradientMap = new Map();
      let sumIteration = 0;

      this.gradientMap.forEach((value, key) => {
        const vertex = this.vertexMap.get(key);
        if (!vertex) return;

        const neighbors = this.getNeighbors(vertex);
        const secondOrderNeighbors = getNeighborsOfOrder2(vertex);

        if (neighbors.length === 0 && secondOrderNeighbors.length === 0) return;

        let sum = 0;
        neighbors.forEach((neighbor) => {
          const neighborKey = `${neighbor.x.toFixed(15)}-${neighbor.y.toFixed(
            15
          )}-${neighbor.z.toFixed(15)}`;
          sum += this.gradientMap.get(neighborKey) || 0;
        });

        secondOrderNeighbors.forEach((neighbor) => {
          const neighborKey = `${neighbor.x.toFixed(15)}-${neighbor.y.toFixed(
            15
          )}-${neighbor.z.toFixed(15)}`;
          sum += this.gradientMap.get(neighborKey) || 0;
        });

        const totalNeighbors = neighbors.length + secondOrderNeighbors.length;
        const newValue = totalNeighbors > 0 ? sum / totalNeighbors : value;

        sumIteration += sum;
        newGradientMap.set(key, newValue);
      });

      console.warn(`Iteration ${iteration} - Sum ${sumIteration}`);
      this.gradientMap = new Map(newGradientMap);
    }
  }

  calculateHarmonicField() {
    // Initialize gradientMap with curvature values
    this.curvatureMap.forEach((curvature, key) => {
      this.gradientMap.set(key, curvature);
    });

    // Iteratively solve the harmonic field using the Laplacian matrix
    for (let iteration = 0; iteration < 15; iteration++) {
      const newGradientMap = new Map();
      let sumIteration = 0;
      this.gradientMap.forEach((value, key) => {
        const vertex = this.vertexMap.get(key);
        if (!vertex) return;

        const neighbors = this.getNeighbors(vertex);
        if (neighbors.length === 0) return;

        let sum = 0;
        neighbors.forEach((neighbor) => {
          const neighborKey = `${neighbor.x.toFixed(15)}-${neighbor.y.toFixed(
            15
          )}-${neighbor.z.toFixed(15)}`;
          sum += this.gradientMap.get(neighborKey) || 0;
        });
        sumIteration += sum;
        newGradientMap.set(key, sum / neighbors.length);
      });
      console.warn(`Iteration ${iteration} - Sum ${sumIteration}`);
      this.gradientMap = new Map(newGradientMap);
    }
  }

  calculateNormalVariation() {
    const positions = this.mesh.geometry.attributes.position.array;
    const normals = this.mesh.geometry.attributes.normal.array;
    const totalVertices = positions.length / 3;

    for (let i = 0; i < totalVertices; i++) {
      const vertex = new THREE.Vector3(
        positions[i * 3],
        positions[i * 3 + 1],
        positions[i * 3 + 2]
      );
      const normal = new THREE.Vector3(
        normals[i * 3],
        normals[i * 3 + 1],
        normals[i * 3 + 2]
      );

      const key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
        15
      )}-${vertex.z.toFixed(15)}`;
      const neighbors = this.getNeighbors(vertex);

      let normalVariation = 0;
      neighbors.forEach((neighbor) => {
        const neighborKey = `${neighbor.x.toFixed(15)}-${neighbor.y.toFixed(
          15
        )}-${neighbor.z.toFixed(15)}`;
        const neighborNormal = this.vertexMap.get(neighborKey).normal;
        if (neighborNormal) {
          normalVariation += normal.angleTo(neighborNormal);
        }
      });

      //normalVariation /= neighbors.length;
      this.gradientMap.set(key, normalVariation);
    }
  }

  applyGradientMap() {
    const positions = this.mesh.geometry.attributes.position.array;
    const colors = new Float32Array(positions.length);
    const totalVertices = positions.length / 3;

    let minGradient = Infinity;
    let maxGradient = -Infinity;

    this.gradientMap.forEach((value) => {
      if (value < minGradient) minGradient = value;
      if (value > maxGradient) maxGradient = value;
    });

    for (let i = 0; i < totalVertices; i++) {
      const vertex = new THREE.Vector3(
        positions[i * 3],
        positions[i * 3 + 1],
        positions[i * 3 + 2]
      );
      const key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
        15
      )}-${vertex.z.toFixed(15)}`;

      const gradient = this.gradientMap.get(key) || 0;
      const normalizedGradient =
        (gradient - minGradient) / (maxGradient - minGradient);

      const color = new THREE.Color();
      color.setHSL(normalizedGradient, 1.0, 0.5); // Ajuste a escala de cor conforme necessário

      colors[i * 3] = color.r;
      colors[i * 3 + 1] = color.g;
      colors[i * 3 + 2] = color.b;
    }

    this.mesh.geometry.setAttribute(
      "color",
      new THREE.BufferAttribute(colors, 3)
    );
  }

  applyGradientMap_() {
    const positions = this.mesh.geometry.attributes.position.array;
    const colors = new Float32Array(positions.length);
    const totalVertices = positions.length / 3;

    let minGradient = Infinity;
    let maxGradient = -Infinity;

    this.gradientMap.forEach((value) => {
      if (value < minGradient) minGradient = value;
      if (value > maxGradient) maxGradient = value;
    });

    for (let i = 0; i < totalVertices; i++) {
      const vertex = new THREE.Vector3(
        positions[i * 3],
        positions[i * 3 + 1],
        positions[i * 3 + 2]
      );
      const key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
        15
      )}-${vertex.z.toFixed(15)}`;

      const gradient = this.gradientMap.get(key) || 0;
      const normalizedGradient =
        (gradient - minGradient) / (maxGradient - minGradient);

      const color = new THREE.Color();
      color.setHSL(normalizedGradient, 1.0, 0.5); // Ajuste a escala de cor conforme necessário

      colors[i * 3] = color.r;
      colors[i * 3 + 1] = color.g;
      colors[i * 3 + 2] = color.b;
    }

    this.mesh.geometry.setAttribute(
      "color",
      new THREE.BufferAttribute(colors, 3)
    );
  }

  applyGradientMap__() {
    const positions = this.mesh.geometry.attributes.position.array;
    const colors = new Float32Array(positions.length);
    const totalVertices = positions.length / 3;

    for (let i = 0; i < totalVertices; i++) {
      const vertex = new THREE.Vector3(
        positions[i * 3],
        positions[i * 3 + 1],
        positions[i * 3 + 2]
      );
      const key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
        15
      )}-${vertex.z.toFixed(15)}`;

      const gradient = this.gradientMap.get(key) || 0;
      const color = new THREE.Color();
      color.setHSL(gradient * (180 / Math.PI), 1.0, gradient); // Ajuste a escala de cor conforme necessário

      colors[i * 3] = color.r;
      colors[i * 3 + 1] = color.g;
      colors[i * 3 + 2] = color.b;
    }

    this.mesh.geometry.setAttribute(
      "color",
      new THREE.BufferAttribute(colors, 3)
    );
  }

  getNeighbors(vertex) {
    const key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
      15
    )}-${vertex.z.toFixed(15)}`;
    const neighborIndices = this.adjacencyMap.get(key) || [];

    const neighbors = neighborIndices.map((index) => {
      const positions = this.mesh.geometry.attributes.position.array;
      return new THREE.Vector3(
        positions[index * 3],
        positions[index * 3 + 1],
        positions[index * 3 + 2]
      );
    });

    return neighbors;
  }
}

class SpatialGrid {
  constructor(triangles, cellSize) {
    this.cellSize = cellSize;
    this.grid = new Map();

    // Distribua os triângulos na grade
    for (let triangle of triangles) {
      const cellKey = this._getCellKey(triangle.center);
      if (!this.grid.has(cellKey)) {
        this.grid.set(cellKey, []);
      }
      this.grid.get(cellKey).push(triangle);
    }
  }

  _getCellKey(position) {
    const x = Math.floor(position.x / this.cellSize);
    const y = Math.floor(position.y / this.cellSize);
    const z = Math.floor(position.z / this.cellSize);
    return `${x}-${y}-${z}`;
  }

  getTrianglesInRadius(center, radius) {
    const nearbyTriangles = [];
    const radiusInCells = Math.ceil(radius / this.cellSize);

    const cellX = Math.floor(center.x / this.cellSize);
    const cellY = Math.floor(center.y / this.cellSize);
    const cellZ = Math.floor(center.z / this.cellSize);

    for (let x = cellX - radiusInCells; x <= cellX + radiusInCells; x++) {
      for (let y = cellY - radiusInCells; y <= cellY + radiusInCells; y++) {
        for (let z = cellZ - radiusInCells; z <= cellZ + radiusInCells; z++) {
          const cellKey = `${x}-${y}-${z}`;
          const trianglesInCell = this.grid.get(cellKey) || [];
          for (let triangle of trianglesInCell) {
            if (center.distanceTo(triangle.center) <= radius) {
              nearbyTriangles.push(triangle);
            }
          }
        }
      }
    }

    return nearbyTriangles;
  }
}

// eslint-disable-next-line no-unused-vars
class GumSegmenter {
  constructor(mesh, scene, numOfClusters, justMeanCurvature = false) {
    this.startTime = performance.now();
    this.mesh = mesh;
    this.segmentedTriangles = new Set();
    this.borderTriangleIndices = new Set();
    this.visited = new Set();
    this.vertexMap = new Map();
    this.adjacencyMap = new Map();
    this.largestLoop = new Map();
    this.borderPointsCandidates = new Map();
    this.TriangleAdjacencyMap = new Set();
    this.scene = scene;
    this.numOfClusters = numOfClusters;

    this.candidateTriangles = this.getTriangles();
    console.log(
      `Filtering triangles time: ${(performance.now() - this.startTime).toFixed(
        2
      )} ms`
    );

    this.initializeVertexMap();
    console.log(
      `Initialize vertex map time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );

    this.buildAdjacencyMap();
    console.log(
      `Building adjacency map time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );

    this.calculateMeanCurvature(this.candidateTriangles);
    console.log(
      `Calculate mean curvature time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );

    // this.calculatePlaneSums(this.candidateTriangles);
    // console.log(
    //   `Calculate Plane Sums time: ${(
    //     performance.now() - this.startTime
    //   ).toFixed(2)} ms`
    // );

    this.clusterTriangles();
    console.log(
      `Cluster Triangles time: ${(performance.now() - this.startTime).toFixed(
        2
      )} ms`
    );

    if (justMeanCurvature) return;

    this.detectEdges();
    console.log(
      `Edge detection time: ${(performance.now() - this.startTime).toFixed(
        2
      )} ms`
    );

    this.expandFromEdges();
    console.log(
      `Expansion time: ${(performance.now() - this.startTime).toFixed(2)} ms`
    );

    this.fillInternalIslands();
    console.log(
      `Fill Internal Islands time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );
  }

  highlightGumTrianglesNearTeeth(gumTriangles, toothTriangles, maxDistance) {
    // maxDistance é o valor de X que você quer usar como critério de proximidade

    const positions = [];
    const vertexColors = [];

    // Cores para destacar os triângulos da gengiva próximos aos dentes
    const highlightColor = new THREE.Color(1, 0, 0); // Vermelho para destaque

    gumTriangles.forEach((gumTriangle) => {
      let isNearTooth = false;

      // Verifica a proximidade com cada triângulo de dente
      for (const toothTriangle of toothTriangles) {
        if (this.isNear(gumTriangle, toothTriangle, maxDistance)) {
          isNearTooth = true;
          break;
        }
      }

      if (isNearTooth) {
        // Destaca o triângulo da gengiva que está próximo de um dente
        for (const vertex of gumTriangle.vertices) {
          positions.push(vertex.x, vertex.y, vertex.z);
          vertexColors.push(
            highlightColor.r,
            highlightColor.g,
            highlightColor.b
          );
        }
      }
    });

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(positions, 3)
    );
    geometry.setAttribute(
      "color",
      new THREE.Float32BufferAttribute(vertexColors, 3)
    );

    geometry.computeVertexNormals();

    const material = new THREE.MeshBasicMaterial({
      vertexColors: true,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 1,
    });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.userData = this.mesh.userData;

    return mesh;
  }

  // Método para verificar a proximidade entre dois triângulos
  isNear(triangle1, triangle2, maxDistance) {
    for (const vertex1 of triangle1.vertices) {
      for (const vertex2 of triangle2.vertices) {
        const distance = vertex1.distanceTo(vertex2);
        if (distance <= maxDistance) {
          return true;
        }
      }
    }
    return false;
  }

  processBorderTriangles() {
    if (!this.borderTriangleIndices) return null;

    const data = [];
    const indices = [];

    for (const triangle of this.candidateTriangles) {
      if (this.borderTriangleIndices.has(triangle.index)) {
        data.push([triangle.area, triangle.curvature]); // Adiciona área e curvatura
        indices.push(triangle.index);
      }
    }

    const result = KMeans(data, this.numOfClusters);

    // Identificar o maior cluster (baixa curvatura) e os outros clusters (alta curvatura)
    const maxClusterIndex = result.centroids.reduce(
      (maxIndex, cluster, index, arr) =>
        cluster.size > arr[maxIndex].size ? index : maxIndex,
      0
    );

    const lowCurvatureClusters = [];
    const highCurvatureClusters = [];

    result.clusters.forEach((clusterIndex, dataIndex) => {
      const triangleIndex = indices[dataIndex];
      if (clusterIndex === maxClusterIndex) {
        lowCurvatureClusters.push(this.candidateTriangles[triangleIndex]);
      } else {
        highCurvatureClusters.push(this.candidateTriangles[triangleIndex]);
      }
    });

    // Função para criar uma mesh a partir dos clusters fornecidos
    const createMeshFromClusters = (clusters) => {
      const positions = [];
      const vertexColors = [];
      const color = new THREE.Color(
        Math.random(),
        Math.random(),
        Math.random()
      );

      clusters.forEach((triangle) => {
        if (triangle) {
          for (const vertex of triangle.vertices) {
            positions.push(vertex.x, vertex.y, vertex.z);
            vertexColors.push(color.r, color.g, color.b);
          }
        }
      });

      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(positions, 3)
      );
      geometry.setAttribute(
        "color",
        new THREE.Float32BufferAttribute(vertexColors, 3)
      );
      geometry.computeVertexNormals();

      const material = new THREE.MeshBasicMaterial({
        vertexColors: true,
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 1,
      });

      const mesh = new THREE.Mesh(geometry, material);
      mesh.userData = this.mesh.userData;

      return mesh;
    };

    const lowCurvatureMesh = createMeshFromClusters(lowCurvatureClusters);
    const highCurvatureMesh = createMeshFromClusters(highCurvatureClusters);

    return { lowCurvatureMesh, highCurvatureMesh };
  }

  clusterTriangles() {
    const data = [];
    const indices = [];

    for (const [, triangle] of this.candidateTriangles.entries()) {
      data.push([triangle.curvature]);
      indices.push(triangle.index);
    }

    const result = KMeans(data, this.numOfClusters + 1);
    this.kmeans = result;
    this.maxClusterIndex = result.centroids.reduce(
      (maxIndex, cluster, index, arr) =>
        cluster.size > arr[maxIndex].size ? index : maxIndex,
      0
    );
    this.centroidGumCurvature = result.centroids[this.maxClusterIndex].centroid;
    console.warn(`Mean Curvature ${this.centroidGumCurvature}`);
  }

  getTriangles() {
    function calculateTriangleArea(A, B, C) {
      const AB = {
        x: B.x - A.x,
        y: B.y - A.y,
        z: B.z - A.z,
      };
      const AC = {
        x: C.x - A.x,
        y: C.y - A.y,
        z: C.z - A.z,
      };
      const crossProduct = {
        x: AB.y * AC.z - AB.z * AC.y,
        y: AB.z * AC.x - AB.x * AC.z,
        z: AB.x * AC.y - AB.y * AC.x,
      };
      const area =
        0.5 *
        Math.sqrt(
          crossProduct.x * crossProduct.x +
            crossProduct.y * crossProduct.y +
            crossProduct.z * crossProduct.z
        );
      return area;
    }

    function determineDominantPlane(normal) {
      const absX = Math.abs(normal.x);
      const absY = Math.abs(normal.y);
      const absZ = Math.abs(normal.z);

      if (absX >= absY && absX >= absZ) return "x";
      if (absY >= absX && absY >= absZ) return "y";
      return "z"; // By process of elimination, this is for the case where absZ is the greatest
    }

    const candidateTriangles = [];
    const positions = this.mesh.geometry.attributes.position.array;
    const totalTriangles = positions.length / 9;

    for (let i = 0; i < totalTriangles; i++) {
      const baseIndex = i * 9;
      const vertices = [
        new THREE.Vector3(
          positions[baseIndex],
          positions[baseIndex + 1],
          positions[baseIndex + 2]
        ),
        new THREE.Vector3(
          positions[baseIndex + 3],
          positions[baseIndex + 4],
          positions[baseIndex + 5]
        ),
        new THREE.Vector3(
          positions[baseIndex + 6],
          positions[baseIndex + 7],
          positions[baseIndex + 8]
        ),
      ];
      const edge1 = vertices[1].clone().sub(vertices[0]);
      const edge2 = vertices[2].clone().sub(vertices[0]);
      const normal = new THREE.Vector3().crossVectors(edge1, edge2).normalize();
      const center = new THREE.Vector3()
        .add(vertices[0])
        .add(vertices[1])
        .add(vertices[2])
        .divideScalar(3);

      candidateTriangles.push({
        index: i,
        center,
        vertices,
        normal,
        area: calculateTriangleArea(vertices[0], vertices[1], vertices[2]),
        plane: determineDominantPlane(normal), // Adiciona a propriedade plane
      });
    }

    return candidateTriangles;
  }

  initializeVertexMap() {
    const positions = this.mesh.geometry.attributes.position.array;
    const totalTriangles = positions.length / 9;

    for (let i = 0; i < totalTriangles; i++) {
      const baseIndex = i * 9;
      for (let j = 0; j < 3; j++) {
        const x = positions[baseIndex + j * 3];
        const y = positions[baseIndex + j * 3 + 1];
        const z = positions[baseIndex + j * 3 + 2];
        const key = `${x.toFixed(15)}-${y.toFixed(15)}-${z.toFixed(15)}`;

        if (!this.vertexMap.has(key)) {
          this.vertexMap.set(key, []);
        }
        this.vertexMap.get(key).push(i);
      }
    }
  }

  buildAdjacencyMap() {
    for (let triangleIndex of this.vertexMap.values()) {
      for (let i = 0; i < triangleIndex.length; i++) {
        for (let j = i + 1; j < triangleIndex.length; j++) {
          const index1 = triangleIndex[i];
          const index2 = triangleIndex[j];

          if (!this.adjacencyMap.has(index1)) {
            this.adjacencyMap.set(index1, new Set());
          }
          this.adjacencyMap.get(index1).add(index2);

          if (!this.adjacencyMap.has(index2)) {
            this.adjacencyMap.set(index2, new Set());
          }
          this.adjacencyMap.get(index2).add(index1);
        }
      }
    }
  }

  calculateMeanCurvature(triangles) {
    const curvatureData = new Map();

    for (let triangle of triangles) {
      const neighbors = this.adjacencyMap.get(triangle.index);
      if (!neighbors) continue;

      let curvatureSum = 0;
      let minCurvature = Infinity;
      let maxCurvature = -Infinity;

      for (let neighborIndex of neighbors) {
        const neighborTriangle = triangles[neighborIndex];
        const angle = triangle.normal.angleTo(neighborTriangle.normal);
        curvatureSum += angle;

        if (angle < minCurvature) {
          minCurvature = angle;
        }
        if (angle > maxCurvature) {
          maxCurvature = angle;
        }
      }

      const meanCurvature = curvatureSum / neighbors.size;
      curvatureData.set(triangle.index, {
        mean: meanCurvature,
        min: minCurvature,
        max: maxCurvature,
      });
      triangle.curvature = meanCurvature;
      triangle.curvatureMin = minCurvature;
      triangle.curvatureMax = maxCurvature;
    }

    return curvatureData;
  }

  calculateMeanCurvaturev2(triangles) {
    const curvatureData = new Map();

    function getAllNeighbors(triangleIndex, depth) {
      const visited = new Set();
      const queue = [{ index: triangleIndex, level: 0 }];
      const allNeighbors = new Set();

      while (queue.length > 0) {
        const { index, level } = queue.shift();
        if (level > depth) continue;

        if (!visited.has(index)) {
          visited.add(index);

          const neighbors = this.adjacencyMap.get(index) || [];
          for (const neighbor of neighbors) {
            if (!visited.has(neighbor)) {
              allNeighbors.add(neighbor);
              queue.push({ index: neighbor, level: level + 1 });
            }
          }
        }
      }

      return Array.from(allNeighbors);
    }

    for (let triangle of triangles) {
      const neighbors = getAllNeighbors.call(this, triangle.index, 2);
      if (!neighbors) continue;

      let curvatureSum = 0;
      let minCurvature = Infinity;
      let maxCurvature = -Infinity;

      for (let neighborIndex of neighbors) {
        const neighborTriangle = triangles[neighborIndex];
        const angle = triangle.normal.angleTo(neighborTriangle.normal);
        curvatureSum += angle;

        if (angle < minCurvature) {
          minCurvature = angle;
        }
        if (angle > maxCurvature) {
          maxCurvature = angle;
        }
      }

      const meanCurvature = curvatureSum / neighbors.length;
      curvatureData.set(triangle.index, {
        mean: meanCurvature,
        min: minCurvature,
        max: maxCurvature,
      });
      triangle.curvature = meanCurvature;
      triangle.curvatureMin = minCurvature;
      triangle.curvatureMax = maxCurvature;
    }

    console.table(curvatureData);
    return curvatureData;
  }

  calculatePlaneSums(triangles) {
    const planeSums = new Map();

    function getAllNeighbors(triangleIndex, depth) {
      const visited = new Set();
      const queue = [{ index: triangleIndex, level: 0 }];
      const allNeighbors = new Set();

      while (queue.length > 0) {
        const { index, level } = queue.shift();
        if (level > depth) continue;

        if (!visited.has(index)) {
          visited.add(index);

          const neighbors = this.adjacencyMap.get(index) || [];
          for (const neighbor of neighbors) {
            if (!visited.has(neighbor)) {
              allNeighbors.add(neighbor);
              queue.push({ index: neighbor, level: level + 1 });
            }
          }
        }
      }

      return Array.from(allNeighbors);
    }

    for (let triangle of triangles) {
      const neighbors = getAllNeighbors.call(this, triangle.index, 2);
      const planeCount = { x: 0, y: 0, z: 0 };

      for (let neighborIndex of neighbors) {
        const neighborTriangle = triangles[neighborIndex];
        const plane = neighborTriangle.plane;
        if (plane) {
          planeCount[plane]++;
        }
      }

      // Determine the plane with the highest count
      const maxPlane = Object.keys(planeCount).reduce((max, key) =>
        planeCount[key] > planeCount[max] ? key : max
      );

      // Check if the predominant plane differs from the triangle's plane
      const planeChange = triangle.plane !== maxPlane;

      planeSums.set(triangle.index, { ...planeCount, planeChange });
      triangle.nearPlanes = planeCount;
      triangle.planeChange = planeChange;
    }

    console.table(planeSums);
    return planeSums;
  }

  calculatePlanarVariation(triangles, radius = 1) {
    const planeVariationData = new Map();

    const grid = new SpatialGrid(triangles, 10); // cellSize de 10 unidades

    for (let triangle of triangles) {
      const regionTriangles = grid.getTrianglesInRadius(
        triangle.center,
        radius
      );

      const averageNormal = new THREE.Vector3();
      regionTriangles.forEach((tr) => averageNormal.add(tr.normal));
      averageNormal.divideScalar(regionTriangles.length).normalize();

      const deviationSum = regionTriangles.reduce((sum, tr) => {
        const deviation = tr.normal.angleTo(averageNormal);
        return sum + deviation;
      }, 0);

      const planarVariation = deviationSum / regionTriangles.length;
      planeVariationData.set(triangle.index, planarVariation);
    }
    console.table(planeVariationData);

    return planeVariationData;
  }

  updateDentalMapIndices(originalMesh, teethMesh, dentalMap, globalDentalMap) {
    console.log(
      `Rebuild dentalMap Start time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );

    // Obter os triângulos dos meshes
    const originalTriangles = this.getTriangles.call({ mesh: originalMesh });
    const teethTriangles = this.getTriangles.call({ mesh: teethMesh });

    // Criar um mapa de vértices para triângulos originais
    const vertexToOriginalIndex = new Map();
    originalTriangles.forEach((triangle) => {
      triangle.vertices.forEach((vertex) => {
        const key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
          15
        )}-${vertex.z.toFixed(15)}`;
        if (!vertexToOriginalIndex.has(key)) {
          vertexToOriginalIndex.set(key, []);
        }
        vertexToOriginalIndex.get(key).push(triangle.index);
      });
    });

    // Criar um mapa para correspondência de índices de dentes para índices originais
    const teethToOriginalIndex = new Map();
    teethTriangles.forEach((teethTriangle) => {
      const potentialIndices = new Map();

      teethTriangle.vertices.forEach((vertex) => {
        const key = `${vertex.x.toFixed(15)}-${vertex.y.toFixed(
          15
        )}-${vertex.z.toFixed(15)}`;
        if (vertexToOriginalIndex.has(key)) {
          vertexToOriginalIndex.get(key).forEach((index) => {
            if (!potentialIndices.has(index)) {
              potentialIndices.set(index, 0);
            }
            potentialIndices.set(index, potentialIndices.get(index) + 1);
          });
        }
      });

      const originalIndex = Array.from(potentialIndices.entries()).reduce(
        (bestMatch, [index, count]) => {
          if (count > bestMatch.count) {
            return { index, count };
          }
          return bestMatch;
        },
        { index: undefined, count: 0 }
      ).index;

      if (originalIndex !== undefined) {
        teethToOriginalIndex.set(teethTriangle.index, originalIndex);
      }
    });

    // Atualizar o dentalMap com os novos índices
    const updatedDentalMap = {};

    for (const toothNumber in dentalMap) {
      // eslint-disable-next-line no-prototype-builtins
      if (dentalMap.hasOwnProperty(toothNumber)) {
        const { segmentedTriangleIndices } = dentalMap[toothNumber];
        updatedDentalMap[toothNumber] = {
          ...dentalMap[toothNumber],
          segmentedTriangleIndices: segmentedTriangleIndices
            .map((teethIndex) => teethToOriginalIndex.get(teethIndex))
            .filter((index) => index !== undefined),
        };
      }
    }

    for (const toothNumber in globalDentalMap) {
      const { borderPoints } = globalDentalMap[toothNumber];
      if (borderPoints.length !== 0)
        updatedDentalMap[toothNumber] = globalDentalMap[toothNumber];
    }

    // Atualizar o dentalMap com os novos índices
    // const updatedDentalMap = {};
    // for (const toothNumber in dentalMap) {
    //   // eslint-disable-next-line no-prototype-builtins
    //   if (dentalMap.hasOwnProperty(toothNumber)) {
    //     const { segmentedTriangleIndices, borderPoints } = dentalMap[toothNumber];

    //     console.warn(`${toothNumber}`)
    //     console.table(dentalMap[toothNumber])

    //     // Se borderPoints já existir, mantém todos os dados existentes da chave
    //     if (borderPoints.length !== 0) {
    //       updatedDentalMap[toothNumber] = dentalMap[toothNumber];
    //     } else {
    //       // Atualiza somente se não houver borderPoints
    //       updatedDentalMap[toothNumber] = {
    //         ...dentalMap[toothNumber],
    //         segmentedTriangleIndices: segmentedTriangleIndices
    //           .map((teethIndex) => teethToOriginalIndex.get(teethIndex))
    //           .filter((index) => index !== undefined),
    //       };
    //     }
    //     console.table(updatedDentalMap[toothNumber])
    //   }
    // }

    console.log(
      `Rebuild dentalMap End time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );

    return updatedDentalMap;
  }

  getReducedBoundingBox(mesh, reductionFactor) {
    const boundingBox = new THREE.Box3().setFromObject(mesh);
    const size = boundingBox.getSize(new THREE.Vector3());
    const center = boundingBox.getCenter(new THREE.Vector3());

    const reducedSize = size.multiplyScalar(1 - reductionFactor);

    const min = center.clone().sub(reducedSize.clone().multiplyScalar(0.5));
    const max = center.clone().add(reducedSize.clone().multiplyScalar(0.5));

    return new THREE.Box3(min, max);
  }

  detectEdges() {
    const reductionFactor = 0.0;
    const reducedBoundingBox = this.getReducedBoundingBox(
      this.mesh,
      reductionFactor
    );

    this.edgeGeometry = new THREE.EdgesGeometry(this.mesh.geometry, 90);
    this.edgePoints = new Set();

    for (
      let i = 0;
      i < this.edgeGeometry.attributes.position.array.length;
      i += 3
    ) {
      const x = this.edgeGeometry.attributes.position.array[i];
      const y = this.edgeGeometry.attributes.position.array[i + 1];
      const z = this.edgeGeometry.attributes.position.array[i + 2];
      const point = new THREE.Vector3(x, y, z);

      if (reducedBoundingBox.containsPoint(point)) {
        const key = `${x.toFixed(15)}-${y.toFixed(15)}-${z.toFixed(15)}`;
        this.edgePoints.add(key);
      }
    }
  }

  fillInternalIslands() {
    const unmarkedTriangles = [];
    const triangleLabels = new Map();

    // Coletar triângulos não gengiva
    this.candidateTriangles.forEach((triangle, index) => {
      if (!this.segmentedTriangles.has(index)) {
        unmarkedTriangles.push(index);
      }
    });

    console.log(`Unmarked Triangles: ${unmarkedTriangles.length}`);

    let label = 1;

    // Agrupar triângulos por conexão
    unmarkedTriangles.forEach((index) => {
      if (!triangleLabels.has(index)) {
        this.labelConnectedTriangles(index, label, triangleLabels);
        label++;
      }
    });

    // Contar triângulos por grupo
    const labelCounts = new Map();
    triangleLabels.forEach((l) => {
      labelCounts.set(l, (labelCounts.get(l) || 0) + 1);
    });

    // Encontrar o maior grupo
    const maxLabel = [...labelCounts.entries()].reduce((a, b) =>
      b[1] > a[1] ? b : a
    )[0];

    console.log(`Max Label: ${maxLabel}`);

    // Criar uma lista dos grupos ordenada por tamanho, em ordem decrescente
    const sortedGroups = [...labelCounts.entries()].sort((a, b) => b[1] - a[1]);

    // Selecionar o segundo maior grupo, se houver mais de um grupo
    const secondLargestLabel =
      sortedGroups.length > 1 ? sortedGroups[2][0] : sortedGroups[0][0];
    console.log(`Second Largest Label: ${secondLargestLabel}`);

    // Marcar triângulos fora do maior grupo como gengiva
    triangleLabels.forEach((l, index) => {
      if (l !== maxLabel) {
        this.segmentedTriangles.add(index);
        this.borderTriangleIndices.delete(index);
      } else {
        this.borderTriangleIndices.add(index);
      }
    });

    console.log(`Segmented Triangles: ${this.segmentedTriangles.size}`);
  }

  labelConnectedTriangles(startIndex, label, triangleLabels) {
    const stack = [startIndex];

    while (stack.length > 0) {
      const currentIndex = stack.pop();

      if (!triangleLabels.has(currentIndex)) {
        triangleLabels.set(currentIndex, label);
        const neighbors = this.adjacencyMap.get(currentIndex) || [];

        neighbors.forEach((neighbor) => {
          if (
            !triangleLabels.has(neighbor) &&
            !this.borderTriangleIndices.has(neighbor)
          ) {
            stack.push(neighbor);
          }
        });
      }
    }
  }

  expandFromEdges() {
    this.edgePoints.forEach((edgePoint) => {
      const triangles = this.vertexMap.get(edgePoint);
      if (triangles) {
        triangles.forEach((triangleIndex) => {
          this.expandFromFirst(triangleIndex);
        });
      }
    });
  }

  expandFromFirst(firstTriangle) {
    let queue = [firstTriangle];

    while (queue.length > 0) {
      const current = queue.shift();

      if (this.visited.has(current)) continue;
      this.visited.add(current);

      const currentTriangle = this.candidateTriangles[current];
      if (currentTriangle.curvature < this.centroidGumCurvature[0] * 1.2) {
        this.segmentedTriangles.add(current);

        const neighbors = this.adjacencyMap.get(current) || [];
        neighbors.forEach((neighbor) => {
          if (!this.visited.has(neighbor)) {
            queue.push(neighbor);
          }
        });
      } else {
        this.borderTriangleIndices.add(current);
      }
    }
  }
}

// eslint-disable-next-line no-unused-vars
class MeshSegmenterMiniGun {
  constructor(mesh, point, params, scene) {
    this.startTime = performance.now();
    this.mesh = mesh;
    this.segmentedTriangles = new Set();
    this.borderTriangleIndices = new Set();
    this.visited = new Set();
    this.params = params;
    this.vertexMap = new Map();
    this.adjacencyMap = new Map();
    this.largestLoop = new Map();
    this.borderPointsCandidates = new Map();
    this.centerPoint = point;
    this.TriangleAdjacencyMap = new Set();
    this.scene = scene;

    this.candidateTriangles = this.getTriangles();
    console.log(
      `Filtering triangles time: ${(performance.now() - this.startTime).toFixed(
        2
      )} ms`
    );

    this.firstTriangle = this.findTriangleIndexByPosition(
      point.x,
      point.y,
      point.z
    );
    console.log(
      `First Triangle time: ${(performance.now() - this.startTime).toFixed(
        2
      )} ms`
    );

    this.initializeVertexMap();
    console.log(
      `Initialize vertex map time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );

    this.buildAdjacencyMap();
    console.log(
      `Building adjacency map time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );
  }

  findTriangleIndexByPosition(x, y, z) {
    const point = new THREE.Vector3(x, y, z);
    let closestTriangleIndex = -1;
    let closestDistance = Infinity;

    this.candidateTriangles.forEach((candidate) => {
      const distance = point.distanceTo(candidate.center);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestTriangleIndex = candidate.index;
      }
    });

    return closestTriangleIndex;
  }

  getTriangles() {
    function calculateTriangleArea(A, B, C) {
      // Vetores AB e AC
      const AB = {
        x: B.x - A.x,
        y: B.y - A.y,
        z: B.z - A.z,
      };

      const AC = {
        x: C.x - A.x,
        y: C.y - A.y,
        z: C.z - A.z,
      };

      // Produto vetorial AB x AC
      const crossProduct = {
        x: AB.y * AC.z - AB.z * AC.y,
        y: AB.z * AC.x - AB.x * AC.z,
        z: AB.x * AC.y - AB.y * AC.x,
      };

      // Norma do vetor resultante do produto vetorial
      const area =
        0.5 *
        Math.sqrt(
          crossProduct.x * crossProduct.x +
            crossProduct.y * crossProduct.y +
            crossProduct.z * crossProduct.z
        );

      return area;
    }
    const candidateTriangles = [];
    const positions = this.mesh.geometry.attributes.position.array;
    const totalTriangles = positions.length / 9; // Cada triângulo tem 3 vértices, cada vértice tem 3 coordenadas (x, y, z)

    for (let i = 0; i < totalTriangles; i++) {
      // Calcula o índice base no buffer para o triângulo atual
      const baseIndex = i * 9;
      // Extrai os vértices do triângulo
      const vertices = [
        new THREE.Vector3(
          positions[baseIndex],
          positions[baseIndex + 1],
          positions[baseIndex + 2]
        ),
        new THREE.Vector3(
          positions[baseIndex + 3],
          positions[baseIndex + 4],
          positions[baseIndex + 5]
        ),
        new THREE.Vector3(
          positions[baseIndex + 6],
          positions[baseIndex + 7],
          positions[baseIndex + 8]
        ),
      ];

      // Calcula as arestas do triângulo
      const edge1 = vertices[1].clone().sub(vertices[0]);
      const edge2 = vertices[2].clone().sub(vertices[0]);
      // Calcula a normal do triângulo usando o produto vetorial
      const normal = new THREE.Vector3().crossVectors(edge1, edge2).normalize();

      // Calcula o centro usando os vértices
      const center = new THREE.Vector3()
        .add(vertices[0])
        .add(vertices[1])
        .add(vertices[2])
        .divideScalar(3);

      candidateTriangles.push({
        index: i,
        center,
        vertices,
        normal,
        area: calculateTriangleArea(vertices[0], vertices[1], vertices[2]),
      }); // Adiciona a normal ao objeto do triângulo
    }

    return candidateTriangles;
  }

  getTrianglesWithinRadius(triangleIndex, radius) {
    // Primeiro, obtenha todos os triângulos disponíveis
    const allTriangles = this.candidateTriangles;

    // Encontra o triângulo com o índice fornecido
    const referenceTriangle = allTriangles.find(
      (tri) => tri.index === triangleIndex
    );
    if (!referenceTriangle) {
      console.error("Triângulo não encontrado com índice:", triangleIndex);
      return [];
    }

    // Lista para guardar os triângulos dentro do raio especificado
    const trianglesWithinRadius = [];

    // Verifica cada triângulo para ver se está dentro do raio
    allTriangles.forEach((triangle) => {
      const distance = referenceTriangle.center.distanceTo(triangle.center);
      if (distance <= radius) {
        trianglesWithinRadius.push(triangle);
        triangle.isBorder = this.borderTriangleIndices.has(triangle.index);
      }
    });

    const geometry = new THREE.SphereGeometry(radius, 32, 32);
    const material = new THREE.MeshBasicMaterial({
      color: 0x0000ff,
      transparent: true,
      opacity: 0.25,
    });
    const sphere = new THREE.Mesh(geometry, material);
    sphere.position.copy(referenceTriangle.center);
    this.scene.add(sphere);

    return trianglesWithinRadius;
  }

  generateSeparatedPoints(radius = 0.25) {
    const grid = new Map();

    for (const triangleIndex of this.borderTriangleIndices) {
      const triangle = this.candidateTriangles[triangleIndex];
      const { center } = triangle;

      const gridKey = this.getGridKey(center, radius);

      if (!grid.has(gridKey)) {
        grid.set(gridKey, []);
      }

      grid.get(gridKey).push(center);
    }

    const separatedPoints = [];

    for (const points of grid.values()) {
      const centroid = this.calculateCentroid(points);
      separatedPoints.push(centroid);
    }

    return separatedPoints;
  }

  getGridKey(point, radius) {
    const x = Math.floor(point.x / radius);
    const y = Math.floor(point.y / 0, 1);
    const z = Math.floor(point.z / radius);
    return `${x},${y},${z}`;
  }

  calculateCentroid(points) {
    const centroid = new THREE.Vector3(0, 0, 0);

    for (const point of points) {
      centroid.add(point);
    }

    centroid.divideScalar(points.length);

    return centroid;
  }

  initializeVertexMap() {
    const positions = this.mesh.geometry.attributes.position.array;
    const totalTriangles = positions.length / 9; // Cada triângulo tem 3 vértices

    for (let i = 0; i < totalTriangles; i++) {
      const baseIndex = i * 9;
      for (let j = 0; j < 3; j++) {
        // Loop para cada vértice do triângulo
        const x = positions[baseIndex + j * 3];
        const y = positions[baseIndex + j * 3 + 1];
        const z = positions[baseIndex + j * 3 + 2];
        const key = `${x.toFixed(15)}-${y.toFixed(15)}-${z.toFixed(15)}`; // Reduzindo a precisão para evitar erros de ponto flutuante

        if (!this.vertexMap.has(key)) {
          this.vertexMap.set(key, []);
        }
        this.vertexMap.get(key).push(i);
      }
    }
  }

  buildAdjacencyMap() {
    for (let triangleIndex of this.vertexMap.values()) {
      for (let i = 0; i < triangleIndex.length; i++) {
        for (let j = i + 1; j < triangleIndex.length; j++) {
          const index1 = triangleIndex[i];
          const index2 = triangleIndex[j];

          if (!this.adjacencyMap.has(index1)) {
            this.adjacencyMap.set(index1, new Set());
          }
          this.adjacencyMap.get(index1).add(index2);

          if (!this.adjacencyMap.has(index2)) {
            this.adjacencyMap.set(index2, new Set());
          }
          this.adjacencyMap.get(index2).add(index1);
        }
      }
    }
  }

  calculateMeanCurvature(triangles) {
    const curvatureData = new Map();

    for (let triangle of triangles) {
      const neighbors = this.adjacencyMap.get(triangle.index);
      if (!neighbors) continue;

      let curvatureSum = 0;
      let minCurvature = Infinity; // Inicializa como infinito para encontrar o mínimo
      let maxCurvature = -Infinity; // Inicializa como negativo infinito para encontrar o máximo

      for (let neighborIndex of neighbors) {
        const neighborTriangle = triangles[neighborIndex];
        const angle = triangle.normal.angleTo(neighborTriangle.normal);
        curvatureSum += angle;

        // Atualiza os valores máximo e mínimo
        if (angle < minCurvature) {
          minCurvature = angle;
        }
        if (angle > maxCurvature) {
          maxCurvature = angle;
        }
      }

      // Calcula a média da curvatura
      const meanCurvature = curvatureSum / neighbors.size;
      curvatureData.set(triangle.index, {
        mean: meanCurvature,
        min: minCurvature,
        max: maxCurvature,
      });
      triangle.curvature = meanCurvature; // Armazena a curvatura média
      triangle.curvatureMin = minCurvature; // Armazena a curvatura mínima
      triangle.curvatureMax = maxCurvature; // Armazena a curvatura máxima
    }

    return curvatureData;
  }

  findHighCurvatureTriangles(
    triangles,
    curvatures,
    threshold,
    radius,
    centerPoint
  ) {
    return triangles.filter((triangle) => {
      const curvature = curvatures.get(triangle.index);
      const distance = triangle.center.distanceTo(centerPoint);

      return curvature && curvature.max > threshold && distance <= radius;
    });
  }

  filterIsolatedTriangles(triangles, minNeighbors = 1) {
    return triangles.filter((triangle) => {
      const neighbors = this.adjacencyMap.get(triangle.index);
      return neighbors && neighbors.size >= minNeighbors;
    });
  }

  getHighCurvatureTriangleIndices(triangles, threshold, radius, centerPoint) {
    const curvatures = this.calculateMeanCurvature(triangles);

    const highCurvatureTriangles = this.findHighCurvatureTriangles(
      triangles,
      curvatures,
      threshold,
      radius,
      centerPoint
    );
    const filteredTriangles = this.filterIsolatedTriangles(
      highCurvatureTriangles
    );
    return filteredTriangles;
  }

  createGrid(n) {
    const grid = [];
    for (let i = 0; i < n; i++) {
      grid[i] = [];
      for (let j = 0; j < n; j++) {
        grid[i][j] = [];
        for (let k = 0; k < n; k++) {
          grid[i][j][k] = {
            triangles: [],
            areaSum: 0,
            triangleCount: 0,
          };
        }
      }
    }
    return grid;
  }

  calculateBounds(triangles) {
    const bounds = {
      min: { x: Infinity, y: Infinity, z: Infinity },
      max: { x: -Infinity, y: -Infinity, z: -Infinity },
    };

    for (const triangle of triangles) {
      for (const vertex of triangle.vertices) {
        if (vertex.x < bounds.min.x) bounds.min.x = vertex.x;
        if (vertex.y < bounds.min.y) bounds.min.y = vertex.y;
        if (vertex.z < bounds.min.z) bounds.min.z = vertex.z;
        if (vertex.x > bounds.max.x) bounds.max.x = vertex.x;
        if (vertex.y > bounds.max.y) bounds.max.y = vertex.y;
        if (vertex.z > bounds.max.z) bounds.max.z = vertex.z;
      }
    }

    return bounds;
  }

  assignTrianglesToGrid(triangles, grid, bounds, n) {
    const cellSize = {
      x: (bounds.max.x - bounds.min.x) / n,
      y: (bounds.max.y - bounds.min.y) / n,
      z: (bounds.max.z - bounds.min.z) / n,
    };

    for (const triangle of triangles) {
      const i = Math.floor((triangle.center.x - bounds.min.x) / cellSize.x);
      const j = Math.floor((triangle.center.y - bounds.min.y) / cellSize.y);
      const k = Math.floor((triangle.center.z - bounds.min.z) / cellSize.z);

      grid[i][j][k].triangles.push(triangle);
    }
  }

  calculateAreaPerRadius(grid, n, radius, bounds) {
    const radiusSquared = radius * radius;

    for (let i = 0; i < n; i++) {
      for (let j = 0; j < n; j++) {
        for (let k = 0; k < n; k++) {
          const cell = grid[i][j][k];
          const cellCenter = {
            x: ((i + 0.5) * (bounds.max.x - bounds.min.x)) / n + bounds.min.x,
            y: ((j + 0.5) * (bounds.max.y - bounds.min.y)) / n + bounds.min.y,
            z: ((k + 0.5) * (bounds.max.z - bounds.min.z)) / n + bounds.min.z,
          };

          for (
            let ii = Math.max(0, i - radius);
            ii <= Math.min(n - 1, i + radius);
            ii++
          ) {
            for (
              let jj = Math.max(0, j - radius);
              jj <= Math.min(n - 1, j + radius);
              jj++
            ) {
              for (
                let kk = Math.max(0, k - radius);
                kk <= Math.min(n - 1, k + radius);
                kk++
              ) {
                const neighborCell = grid[ii][jj][kk];

                for (const triangle of neighborCell.triangles) {
                  const distanceSquared =
                    (triangle.center.x - cellCenter.x) *
                      (triangle.center.x - cellCenter.x) +
                    (triangle.center.y - cellCenter.y) *
                      (triangle.center.y - cellCenter.y) +
                    (triangle.center.z - cellCenter.z) *
                      (triangle.center.z - cellCenter.z);

                  if (distanceSquared <= radiusSquared) {
                    cell.areaSum += triangle.area;
                    cell.triangleCount += 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  gridPositionToVector(i, j, k, bounds, n) {
    const cellSize = {
      x: (bounds.max.x - bounds.min.x) / n,
      y: (bounds.max.y - bounds.min.y) / n,
      z: (bounds.max.z - bounds.min.z) / n,
    };

    const position = {
      x: (i + 0.5) * cellSize.x + bounds.min.x,
      y: (j + 0.5) * cellSize.y + bounds.min.y,
      z: (k + 0.5) * cellSize.z + bounds.min.z,
    };

    return position;
  }

  getTrianglesInRange(dataTooth, range) {
    const borderInRange = [];

    // Função para calcular a menor distância entre o centro de um triângulo e um conjunto de pontos
    function isInRange(triangleCenter, points, range) {
      for (const point of points) {
        const vector = new THREE.Vector3(point[0], point[1], point[2]);
        if (triangleCenter.distanceTo(vector) <= range) {
          return true;
        }
      }
      return false;
    }

    // Percorre os índices dos triângulos de borda
    for (const index of this.borderTriangleIndices) {
      const triangle = this.candidateTriangles.find((t) => t.index === index);
      if (triangle && isInRange(triangle.center, dataTooth, range)) {
        borderInRange.push(index);
      }
    }

    return borderInRange;
  }

  clusterTriangles(triangles, numberOfClusters = 5) {
    const data = [];
    const indices = [];

    for (const [, triangle] of triangles.entries()) {
      data.push([triangle.curvature]);
      indices.push(triangle.index);
    }

    const result = KMeans(data, numberOfClusters);

    const n = 40;
    const grid = this.createGrid(n);
    const bounds = this.calculateBounds(triangles);
    this.assignTrianglesToGrid(triangles, grid, bounds, n);
    this.calculateAreaPerRadius(grid, n, 6, bounds);

    const clusters = Array.from({ length: numberOfClusters }, () => []);

    result.clusters.forEach((clusterIndex, dataIndex) => {
      const triangleIndex = indices[dataIndex];
      clusters[clusterIndex].push(triangles[triangleIndex]);
    });

    // Gerar um array de cores aleatórias para os clusters
    const colors = Array.from(
      { length: numberOfClusters },
      () => new THREE.Color(Math.random(), Math.random(), Math.random())
    );

    // Cria um mapa de índice para triângulos para acesso rápido
    const triangleMap = new Map();
    for (const triangle of triangles.values()) {
      triangleMap.set(triangle.index, triangle);
    }

    // Adicionar cores aos vértices com base nos clusters
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const vertexColors = [];

    clusters.forEach((clusterIndices, clusterIndex) => {
      const color = colors[clusterIndex];

      clusterIndices.forEach((triangleIndex) => {
        if (triangleIndex) {
          for (const vertex of triangleIndex.vertices) {
            positions.push(vertex.x, vertex.y, vertex.z);
            vertexColors.push(color.r, color.g, color.b);
          }
        }
      });
    });

    geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(positions, 3)
    );
    geometry.setAttribute(
      "color",
      new THREE.Float32BufferAttribute(vertexColors, 3)
    );

    const material = new THREE.MeshBasicMaterial({
      vertexColors: true,
      side: THREE.DoubleSide,
    });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.userData.name = "Search Geometry";

    const dataTooth = [];
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < n; j++) {
        for (let k = 0; k < n; k++) {
          const cell = grid[i][j][k];
          if (cell.triangleCount > 1000 && cell.areaSum > 130) {
            // Ou qualquer outro critério de relevância
            const position = this.gridPositionToVector(i, j, k, bounds, n);
            // Visualize a posição na cena, por exemplo, adicionando uma esfera no Three.js
            const sphereGeometry = new THREE.SphereGeometry(0.5, 8, 8);
            const sphereMaterial = new THREE.MeshBasicMaterial({
              color: 0x000000,
              transparent: true,
              opacity: 1,
            });
            const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
            sphere.position.set(position.x, position.y, position.z);
            mesh.add(sphere);
            dataTooth.push([position.x, position.y, position.z]);
          }
        }
      }
    }

    const resultTooth = KMeans(dataTooth, 20);
    const toothPoints = [];
    resultTooth.centroids.forEach((point) => {
      const sphereGeometry = new THREE.SphereGeometry(0.5, 32, 32);
      const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0x0044ff });
      const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
      sphere.position.set(
        point.centroid[0],
        point.centroid[1],
        point.centroid[2]
      );
      toothPoints.push([
        point.centroid[0],
        point.centroid[1],
        point.centroid[2],
      ]);
      mesh.add(sphere);
    });

    return {
      mesh,
      clusters,
      centroids: result.centroids,
      result,
      resultTooth,
      dataTooth,
      toothPoints,
    };
  }

  addDistanceAndAnglesToTriangles(centerVector, triangles) {
    // Função para calcular a distância euclidiana entre dois vetores
    const calculateDistance = (v1, v2) => {
      return Math.sqrt(
        Math.pow(v2.x - v1.x, 2) +
          Math.pow(v2.y - v1.y, 2) +
          Math.pow(v2.z - v1.z, 2)
      );
    };

    // Função para calcular os ângulos em relação aos eixos x, y e z
    const calculateAngles = (v1, v2) => {
      const deltaX = v2.x - v1.x;
      const deltaY = v2.y - v1.y;
      const deltaZ = v2.z - v1.z;

      const distance = calculateDistance(v1, v2);

      const angleX = Math.acos(deltaX / distance) * (180 / Math.PI);
      const angleY = Math.acos(deltaY / distance) * (180 / Math.PI);
      const angleZ = Math.acos(deltaZ / distance) * (180 / Math.PI);

      return { angleX, angleY, angleZ };
    };

    const calculateProjectionAngles = (v1, v2) => {
      const deltaX = v2.x - v1.x;
      const deltaY = v2.y - v1.y;
      const deltaZ = v2.z - v1.z;

      const angleXY = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
      const angleXZ = Math.atan2(deltaZ, deltaX) * (180 / Math.PI);

      return { angleXY, angleXZ };
    };

    for (const triangle of triangles) {
      const distance = calculateDistance(centerVector, triangle.center);
      const { angleX, angleY, angleZ } = calculateAngles(
        centerVector,
        triangle.center
      );
      const { angleXY, angleXZ } = calculateProjectionAngles(
        centerVector,
        triangle.center
      );

      // Adicionando as propriedades ao objeto triangle
      triangle.distance = distance;
      triangle.angles = { angleX, angleY, angleZ, angleXY, angleXZ };
    }
  }

  segment() {
    if (this.firstTriangle !== null) {
      const highCurvatureTriangles = this.getHighCurvatureTriangleIndices(
        this.candidateTriangles,
        0.5,
        10,
        this.centerPoint
      );
      this.borderTriangleIndices = new Set(
        highCurvatureTriangles.map((triangle) => triangle.index)
      );
      console.log(
        `Find High Curvature time: ${(
          performance.now() - this.startTime
        ).toFixed(2)} ms`
      );

      // this.calculateMeanCurvature(this.candidateTriangles);
      // console.log(
      //   `Calculate Mean Curvature time: ${(
      //     performance.now() - this.startTime
      //   ).toFixed(2)} ms`
      // );

      // //const partialTriangles = this.getTrianglesWithinRadius(this.firstTriangle, 12)
      // const clusters = this.clusterTriangles(this.candidateTriangles, 3);
      // console.log(
      //   `Clusters Triangles time: ${(
      //     performance.now() - this.startTime
      //   ).toFixed(2)} ms`
      // );

      // console.table(clusters);

      // const toothCenter = new THREE.Vector3(
      //   clusters.resultTooth.centroids[0].centroid[0],
      //   clusters.resultTooth.centroids[0].centroid[1],
      //   clusters.resultTooth.centroids[0].centroid[2]
      // )
      //this.addDistanceAndAnglesToTriangles(this.centerPoint, this.candidateTriangles)

      // Encontrar o maior cluster
      // const maxClusterIndex = clusters.centroids.reduce(
      //   (maxIndex, cluster, index, arr) =>
      //     cluster.size > arr[maxIndex].size ? index : maxIndex,
      //   0
      // );

      // this.borderTriangles = new Set();

      // // Criar o Set com os índices dos clusters menores
      // this.borderTriangleIndices = new Set();
      // this.minCurvature = Infinity;
      // clusters.clusters.forEach((cluster, index) => {
      //   if (index !== maxClusterIndex) {
      //     cluster.forEach((value) => {
      //       //if (clusters.borderinRange.has(value.index))
      //       this.borderTriangleIndices.add(value.index);
      //       this.minCurvature = Math.min(this.minCurvature, value.curvature);
      //       // if (value.distance<15) {
      //       //    this.borderTriangles.add(value);
      //       //    const sphereGeometry = new THREE.SphereGeometry(0.25, 8, 8);
      //       //     const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity : 0.5 });
      //       //     const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
      //       //     sphere.position.set(value.center.x, value.center.y , value.center.z);
      //       //     clusters.mesh.add(sphere);

      //       // }
      //     });
      //   }
      // });

      // console.warn(`Curvature Minima ${this.minCurvature}`);
      // this.borderinRange = this.getTrianglesInRange(clusters.dataTooth, 6);
      // console.log(
      //   `Filter Border Triangles time: ${(
      //     performance.now() - this.startTime
      //   ).toFixed(2)} ms`
      // );

      // this.scene.add(clusters.mesh);

      // const adjascencyMap = this.buildTriangleAdjacencyMap([
      //   ...this.borderTriangleIndices
      // ], 10);
      // this.borderTriangleIndices = new Set(adjascencyMap.keys());
      // console.log(
      //   `Get Border Triangle Map time: ${(
      //     performance.now() - this.startTime
      //   ).toFixed(2)} ms`
      // );

      // this.TriangleAdjacencyMap = adjascencyMap;

      this.expandFromFirst(this.firstTriangle);
      console.log(
        `Segmentation time: ${(performance.now() - this.startTime).toFixed(
          2
        )} ms`
      );

      //console.table(this.getTrianglesWithinRadius(this.firstTriangle, 0.25));

      // this.borderTriangleIndices = this.findClosedContours(this.borderTriangleIndices,adjascencyMap);
      // this.borderTriangleIndices = this.extractLargestClosedLoop();
      // console.log(
      //   `Extract Largest Loop time: ${(
      //     performance.now() - this.startTime
      //   ).toFixed(2)} ms`
      // );

      // this.borderTriangleIndices = new Set(this.borderTriangleIndices)
      // this.largestLoop = this.findContourPoints(32, 1.5);
      // console.log(
      //   `Find Countour time: ${(performance.now() - this.startTime).toFixed(
      //     2
      //   )} ms`
      // );

      //this.borderTriangleIndices = new Set(this.borderinRange);
      // this.borderPointsCandidates = this.generateSeparatedPoints(0.8);
      // console.table(this.borderPointsCandidates);
      // console.log(
      //   `Separated Points time: ${(performance.now() - this.startTime).toFixed(
      //     2
      //   )} ms`
      // );
    }
  }

  findContourPoints(numPoints = 24, minDistance = 1.25) {
    const contourPoints = [];

    for (let i = 0; i < numPoints; i++) {
      let bestPoint = null;
      let closestToMaxDistance = minDistance * 1.2;

      bestPoint = this.findFarthestPoint(
        contourPoints,
        contourPoints.length > 0
          ? contourPoints[contourPoints.length - 1]
          : null,
        minDistance
      );

      if (bestPoint) {
        const isWithinMaxDistance = contourPoints.some(
          (point) =>
            point !== contourPoints[contourPoints.length - 1] &&
            point.distanceTo(bestPoint) <= closestToMaxDistance
        );
        if (isWithinMaxDistance) {
          break;
        }
        contourPoints.push(bestPoint);
      } else {
        break;
      }
    }

    return contourPoints;
  }

  findFarthestPoint(excludedPoints, lastPoint = null, minDistance = 1.5) {
    const triangles = this.candidateTriangles;
    let maxDistance = 0;
    let bestPoint = null;
    const closestToMaxDistance = minDistance * 1.2; // Definindo o limite máximo como 120% da distância mínima

    triangles.forEach((triangle) => {
      // Verificar se algum vizinho está na lista de triângulos segmentados
      const hasSegmentedNeighbor = [
        ...(this.adjacencyMap.get(triangle.index) || []),
      ].some((neighborIndex) => this.segmentedTriangles.has(neighborIndex));

      if (
        this.borderTriangleIndices.has(triangle.index) &&
        hasSegmentedNeighbor
      ) {
        triangle.vertices.forEach((vertex) => {
          const distanceToCenter = this.centerPoint.distanceTo(vertex);
          const isFarEnough = excludedPoints.every(
            (point) => point.distanceTo(vertex) >= minDistance
          );

          if (lastPoint) {
            const distanceFromLast = lastPoint.distanceTo(vertex);
            const isWithinMaxDistance = excludedPoints.some(
              (point) =>
                point !== lastPoint &&
                point.distanceTo(vertex) <= closestToMaxDistance
            );
            if (
              isFarEnough &&
              distanceToCenter > maxDistance &&
              distanceFromLast >= minDistance &&
              distanceFromLast <= closestToMaxDistance &&
              !isWithinMaxDistance
            ) {
              maxDistance = distanceToCenter;
              bestPoint = vertex.clone();
            }
          } else {
            if (distanceToCenter > maxDistance) {
              maxDistance = distanceToCenter;
              bestPoint = vertex.clone();
            }
          }
        });
      }
    });

    // Se encontrarmos um ponto existente dentro do closestToMaxDistance, retornar null
    const isWithinMaxDistance = excludedPoints.some(
      (point) =>
        bestPoint &&
        point !== lastPoint &&
        point.distanceTo(bestPoint) <= closestToMaxDistance
    );
    if (isWithinMaxDistance) {
      return null;
    }

    return bestPoint;
  }

  interpolateContourPoints(contourPoints) {
    const interpolatedPoints = [];

    for (let i = 0; i < contourPoints.length; i++) {
      const currentPoint = contourPoints[i];
      const nextPoint = contourPoints[(i + 1) % contourPoints.length]; // Garantir que o último ponto conecta de volta ao primeiro

      interpolatedPoints.push(currentPoint);

      // Interpolar ponto entre currentPoint e nextPoint
      const midPoint = new THREE.Vector3(
        (currentPoint.x + nextPoint.x) / 2,
        (currentPoint.y + nextPoint.y) / 2,
        (currentPoint.z + nextPoint.z) / 2
      );

      interpolatedPoints.push(midPoint);
    }

    return interpolatedPoints;
  }

  findLargestClosedContour(separatedPoints, radius = 0.25) {
    const adjacencyMap = new Map();
    const visited = new Set();
    const closedContours = [];

    // Construir o mapa de adjacência para os pontos separados
    for (let i = 0; i < separatedPoints.length; i++) {
      const neighbors = [];
      for (let j = 0; j < separatedPoints.length; j++) {
        if (
          i !== j &&
          separatedPoints[i].distanceTo(separatedPoints[j]) < radius * 2
        ) {
          neighbors.push(j);
        }
      }
      adjacencyMap.set(i, neighbors);
    }

    const dfs = (current, start, path) => {
      path.push(current);
      visited.add(current);

      const neighbors = adjacencyMap.get(current) || [];
      for (const neighbor of neighbors) {
        if (neighbor === start && path.length > 2) {
          closedContours.push([...path]);
        } else if (!visited.has(neighbor)) {
          dfs(neighbor, start, path);
        }
      }

      path.pop();
      visited.delete(current);
    };

    for (let i = 0; i < separatedPoints.length; i++) {
      dfs(i, i, []);
    }

    // Encontrar o maior contorno fechado
    let largestContour = [];
    for (const contour of closedContours) {
      if (contour.length > largestContour.length) {
        largestContour = contour;
      }
    }

    // Converter os índices do contorno para pontos
    return largestContour.map((index) => separatedPoints[index]);
  }

  buildTriangleAdjacencyMap(borderTriangleIndices, minNeighbors = 5) {
    const filteredTriangles = this.candidateTriangles.filter((triangle) =>
      borderTriangleIndices.includes(triangle.index)
    );

    const adjacencyMap = new Map();
    const vertexMap = new Map();

    // Criar um mapa de vértices para rápida referência cruzada
    filteredTriangles.forEach((triangle) => {
      triangle.vertices.forEach((vertex) => {
        const key = `${vertex.x.toFixed(3)}-${vertex.y.toFixed(
          3
        )}-${vertex.z.toFixed(3)}`;
        if (!vertexMap.has(key)) {
          vertexMap.set(key, new Set());
        }
        vertexMap.get(key).add(triangle.index);
      });
    });

    // Construir o mapa de adjacência usando a referência cruzada
    filteredTriangles.forEach((triangle) => {
      const neighborSet = new Set();
      triangle.vertices.forEach((vertex) => {
        const key = `${vertex.x.toFixed(3)}-${vertex.y.toFixed(
          3
        )}-${vertex.z.toFixed(3)}`;
        vertexMap.get(key).forEach((adjacentIndex) => {
          if (adjacentIndex !== triangle.index) {
            neighborSet.add(adjacentIndex);
          }
        });
      });

      if (neighborSet.size >= minNeighbors) {
        adjacencyMap.set(triangle.index, neighborSet);
      }
    });

    return adjacencyMap;
  }

  findFarthestPoint_(excludedPoints, lastPoint = null, minDistance = 1.5) {
    const triangles = this.candidateTriangles;
    let maxDistance = 0;
    let bestPoint = null;
    const closestToMaxDistance = minDistance * 1.2; // Definindo o limite máximo como 120% da distância mínima

    // Função auxiliar para verificar a nova condição de vizinhança
    const hasEligibleNeighbor = (triangleIndex) => {
      const neighbors = this.adjacencyMap.get(triangleIndex) || new Set();
      // Verificar se tem vizinho que não está em segmentedTriangles mas tem vizinho que está
      return Array.from(neighbors).some(
        (neighborIndex) =>
          !this.segmentedTriangles.has(neighborIndex) &&
          Array.from(this.adjacencyMap.get(neighborIndex) || new Set()).some(
            (subNeighbor) => this.segmentedTriangles.has(subNeighbor)
          )
      );
    };

    triangles.forEach((triangle) => {
      if (
        this.borderTriangleIndices.has(triangle.index) &&
        hasEligibleNeighbor(triangle.index)
      ) {
        triangle.vertices.forEach((vertex) => {
          const distanceToCenter = this.centerPoint.distanceTo(vertex);
          const isFarEnough = excludedPoints.every(
            (point) => point.distanceTo(vertex) >= minDistance
          );

          if (lastPoint) {
            const distanceFromLast = lastPoint.distanceTo(vertex);
            const isWithinMaxDistance = excludedPoints.some(
              (point) =>
                point !== lastPoint &&
                point.distanceTo(vertex) <= closestToMaxDistance
            );
            if (
              isFarEnough &&
              distanceToCenter > maxDistance &&
              distanceFromLast >= minDistance &&
              distanceFromLast <= closestToMaxDistance &&
              !isWithinMaxDistance
            ) {
              maxDistance = distanceToCenter;
              bestPoint = vertex.clone();
            }
          } else {
            if (distanceToCenter > maxDistance && isFarEnough) {
              maxDistance = distanceToCenter;
              bestPoint = vertex.clone();
            }
          }
        });
      }
    });

    // Se encontrarmos um ponto existente dentro do closestToMaxDistance, retornar null
    const isWithinMaxDistance = excludedPoints.some(
      (point) =>
        bestPoint &&
        point !== lastPoint &&
        point.distanceTo(bestPoint) <= closestToMaxDistance
    );
    if (isWithinMaxDistance) {
      return null;
    }

    return bestPoint;
  }

  // findFarthestPoint(excludedPoints, lastPoint = null, minDistance = 1.5) {
  //   const triangles = this.candidateTriangles;
  //   let maxDistance = 0;
  //   let bestPoint = null;
  //   const closestToMaxDistance = minDistance * 1.2; // Definindo o limite máximo como 120% da distância mínima

  //   triangles.forEach((triangle) => {
  //     // Verificar se algum vizinho está na lista de triângulos segmentados
  //     const hasSegmentedNeighbor = [
  //       ...(this.adjacencyMap.get(triangle.index) || []),
  //     ].some((neighborIndex) => this.segmentedTriangles.has(neighborIndex));

  //     if (
  //       this.borderTriangleIndices.has(triangle.index) &&
  //       hasSegmentedNeighbor
  //     ) {
  //       triangle.vertices.forEach((vertex) => {
  //         const distanceToCenter = this.centerPoint.distanceTo(vertex);
  //         const isFarEnough = excludedPoints.every(
  //           (point) => point.distanceTo(vertex) >= minDistance
  //         );

  //         if (lastPoint) {
  //           const distanceFromLast = lastPoint.distanceTo(vertex);
  //           const isWithinMaxDistance = excludedPoints.some(
  //             (point) =>
  //               point !== lastPoint &&
  //               point.distanceTo(vertex) <= closestToMaxDistance
  //           );
  //           if (
  //             isFarEnough &&
  //             distanceToCenter > maxDistance &&
  //             distanceFromLast >= minDistance &&
  //             distanceFromLast <= closestToMaxDistance &&
  //             !isWithinMaxDistance
  //           ) {
  //             maxDistance = distanceToCenter;
  //             bestPoint = vertex.clone();
  //           }
  //         } else {
  //           if (distanceToCenter > maxDistance) {
  //             maxDistance = distanceToCenter;
  //             bestPoint = vertex.clone();
  //           }
  //         }
  //       });
  //     }
  //   });

  //   // Se encontrarmos um ponto existente dentro do closestToMaxDistance, retornar null
  //   const isWithinMaxDistance = excludedPoints.some(
  //     (point) =>
  //       bestPoint &&
  //       point !== lastPoint &&
  //       point.distanceTo(bestPoint) <= closestToMaxDistance
  //   );
  //   if (isWithinMaxDistance) {
  //     return null;
  //   }

  //   return bestPoint;
  // }

  isBorder(triangleIndex, numNeighbors, angleBetween) {
    const triangle = this.candidateTriangles[triangleIndex];
    const visited = new Set(); // Para evitar visitar o mesmo triângulo mais de uma vez
    const queue = [triangleIndex];
    let maxAngle = -Infinity; // Maior variação angular
    let minAngle = Infinity; // Menor variação angular
    let curvature = 0;

    while (queue.length > 0 && visited.size <= numNeighbors) {
      const current = queue.shift();
      const currentTriangle = this.candidateTriangles[current];

      // Verifica se o triângulo atual já foi visitado
      if (visited.has(current)) continue;
      visited.add(current);

      // Calcula a variação angular entre as normais
      const angleBetweenNormals = this.calculateMaxSignedAngle(
        triangle.normal,
        currentTriangle.normal
      );

      curvature += triangle.curvatureMax;

      // Atualiza os valores da maior e menor variação angular
      maxAngle = Math.max(maxAngle, angleBetweenNormals);
      minAngle = Math.min(minAngle, angleBetweenNormals);

      // Se o número máximo de vizinhos foi atingido, verifica a variação angular
      if (visited.size >= numNeighbors) {
        // Se a diferença entre a maior e a menor variação angular excede o threshold, considera como borda
        if (Math.abs(maxAngle - minAngle) >= angleBetween && curvature > 4) {
          return true;
        }
      }

      // Adiciona os vizinhos do triângulo atual à fila de busca
      const neighbors = this.adjacencyMap.get(current) || [];
      for (const neighborIndex of neighbors) {
        if (!visited.has(neighborIndex)) {
          queue.push(neighborIndex);
        }
      }
    }

    // Se chegou até aqui, significa que não encontrou uma borda
    return false;
  }

  calculateMaxSignedAngle(vector1, vector2) {
    const angle1 = this.calculateSignedAngle(vector1, vector2);
    const angle2 = this.calculateSignedAngle(vector1, vector2.clone().negate());
    return Math.max(Math.abs(angle1), Math.abs(angle2));
  }

  calculateSignedAngle(vector1, vector2) {
    const crossProduct = new THREE.Vector3().crossVectors(vector1, vector2);
    const dotProduct = vector1.dot(vector2);
    const angle =
      Math.atan2(crossProduct.length(), dotProduct) * (180 / Math.PI);
    const sign = crossProduct.y >= 0 ? 1 : -1; // Determina o sinal com base no componente Y do produto vetorial
    return angle * sign;
  }

  expandFromFirst_(firstTriangle) {
    let queue = [firstTriangle];

    // Função para verificar se algum dos vizinhos está nos borderTriangleIndices
    const hasBorderNeighbor = (triangle) => {
      const neighbors = this.adjacencyMap.get(triangle);
      // Certificar que neighbors é tratado como um array
      if (!neighbors) {
        return false;
      } else if (neighbors instanceof Set) {
        // Converte o Set para Array se neighbors for uma instância de Set
        return Array.from(neighbors).some((neighbor) =>
          this.borderTriangleIndices.has(neighbor)
        );
      } else if (Array.isArray(neighbors)) {
        // Procede normalmente se já for um array
        return neighbors.some((neighbor) =>
          this.borderTriangleIndices.has(neighbor)
        );
      }
      // Retorna false por padrão se não for um array nem um Set
      return false;
    };

    while (queue.length > 0 && this.segmentedTriangles.size < 12000) {
      const current = queue.shift();

      if (this.visited.has(current)) continue;
      this.visited.add(current);

      if (
        (!this.borderTriangleIndices.has(current) &&
          !hasBorderNeighbor(current)) ||
        this.segmentedTriangles.size < 1000
      ) {
        this.segmentedTriangles.add(current);

        const neighbors = this.adjacencyMap.get(current) || [];
        neighbors.forEach((neighbor) => {
          if (!this.visited.has(neighbor)) {
            queue.push(neighbor);
          }
        });
      }
    }
  }

  expandFromFirst(firstTriangle) {
    let queue = [firstTriangle];

    while (queue.length > 0 && this.segmentedTriangles.size < 12000) {
      const current = queue.shift();

      if (this.visited.has(current)) continue;
      this.visited.add(current);

      if (
        !this.borderTriangleIndices.has(current) ||
        this.segmentedTriangles.size < 1000
      ) {
        this.segmentedTriangles.add(current);

        const neighbors = this.adjacencyMap.get(current) || [];
        neighbors.forEach((neighbor) => {
          if (!this.visited.has(neighbor)) {
            queue.push(neighbor);
          }
        });
      }
    }
  }

  expandFromFirst__(firstTriangle) {
    let queue = [firstTriangle];

    while (queue.length > 0 && this.segmentedTriangles.size < 10000) {
      const current = queue.shift();

      if (this.visited.has(current)) continue;
      this.visited.add(current);

      if (
        !this.isBorder(current, this.params.neighbor, this.params.variation) ||
        this.segmentedTriangles.size < 1000
      ) {
        this.segmentedTriangles.add(current);

        const neighbors = this.adjacencyMap.get(current) || [];
        neighbors.forEach((neighbor) => {
          if (!this.visited.has(neighbor)) {
            queue.push(neighbor);
          }
        });
      } else {
        this.borderTriangleIndices.add(current);
      }
    }
  }

  calculateRadiusAndAngle(triangle, center) {
    const centerXZ = new THREE.Vector2(center.x, center.z);
    const triangleCenterXZ = new THREE.Vector2(
      triangle.center.x,
      triangle.center.z
    );
    const radius = triangleCenterXZ.distanceTo(centerXZ);
    const angle =
      Math.atan2(
        triangleCenterXZ.y - centerXZ.y,
        triangleCenterXZ.x - centerXZ.x
      ) *
      (180 / Math.PI);
    return { radius, angle };
  }

  extractLargestClosedLoop() {
    const weights = new Map();
    const queue = Array.from(this.borderTriangleIndices);
    const visited = new Set();

    while (queue.length > 0) {
      const current = queue.shift();
      if (!visited.has(current)) {
        const loop = this.incrementWeights(current, weights, visited);
        if (loop) {
          console.warn("borda detectada");
          break;
        }
      }
    }

    const sortedTriangles = Array.from(weights.entries())
      .filter((entry) => entry[1] > 10000) // Filtrar os triângulos com peso menor que 200
      .sort((a, b) => b[1] - a[1])
      .map((entry) => entry[0]);

    return sortedTriangles.length > 8000 ? [] : sortedTriangles.slice(0, 8000); // Limitar a quantidade de triângulos selecionados
  }

  refineLargestClosedLoop(maxIterations = 2, threshold = 0.01) {
    let previousLoop = [];
    let iteration = 1;
    let currentLoop = this.extractLargestClosedLoop();
    console.log(
      `Refine Largest Loop ${iteration} time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );

    while (iteration < maxIterations) {
      console.log(
        `Iteration: ${iteration}, Current Loop Size: ${currentLoop.length}`
      );

      // Verifique a mudança na quantidade de triângulos selecionados
      const changeRatio =
        previousLoop.length > 0
          ? Math.abs(currentLoop.length - previousLoop.length) /
            previousLoop.length
          : 1; // Define changeRatio como 1 se for a primeira iteração

      if (changeRatio < threshold) {
        console.log("Convergence achieved. " + changeRatio);
        break;
      }

      // Atualize previousLoop e execute novamente extractLargestClosedLoop
      previousLoop = currentLoop;
      currentLoop = this.extractLargestClosedLoop();

      iteration++;
      console.log(
        `Refine Largest Loop ${iteration} time: ${(
          performance.now() - this.startTime
        ).toFixed(2)} ms`
      );
    }

    return currentLoop;
  }

  incrementWeights(triangleIndex, weights, visited, minLoopSize = 50) {
    let stack = [{ node: triangleIndex, path: [] }];
    let originalQueue = [triangleIndex];
    let loopDetected = false;
    let loopSize = 0;
    visited.add(triangleIndex);

    while (stack.length > 0) {
      const { node: current, path } = stack.pop();
      const currentPath = new Set(path);
      currentPath.add(current);

      if (!weights.has(current)) {
        weights.set(current, 0);
      }
      weights.set(current, weights.get(current) + 1);

      const neighbors = this.adjacencyMap.get(current) || [];

      if (neighbors.length === 0) {
        // Se chegarmos a um beco sem saída, limpar os pesos do caminho atual
        currentPath.forEach((triangle) => {
          weights.set(triangle, 0);
        });
        continue;
      }

      let pathAddedToStack = false;

      neighbors.forEach((neighbor) => {
        if (neighbor === triangleIndex && currentPath.size >= minLoopSize) {
          loopDetected = true;
          loopSize = currentPath.size; // Define o tamanho do loop detectado
        } else if (
          !visited.has(neighbor) &&
          this.borderTriangleIndices.has(neighbor)
        ) {
          visited.add(neighbor);
          const newPath = Array.from(currentPath);
          newPath.push(neighbor);
          originalQueue.push(neighbor);
          stack.push({ node: neighbor, path: newPath });
          pathAddedToStack = true;
        }

        // Incrementa o contador para todos os triângulos na originalQueue
        originalQueue.forEach((triangle) => {
          if (!weights.has(triangle)) {
            weights.set(triangle, 0);
          }
          weights.set(triangle, weights.get(triangle) + 1);
        });
      });

      if (loopDetected) {
        console.log(`Loop detected with size ${loopSize}!`);
        return true;
      }

      // Se não foi detectado um loop e nenhum caminho foi adicionado à pilha, limpar os pesos do caminho atual
      if (!loopDetected && !pathAddedToStack) {
        currentPath.forEach((triangle) => {
          weights.set(triangle, weights.get(triangle) - 1);
        });
      }
    }

    return false;
  }

  drawSelectedTriangles(
    selectedTriangleIndices,
    scene,
    sphereRadius = 0.25,
    sphereColor = 0xff00ff
  ) {
    const geometry = new THREE.SphereGeometry(sphereRadius, 32, 32);
    const material = new THREE.MeshBasicMaterial({ color: sphereColor });

    selectedTriangleIndices.forEach((triangleIndex) => {
      const triangle = this.candidateTriangles[triangleIndex];
      const sphere = new THREE.Mesh(geometry, material);
      sphere.position.copy(triangle.center);
      scene.add(sphere);
    });
  }
}

class Sphere {
  constructor(position) {
    this.position = position;
    this.velocity = new THREE.Vector3(0, 0, 0);
  }

  applyForce(force) {
    this.velocity.add(force);
  }

  updatePosition() {
    this.position.add(this.velocity);
    this.velocity.multiplyScalar(0.9); // Damping factor
  }
}

// Initialize spheres around the tooth
function initializeSpheres(aroundPosition, numSpheres = 24, radius = 10) {
  let spheres = [];
  for (let i = 0; i < numSpheres; i++) {
    let angle = (i / numSpheres) * Math.PI * 2;
    let position = new THREE.Vector3(
      aroundPosition.x + Math.cos(angle) * radius,
      aroundPosition.y,
      aroundPosition.z + Math.sin(angle) * radius
    );
    spheres.push(new Sphere(position));
  }
  return spheres;
}

// Calculate the centers of triangles specified by indices
function calculateTriangleCenters(bufferGeometry, triangleIndices) {
  let positions = bufferGeometry.geometry.attributes.position.array;
  let centers = [];
  for (let i = 0; i < triangleIndices.length; i += 3) {
    let index1 = triangleIndices[i] * 3;
    let index2 = triangleIndices[i + 1] * 3;
    let index3 = triangleIndices[i + 2] * 3;

    let vertex1 = new THREE.Vector3(
      positions[index1],
      positions[index1 + 1],
      positions[index1 + 2]
    );
    let vertex2 = new THREE.Vector3(
      positions[index2],
      positions[index2 + 1],
      positions[index2 + 2]
    );
    let vertex3 = new THREE.Vector3(
      positions[index3],
      positions[index3 + 1],
      positions[index3 + 2]
    );

    let center = new THREE.Vector3(
      (vertex1.x + vertex2.x + vertex3.x) / 3,
      (vertex1.y + vertex2.y + vertex3.y) / 3,
      (vertex1.z + vertex2.z + vertex3.z) / 3
    );

    centers.push(center);
  }
  return centers;
}

// Find the nearest point on the tooth mesh using triangle centers
function findNearestPointOnTooth(position, triangleCenters) {
  let nearestPoint = null;
  let minDistance = Infinity;
  for (let center of triangleCenters) {
    let distance = position.distanceTo(center);
    if (distance < minDistance) {
      minDistance = distance;
      nearestPoint = center;
    }
  }
  return nearestPoint;
}

// Compute forces and update positions
function updateSpheres(spheres, triangleCenters) {
  for (let sphere of spheres) {
    // Compute repulsion force from other spheres
    for (let otherSphere of spheres) {
      if (sphere !== otherSphere) {
        let distance = sphere.position.distanceTo(otherSphere.position);
        let force = sphere.position
          .clone()
          .sub(otherSphere.position)
          .normalize()
          .multiplyScalar(0.01 / distance);
        sphere.applyForce(force);
      }
    }

    // Compute attraction force towards the tooth surface
    let nearestPoint = findNearestPointOnTooth(
      sphere.position,
      triangleCenters
    );
    let attractionForce = nearestPoint
      .clone()
      .sub(sphere.position)
      .normalize()
      .multiplyScalar(0.2);
    sphere.applyForce(attractionForce);

    // Update sphere position
    sphere.updatePosition();
  }
}

// Main function to get smooth contour
// eslint-disable-next-line no-unused-vars
function getSmoothContour(aroundPosition, bufferGeometry, triangleIndices) {
  let spheres = initializeSpheres(aroundPosition, 12, 8);
  let triangleCenters = calculateTriangleCenters(
    bufferGeometry,
    triangleIndices
  );

  for (let i = 0; i < 10; i++) {
    // Simulate for 100 iterations
    updateSpheres(spheres, triangleCenters);
  }

  // Fit a Catmull-Rom curve to the spheres
  let curve = new THREE.CatmullRomCurve3(
    spheres.map((sphere) => sphere.position)
  );
  return { curve, spheres };
}
